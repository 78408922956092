import { put, takeLatest, call, select } from 'redux-saga/effects'; // select
import { toast } from 'react-toastify';
import * as actions from './actions';
import * as api from './api';
import { fetchUserData } from 'src/modules/auth/redux/actions';

function* pollConversionSaga({ payload }) {
  try {
    const {
      auth: { user },
    } = yield select();
    const result = yield call(api.pollConversion, {
      ...payload,
      AccountId: user.AccountId,
    });
    yield put(
      actions.pollConversion.success({
        ...result,
      }),
    );
    if (result.status === 'created') {
      toast.success('Your audio video is created successfully');
      yield put(fetchUserData.request({}));
    }
  } catch (ex) {
    yield put(actions.pollConversion.failure(ex));
  }
}

function* createAudioVideoSaga({ payload: { values, rawData, successCb } }) {
  try {
    const {
      auth: { user },
    } = yield select();
    const response = yield call(api.createAudioVideo, {
      AccountId: user.AccountId,
      ...values,
    });

    if (response.payloadFileUrl) {
      yield put(actions.createAudioVideo.success({}));
      if (successCb) {
        successCb(rawData);
      }
    } else {
      toast.error(response.message);
      yield put(actions.createAudioVideo.failure(response.message));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.createAudioVideo.failure(ex));
  }
}

function* fetchShortClipsSaga({ payload }) {
  try {
    const result = yield call(api.fetchShortClips, { ...payload });
    yield put(
      actions.fetchShortClips.success({
        ...result,
      }),
    );
  } catch (ex) {
    yield put(actions.fetchShortClips.failure(ex));
  }
}

function* demoSagas() {
  yield takeLatest(actions.createAudioVideo.request, createAudioVideoSaga);
  yield takeLatest(actions.pollConversion.request, pollConversionSaga);
  yield takeLatest(actions.fetchShortClips.request, fetchShortClipsSaga);
}

export default demoSagas;
