import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { storage } from 'src/utils';
import * as authActions from 'src/modules/auth/redux/actions';
import * as authApi from '../../auth/redux/api';
import * as api from './api';
import * as actions from './actions';
import { trim } from 'lodash';

function* updateSubscriptionSaga({ payload }) {
  const { values, successCb, renew, apiToken } = payload;
  try {
    const data = {
      ...values,
      card: {
        ...values.card,
        number: values.card.number.replace(/\s/g, ''),
        credit_card_type: undefined,
      },
      user: {
        ...values.user,
        name: values.user.name + ' ' + values.user.lastName,
        lastName: undefined,
        email: trim(values.user.email),
      },
      subscription: values.subscription || undefined,
      renew: renew || undefined,
      toStep: undefined,
    };
    if (renew && apiToken) {
      storage.setItem('token', apiToken);
    }
    const subscription = yield call(api.updateSubscription, data, apiToken);
    if (subscription.code === 1) {
      if (!renew) {
        const user = yield call(authApi.getUser);

        let ipInfo;
        try {
          if (window.location.hostname !== 'localhost') {
            ipInfo = yield call(authApi.getIpInfo);
          } else {
            ipInfo = { country: '' };
          }
        } catch (ex) {
          ipInfo = { country: '' };
        }

        yield put(authActions.fetchUserData.success({ ipInfo, ...user }));
        toast.success('Payment successfully confirmed');
      } else {
        toast.success('Your account renewed successfully.');
      }
      yield put(actions.updateSubscription.success(subscription));
      if (successCb) {
        successCb();
      }
    } else {
      yield put(actions.updateSubscription.failure(subscription.message));
      toast.error('Transaction failed');
    }
  } catch (ex) {
    yield put(actions.updateSubscription.failure(ex));
    toast.error('Transaction failed');
  } finally {
    if (renew && apiToken) {
      storage.setItem('token', null);
    }
  }
}

function* changePasswordSaga({ payload }) {
  try {
    const { values, successCb } = payload;

    const response = yield call(api.changePassword, {
      oldPassword: values.oldPassword,
      newPassword: values.Password,
    });

    yield put(actions.changePassword.success(response));

    toast.success('Password successfully changed');

    if (successCb) {
      successCb();
    }
  } catch (ex) {
    yield put(actions.changePassword.failure(ex));
    toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* cancelSubscriptionSaga({ payload }) {
  try {
    const { successCb } = payload;

    yield call(api.cancelSubscription);
    const user = yield call(authApi.getUser);
    let ipInfo;
    try {
      if (window.location.hostname !== 'localhost') {
        ipInfo = yield call(authApi.getIpInfo);
      } else {
        ipInfo = { country: '' };
      }
    } catch (ex) {
      ipInfo = { country: '' };
    }
    yield put(actions.cancelSubscription.success());
    yield put(authActions.fetchUserData.success({ ipInfo, ...user }));

    toast.success('Subscription successfully canceled');
    if (successCb) {
      successCb();
    }
  } catch (ex) {
    yield put(actions.cancelSubscription.failure(ex));
    toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* fetchExpiredAccountsSaga({ payload = {} }) {
  try {
    const accounts = yield call(api.fetchExpiredAccounts, { ...payload });
    yield put(
      actions.fetchExpiredAccounts.success({
        data: [...accounts],
      }),
    );
  } catch (ex) {
    yield put(actions.fetchExpiredAccounts.failure(ex));
  }
}

function* updateEmailSaga({ payload }) {
  try {
    const { values, successCb } = payload;

    const response = yield call(api.updateEmail, {
      EmailAddress: values.EmailAddress,
    });

    yield put(actions.updateEmail.success(response));

    toast.success(
      'Email updated successfully.\nWe have sent verification email.\nPlease verify it.',
    );

    if (successCb) {
      successCb();
    }
  } catch (ex) {
    yield put(actions.updateEmail.failure(ex));
    toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* videoSagas() {
  yield takeLatest(actions.updateSubscription.request, updateSubscriptionSaga);
  yield takeLatest(actions.changePassword.request, changePasswordSaga);
  yield takeLatest(actions.cancelSubscription.request, cancelSubscriptionSaga);
  yield takeLatest(
    actions.fetchExpiredAccounts.request,
    fetchExpiredAccountsSaga,
  );
  yield takeLatest(actions.updateEmail.request, updateEmailSaga);
}

export default videoSagas;
