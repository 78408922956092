import { Loadable } from 'src/common';

import { PATH } from 'src/constants';

const AudioVideoPage = Loadable(() => import('./pages/AudioVideo'));
const VevoPublishPage = Loadable(() => import('./pages/VevoPublish'));
const AudioVideoDownloadPage = Loadable(() =>
  import('./pages/AudioVideoDownload'),
);

export default [
  {
    component: AudioVideoPage,
    path: `${PATH.PUBLISHING}/audio-video`,
    name: 'AudioVideo',
    exact: true,
  },
  {
    component: VevoPublishPage,
    path: `${PATH.PUBLISHING}/vevo-publish`,
    name: 'VevoPublish',
    exact: true,
  },
  {
    component: AudioVideoDownloadPage,
    path: `${PATH.PUBLISHING}/audio-video-create`,
    name: 'AudioVideoCreate',
    exact: true,
  },
];
