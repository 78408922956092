import { createAction } from 'redux-act';
import identity from 'lodash/identity';

export const createAsyncAction = (type, payloadCreators, metaCreators) => {
  const payload = payloadCreators || {};
  const meta = metaCreators || {};
  return {
    cancel: createAction(
      type + '::CANCEL',
      payload.cancel || identity,
      meta.cancel,
    ),
    failure: createAction(
      type + '::FAILURE',
      payload.failure || identity,
      meta.failure,
    ),
    trigger: createAction(
      type + '::TRIGGER',
      payload.trigger || identity,
      meta.trigger,
    ),
    request: createAction(
      type + '::REQUEST',
      payload.request || identity,
      meta.request,
    ),
    success: createAction(
      type + '::SUCCESS',
      payload.success || identity,
      meta.success,
    ),
    fulfill: createAction(
      type + '::FULFILL',
      payload.fulfill || identity,
      meta.fulfill,
    ),
    start: createAction(
      type + '::START',
      payload.start || identity,
      meta.start,
    ),
  };
};

export default (prefix: string) => (type, payloadCreators, metaCreators) => {
  return createAsyncAction(`${prefix}:${type}`, payloadCreators, metaCreators);
};
