import { actionCreator } from 'src/utils';

const MODULE_NAME = 'AUTH';
const ACTION = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SIGN_UP: 'SIGN_UP',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  FETCH_USER_DATA: 'FETCH_USER',
  FETCH_SUBSCRIPTIONS: 'FETCH_SUBSCRIPTIONS',
  FETCH_IP_INFO: 'FETCH_IP_INFO',
  SET_USER_SETTINGS: 'SET_USER_SETTINGS',
  UPDATE_TERMS_AND_CONDITIONS: 'UPDATE_TERMS_AND_CONDITIONS',
  CREATE_USER: 'CREATE_USER',
  REQUEST_CHANNEL: 'REQUEST_CHANNEL',
  BUY_PACKAGE: 'BUY_PACKAGE',
  HYDRATE_REGISTERED_USER_FROM_SESSION: 'HYDRATE_REGISTERED_USER_FROM_SESSION',
  UPDATE_USER: 'UPDATE_USER',
  GENERATE_PHONE_VERIFICATION_CODE: 'GENERATE_PHONE_VERIFICATION_CODE',
  UPDATE_PHONE_NUMBER: 'UPDATE_PHONE_NUMBER',
};

const action = actionCreator(MODULE_NAME);

export const login = action(ACTION.LOGIN);
export const signUp = action(ACTION.SIGN_UP);
export const logout = action(ACTION.LOGOUT);
export const forgotPassword = action(ACTION.FORGOT_PASSWORD);
export const changePassword = action(ACTION.CHANGE_PASSWORD);
export const fetchUserData = action(ACTION.FETCH_USER_DATA);
export const fetchSubscriptions = action(ACTION.FETCH_SUBSCRIPTIONS);
export const fetchTermsAndConditions = action(ACTION.FETCH_TERMS_CONDITIONS);
export const fetchIpInfo = action(ACTION.FETCH_IP_INFO);
export const setUserSettings = action(ACTION.SET_USER_SETTINGS);
export const updateTermsAndConditions = action(
  ACTION.UPDATE_TERMS_AND_CONDITIONS,
);

export const createUser = action(ACTION.CREATE_USER);
export const requestChannel = action(ACTION.REQUEST_CHANNEL);
export const buyPackage = action(ACTION.BUY_PACKAGE);
export const hydrateRegisteredUser = action(
  ACTION.HYDRATE_REGISTERED_USER_FROM_SESSION,
);

export const updateUser = action(ACTION.UPDATE_USER);
export const generatePhoneSecurityCode = action(
  ACTION.GENERATE_PHONE_VERIFICATION_CODE,
);

export const updatePhoneNumber = action(ACTION.UPDATE_PHONE_NUMBER);
