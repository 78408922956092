import { actionCreator } from 'src/utils';

const MODULE_NAME = 'VIDEO';
const ACTION = {
  FETCH_VIDEOS: 'FETCH_VIDEOS',
  SEARCH_VIDEO: 'SEARCH_VIDEO',
  CHANGE_PAGE: 'CHANGE_PAGE',
  CHANGE_PAGE_SIZE: 'CHANGE_PAGE_SIZE',
  CREATE_VIDEO: 'CREATE_VIDEO',
  FETCH_FACEBOOK_ARTIST_URL: 'FETCH_FACEBOOK_ARTIST_URL',
  SAVE_FACEBOOK_ARTIST_URL: 'SAVE_FACEBOOK_ARTIST_URL',
  ADD_TO_PMV: 'ADD_TO_PMV',
};

const action = actionCreator(MODULE_NAME);

export const fetchVideos = action(ACTION.FETCH_VIDEOS);
export const searchVideos = action(ACTION.SEARCH_VIDEO);
export const changePage = action(ACTION.CHANGE_PAGE);
export const changePageSize = action(ACTION.CHANGE_PAGE_SIZE);
export const createVideo = action(ACTION.CREATE_VIDEO);
export const fetchFacebookArtistUrl = action(ACTION.FETCH_FACEBOOK_ARTIST_URL);
export const saveFacebookArtistUrl = action(ACTION.SAVE_FACEBOOK_ARTIST_URL);
export const addToPMV = action(ACTION.ADD_TO_PMV);
