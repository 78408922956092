export const orange = '#EE8524';
export const green = '#7CA000';
export const blue = '#5674b8';

export const lGreen = '#84BD00';
export const lBlue = '#96CDE8';

export const gray = '#6C757D';
export const lGray = '#F1F1F1';
export const dGray = '#222222';

export const theme = {
  mainWhite: '#fff',
  mainTextColor: '#0E0E0E',
  mainColor: gray,
  mainColorLight: lGray,
  mainColorDark: dGray,
  errorColor: '#BB0000',
  secondaryColor: blue,
  secondaryColorLight: '#CFD4D9',
  secondaryColorDark: '',
  accentColor: blue,
  secondaryAccentColor: blue,
  defaultPadding: '20px 20px',
};
