import { api, serialize } from 'src/utils';

export const fetchAnalytics = () => {
  return api('/analytics/all');
};
// new analytics
export const fetchVideoAutoComplete = (params) => {
  const queryStringParams = serialize(params);
  return api(`/video/autocomplete${queryStringParams}`);
};

export const fetchVideoAnalyticsByCountry = (params) => {
  const queryStringParams = serialize({ ...params, mode: 'by-country' });
  return api(`/video/analytics${queryStringParams}`);
};

export const fetchVideoAnalyticsBySource = (params) => {
  const queryStringParams = serialize({ ...params, mode: 'by-source' });
  return api(`/video/analytics${queryStringParams}`);
};

export const fetchVideoAnalyticsTopCountries = (params) => {
  const queryStringParams = serialize({
    ...params,
    mode: 'by-country',
    page: 1,
    limit: 10,
  });
  return api(`/video/analytics${queryStringParams}`);
};

export const fetchVideoAnalyticsByMonth = (params) => {
  const queryStringParams = serialize({ ...params, mode: 'revenue-by-month' });
  return api(`/video/analytics${queryStringParams}`);
};

export const fetchVideoAnalyticsTopVideos = (params) => {
  const queryStringParams = serialize({
    ...params,
    mode: 'by-videos',
    page: 1,
    limit: 10,
  });
  return api(`/video/analytics${queryStringParams}`);
};

export const fetchAudioAnalyticsByCountry = (params) => {
  const queryStringParams = serialize({ ...params, mode: 'by-country' });
  return api(`/audio/analytics${queryStringParams}`);
};

export const fetchAudioAnalyticsByDsp = (params) => {
  const queryStringParams = serialize({ ...params, mode: 'by-dsp' });
  return api(`/audio/analytics${queryStringParams}`);
};

export const fetchAudioAnalyticsTopCountries = (params) => {
  const queryStringParams = serialize({
    ...params,
    mode: 'by-country',
    page: 1,
    limit: 10,
  });
  return api(`/audio/analytics${queryStringParams}`);
};

export const fetchAudioAnalyticsByMonth = (params) => {
  const queryStringParams = serialize({ ...params, mode: 'revenue-by-month' });
  return api(`/audio/analytics${queryStringParams}`);
};

export const fetchAudioAnalyticsRevenueByDsp = (params) => {
  const queryStringParams = serialize({ ...params, mode: 'revenue-by-dsp' });
  return api(`/audio/analytics${queryStringParams}`);
};
