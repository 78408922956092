import { combineReducers } from 'redux';

import { layoutReducer } from 'src/modules/layout';
import { audioReducer } from 'src/modules/audio';
import { videoReducer } from 'src/modules/video';
import { authReducer } from 'src/modules/auth';
import { accountReducer } from 'src/modules/account';
import { channelReducer } from 'src/modules/channel';
import { analyticsReducer } from 'src/modules/analytics';
import { dashboardReducer } from 'src/modules/dashboard';
import { payoutReducer } from 'src/modules/payout';
import { macvReducer } from 'src/modules/macv';
import { finReducer } from 'src/modules/financial';
// import { beatsReducer } from 'src/modules/beats';
import { audioVideoReducer } from 'src/modules/audioVideo';
import { marketingReducer } from 'src/modules/marketing';

export default combineReducers({
  layout: layoutReducer,
  video: videoReducer,
  audio: audioReducer,
  auth: authReducer,
  account: accountReducer,
  channel: channelReducer,
  analytics: analyticsReducer,
  dashboard: dashboardReducer,
  payout: payoutReducer,
  macv: macvReducer,
  financial: finReducer,
  // beats: beatsReducer,
  audioVideo: audioVideoReducer,
  marketing: marketingReducer,
});
