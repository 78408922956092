import { actionCreator } from 'src/utils';

const MODULE_NAME = 'MACV';
const ACTION = {
  FETCH_VIDEOS_QUEUE: 'FETCH_VIDEOS_QUEUE',
  FETCH_SHARED_VIDEOS: 'FETCH_SHARED_VIDEOS',
  SHARE_VIDEO: 'SHARE_VIDEO',
  UPDATE_VIDEO_VIEW: 'UPDATE_VIDEO_VIEW',
  FETCH_QUEUE_VIDEO: 'FETCH_QUEUE_VIDEO',
  FETCH_TODAY_VIEWED: 'FETCH_TODAY_VIEWED',
  SEARCH_VIDEOS: 'SEARCH_VIDEOS',
  GET_MAC_USER_PROFILE: 'GET_MAC_USER_PROFILE',
  SET_IS_EXPLICIT: 'SET_IS_EXPLICIT',
  UPDATE_SHARE_CREDIT_FLAG: 'UPDATE_SHARE_CREDIT_FLAG',
  GET_MACV_STATS: 'GET_MACV_STATS',
  FETCH_VIEWS_BY_ISRC: 'FETCH_VIEWS_BY_ISRC',
  FETCH_TOP_WATCHERS: 'FETCH_TOP_WATCHERS',
  RESET_MACV: 'RESET_MACV',
  FETCH_BEATS: 'FETCH_BEATS',
  BEATS_CART_ADD: 'BEATS_CART_ADD',
  BEATS_CART_REMOVE: 'BEATS_CART_REMOVE',
  BEATS_CAR_EMPTY: 'BEATS_CAR_EMPTY',
  BEATS_CHECKOUT: 'BEATS_CHECKOUT',
  FETCH_INVOICE_BY_TOKEN: 'FETCH_INVOICE_BY_TOKEN',
  FETCH_BEATS_ORDERS: 'FETCH_BEATS_ORDERS',
  FETCH_BEATS_ORDERS_COUNT: 'FETCH_BEATS_ORDERS_COUNT',
};

const action = actionCreator(MODULE_NAME);

export const fetchVideosQueue = action(ACTION.FETCH_VIDEOS_QUEUE);
export const fetchSharedVideos = action(ACTION.FETCH_SHARED_VIDEOS);
export const shareVideo = action(ACTION.SHARE_VIDEO);
export const updateVideoView = action(ACTION.UPDATE_VIDEO_VIEW);
export const fetchQueueVideo = action(ACTION.FETCH_QUEUE_VIDEO);
export const fetchTodayViewed = action(ACTION.FETCH_TODAY_VIEWED);
export const searchVideos = action(ACTION.SEARCH_VIDEOS);
export const getMacUser = action(ACTION.GET_MAC_USER_PROFILE);
export const setMacUserExplicit = action(ACTION.SET_IS_EXPLICIT);
export const updateShareCreditFlag = action(ACTION.UPDATE_SHARE_CREDIT_FLAG);
export const getMacvStats = action(ACTION.GET_MACV_STATS);
export const fetchViewsByIsrc = action(ACTION.FETCH_VIEWS_BY_ISRC);
export const fetchTopWatchers = action(ACTION.FETCH_TOP_WATCHERS);
export const resetMacv = action(ACTION.RESET_MACV);
export const fetchBeats = action(ACTION.FETCH_BEATS);
export const beatsCartItemAdd = action(ACTION.BEATS_CART_ADD);
export const beatsCartItemRemove = action(ACTION.BEATS_CART_REMOVE);
export const beatsCartEmpty = action(ACTION.BEATS_CART_EMPTY);
export const beatsCheckout = action(ACTION.BEATS_CHECKOUT);
export const fetchBeatInvoiceByToken = action(ACTION.FETCH_INVOICE_BY_TOKEN);
export const fetchBeatsOrders = action(ACTION.FETCH_BEATS_ORDERS);
export const fetchBeatsOrdersCount = action(ACTION.FETCH_BEATS_ORDERS_COUNT);
