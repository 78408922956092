export default {
  ROOT: '/',
  SIGNIN: '/login',
  SIGNUP: '/signup',
  ACCOUNT: '/account',
  ANALYTICS: '/analytics',
  AUDIO: '/audio',
  AUDIO_CREATE: '/audio-create',
  VIDEO: '/video',
  VIDEO_GRID: '/video-grid',
  VIDEO_TABLE: '/video-table',
  VIDEO_UPLOAD: '/video-upload',
  CHANNEL: '/channel',
  CHANNEL_CREATE: '/channel-create',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  DASHBOARD: '/dashboard',
  PAYOUT: '/payout',
  RENEW_ACCOUNT: '/renew',
  MACV: '/macv',
  FINANCIAL: '/financial',
  FINANCIAL_EARNINGS: '/financial/earnings',
  BEATS: '/beats',
  BEAT_CREATE: '/beats/create',
  PUBLISHING: '/publishing',
  ADD_TO_PMV: '/video-add-to-pmv',
  MARKETING: '/marketing',
  MARKETING_LINKS: '/marketing/links',
  MARKETING_CREATE_LINK: '/marketing/create-link',
  MARKETING_CREATE_LINK_SETUP: '/marketing/create-link/setup',
  MARKETING_CREATE_LINK_STATUS: '/marketing/create-link/status',
  MARKETING_EDIT_LINK: '/marketing/edit-link',
  MARKETING_INSIGHTS: '/marketing/insights',
  MARKETING_LINK_INSIGHTS: '/marketing/link-insights',
  VIDEO_ANALYTICS: '/analytics/video',
  AUDIO_ANALYTICS: '/analytics/audio',
  AGREE_TERMS_WITH_TOKEN: '/agree-terms',
  HOW_TO_UPLOAD_NFT: '/admin/nft/how-to-upload',
  VALIDATE_PHONE: '/validate-phone',
};
