import { trim } from 'lodash';
import * as yup from 'yup';

export const createChannelInitialValues = {
  ArtistName: '',
  ChannelName: '',
  Links: [],
  ArtistImagepath: null,
  BannerImagePath: null,
  IsChannelOnYoutube: false,
};

export const createChannelValidationSchema = yup.object().shape({
  ArtistName: yup
    .string()
    .required('Artist name is required')
    .max(16, 'Must be no more than 16 characters')
    .test({
      name: 'noSpecialChars',
      test: (value) => /^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/gi.test(trim(value)),
      message: 'Special characters are not allowed.',
    }),
});

export const createChannelName = (channelName) => {
  return channelName.replace(/\W+/g, '');
};

export const transformChannelLinks = (links) =>
  links
    .map((socialLists) => socialLists.substr(0, socialLists.lastIndexOf(':')))
    .join(', ');
