import { createReducer } from 'redux-act';
import produce from 'immer';

import * as actions from './actions';

const initialState = {
  userData: {},
  loading: false,
  selectedSubscription: null,
  updateSubscriptionError: null,
  expiredAccounts: [],
};

export default createReducer(
  {
    [actions.updateSubscription.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
        nextState.updateSubscriptionError = null;
      }),
    [actions.updateSubscription.success]: (state, payload) => {
      return produce(state, (nextState) => {
        nextState.userData = payload;
        nextState.loading = false;
      });
    },
    [actions.updateSubscription.failure]: (state, error) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.updateSubscriptionError = error;
      }),
    [actions.changePassword.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.changePassword.success]: (state) => {
      return produce(state, (nextState) => {
        nextState.loading = false;
      });
    },
    [actions.changePassword.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.cancelSubscription.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.cancelSubscription.success]: (state) => {
      return produce(state, (nextState) => {
        nextState.loading = false;
      });
    },
    [actions.selectSubscription.success]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.selectedSubscription = payload;
      }),
    [actions.fetchExpiredAccounts.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.fetchExpiredAccounts.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        nextState.expiredAccounts = data;
      });
    },
    [actions.fetchExpiredAccounts.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),

    [actions.updateEmail.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.updateEmail.success]: (state) => {
      return produce(state, (nextState) => {
        nextState.loading = false;
      });
    },
    [actions.updateEmail.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
  },
  initialState,
);
