/* eslint-disable no-undef */
const LOCAL_STORAGE_SECRET = 'superstar-2020-';

const getStorage = (mode) => {
  return mode === 'session' ? sessionStorage : localStorage;
};

const getItem = (key, mode = 'store') => {
  try {
    const storage = getStorage(mode);
    return JSON.parse(storage.getItem(`${LOCAL_STORAGE_SECRET}${key}`));
  } catch (ex) {
    return undefined;
  }
};

const removeItem = (key, mode = 'store') => {
  try {
    const storage = getStorage(mode);
    storage.removeItem(`${LOCAL_STORAGE_SECRET}${key}`);
  } catch (ex) {
    console.log(ex);
  }
};

const setItem = (key, value, mode = 'store') => {
  try {
    const storage = getStorage(mode);
    storage.setItem(`${LOCAL_STORAGE_SECRET}${key}`, JSON.stringify(value));
    return undefined;
  } catch (ex) {
    return undefined;
  }
};

export default {
  getItem,
  setItem,
  removeItem,
};
