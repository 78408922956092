import { Loadable } from 'src/common';

import { PATH } from 'src/constants';

const DashboardPage = Loadable(() => import('./pages/Dashboard'));
// const HowToUploadNFTPage = Loadable(() => import('./pages/HowToUploadNFT'));

export default [
  {
    component: DashboardPage,
    path: PATH.DASHBOARD,
    name: 'Dashboard',
    exact: true,
  },
  // {
  //   component: HowToUploadNFTPage,
  //   path: PATH.HOW_TO_UPLOAD_NFT,
  //   name: 'How to upload NFT',
  //   exact: true,
  // },
];
