import { api, serialize } from 'src/utils';

export const fetchPayout = (params) => {
  const queryStringParams = serialize(params);
  return api(`/payout${queryStringParams}`);
};

export const addPayoutDirect = (body) => {
  return api.post('/payout/direct', body);
};

export const updatePayoutDirect = (payoutId, body) => {
  return api.put(`/payout/direct/${payoutId}`, body);
};

export const deletePayoutDirect = (payoutId) => {
  return api.delete(`/payout/direct/${payoutId}`);
};

export const addPayoutPaypal = (body) => {
  return api.post('/payout/paypal', body);
};

export const updatePayoutPaypal = (payoutId, body) => {
  return api.put(`/payout/paypal/${payoutId}`, body);
};

export const deletePayoutPaypal = (payoutId) => {
  return api.delete(`/payout/paypal/${payoutId}`);
};

export const fetchPayoutSettings = (params) => {
  const queryStringParams = serialize(params);
  return api(`/payout/settings${queryStringParams}`);
};

export const updatePayoutSettings = (payload) => {
  return api.post('/payout/settings', payload);
};
