import { createReducer } from 'redux-act';
import produce from 'immer';

import * as actions from './actions';

const initialState = {
  loading: {
    videosQueue: false,
    sharedVideos: false,
    shareVideo: false,
    updateVideoView: false,
    queueVideo: false,
    todayViewed: false,
    searchVideos: false,
    macUser: false,
    setMacExplicit: false,
    macStats: false,
    viewsByIsrc: false,
    topWatchers: false,
    fetchBeats: false,
    beatsCheckout: false,
    tokenInvoice: false,
    beatsOrders: false,
    beatsOrdersCount: false,
  },
  notRefreshQueue: false,
  videosQueue: [],
  sharedVideos: [],
  watchingVideo: null,
  todayViewed: [],
  searchedVideos: [],
  macUser: null,
  hasGotShareCredits: false,
  stats: null,
  viewsByIsrc: [],
  topWatchers: [],
  beats: [],
  beatsPaging: {
    count: 0,
    total: 0,
    page: 1,
    pageCount: 0,
    limit: 25,
  },
  beatsCartItems: [],
  tokenInvoice: null,
  beatsOrders: [],
  beatsOrdersPaging: {
    count: 0,
    total: 0,
    page: 1,
    pageCount: 0,
    limit: 25,
  },
  totalBeatsOrders: 0,
};

export default createReducer(
  {
    [actions.fetchVideosQueue.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, videosQueue: true };
      }),
    [actions.fetchVideosQueue.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, videosQueue: false };
        nextState.videosQueue = data;
        nextState.notRefreshQueue = true;
      });
    },
    [actions.fetchVideosQueue.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, videosQueue: false };
        nextState.videosQueue = [];
        nextState.notRefreshQueue = false;
      }),
    [actions.fetchSharedVideos.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, sharedVideos: true };
      }),
    [actions.fetchSharedVideos.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, sharedVideos: false };
        nextState.sharedVideos = data;
      });
    },
    [actions.fetchSharedVideos.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, sharedVideos: false };
      }),
    [actions.shareVideo.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, shareVideo: true };
      }),
    [actions.shareVideo.success]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, shareVideo: false };
      }),
    [actions.shareVideo.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, shareVideo: false };
      }),
    [actions.updateVideoView.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, updateVideoView: true };
      }),
    [actions.updateVideoView.success]: (state) => {
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, updateVideoView: false };
      });
    },
    [actions.updateVideoView.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, updateVideoView: false };
      }),

    [actions.fetchQueueVideo.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, queueVideo: true };
      }),
    [actions.fetchQueueVideo.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, queueVideo: false };
        nextState.watchingVideo = data;
      });
    },
    [actions.fetchQueueVideo.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, queueVideo: false };
      }),

    [actions.fetchTodayViewed.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, todayViewed: true };
      }),
    [actions.fetchTodayViewed.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, todayViewed: false };
        nextState.todayViewed = data;
      });
    },
    [actions.fetchTodayViewed.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, todayViewed: false };
      }),

    [actions.searchVideos.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, searchVideos: true };
      }),
    [actions.searchVideos.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, searchVideos: false };
        nextState.searchedVideos = data;
      });
    },
    [actions.searchVideos.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, searchVideos: false };
      }),

    [actions.getMacUser.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, macUser: true };
      }),
    [actions.getMacUser.success]: (state, payload) => {
      const { data } = payload;
      const { macUser } = state;
      const oldShareCredits = macUser ? macUser.ShareVideoCredits : -1;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, macUser: false };
        nextState.macUser = data;
        nextState.hasGotShareCredits =
          data.ShareVideoCredits > 0 &&
          oldShareCredits === data.ShareVideoCredits - 1
            ? true
            : false;
      });
    },
    [actions.getMacUser.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, macUser: false };
      }),

    [actions.setMacUserExplicit.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, setMacExplicit: true };
      }),
    [actions.setMacUserExplicit.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, setMacExplicit: false };
        nextState.macUser = data;
      });
    },
    [actions.setMacUserExplicit.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, setMacExplicit: false };
      }),

    [actions.updateShareCreditFlag.success]: (state, payload) => {
      const { hasGotShareCredits } = payload;
      return produce(state, (nextState) => {
        nextState.hasGotShareCredits = hasGotShareCredits;
      });
    },

    [actions.getMacvStats.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, macStats: true };
      }),
    [actions.getMacvStats.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, macStats: false };
        nextState.stats = data;
      });
    },
    [actions.getMacvStats.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, macStats: false };
        nextState.stats = null;
      }),

    [actions.fetchViewsByIsrc.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, viewsByIsrc: true };
      }),
    [actions.fetchViewsByIsrc.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, viewsByIsrc: false };
        nextState.viewsByIsrc = data;
      });
    },
    [actions.fetchViewsByIsrc.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, viewsByIsrc: false };
      }),

    [actions.fetchTopWatchers.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, topWatchers: true };
      }),
    [actions.fetchTopWatchers.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, topWatchers: false };
        nextState.topWatchers = data;
      });
    },
    [actions.fetchTopWatchers.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, topWatchers: false };
      }),

    [actions.resetMacv.success]: (state) =>
      produce(state, (nextState) => {
        nextState.notRefreshQueue = false;
        nextState.videosQueue = [];
        nextState.sharedVideos = [];
        nextState.watchingVideo = null;
        nextState.todayViewed = [];
        nextState.searchedVideos = [];
        nextState.macUser = null;
        nextState.hasGotShareCredits = false;
        nextState.stats = null;
        nextState.viewsByIsrc = [];
        nextState.topWatchers = [];
      }),

    [actions.fetchBeats.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, fetchBeats: true };
      }),
    [actions.fetchBeats.success]: (state, payload) => {
      const { data, paging, loadMore } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, fetchBeats: false };
        if (loadMore) {
          nextState.beats = [...state.beats, ...data];
        } else {
          nextState.beats = data;
        }
        nextState.beatsPaging = {
          ...state.beatsPaging,
          ...paging,
        };
      });
    },
    [actions.fetchBeats.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, fetchBeats: false };
        // nextState.beats = [];
        // nextState.beatsPaging = {
        //   count: 0,
        //   total: 0,
        //   page: 1,
        //   pageCount: 0,
        //   limit: 25,
        // };
      }),
    [actions.beatsCartItemAdd.success]: (state, payload) => {
      const { IsUpdateCart, ...data } = payload;
      return produce(state, (nextState) => {
        if (!IsUpdateCart) {
          nextState.beatsCartItems = [...state.beatsCartItems, data];
        } else if (IsUpdateCart) {
          const beatsCartItems = [...state.beatsCartItems];
          const found = beatsCartItems.findIndex((item) => item.Id === data.Id);
          if (found >= 0) {
            beatsCartItems[found] = { ...data };
          }
          nextState.beatsCartItems = beatsCartItems;
        }
      });
    },
    [actions.beatsCartItemRemove.success]: (state, payload) => {
      return produce(state, (nextState) => {
        nextState.beatsCartItems = state.beatsCartItems.filter(
          (item) => item.Id !== payload.Id,
        );
      });
    },
    [actions.beatsCartEmpty.success]: (state) => {
      return produce(state, (nextState) => {
        nextState.beatsCartItems = [];
      });
    },
    [actions.beatsCheckout.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, beatsCheckout: true };
      }),
    [actions.beatsCheckout.success]: (state) => {
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, beatsCheckout: false };
      });
    },
    [actions.beatsCheckout.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, beatsCheckout: false };
      }),

    [actions.fetchBeatInvoiceByToken.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, tokenInvoice: true };
      }),
    [actions.fetchBeatInvoiceByToken.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, tokenInvoice: false };
        nextState.tokenInvoice = data;
      });
    },
    [actions.fetchBeatInvoiceByToken.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, tokenInvoice: false };
        nextState.tokenInvoice = null;
      }),

    [actions.fetchBeatsOrders.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, beatsOrders: true };
      }),
    [actions.fetchBeatsOrders.success]: (state, payload) => {
      const { data, paging, loadMore } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, beatsOrders: false };
        if (loadMore) {
          nextState.beatsOrders = [...state.beatsOrders, ...data];
        } else {
          nextState.beatsOrders = data;
        }
        nextState.beatsOrdersPaging = {
          ...state.beatsOrdersPaging,
          ...paging,
        };
      });
    },
    [actions.fetchBeatsOrders.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, beatsOrders: false };
      }),

    [actions.fetchBeatsOrdersCount.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, beatsOrdersCount: true };
      }),
    [actions.fetchBeatsOrdersCount.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, beatsOrdersCount: false };
        nextState.totalBeatsOrders = data;
      });
    },
    [actions.fetchBeatsOrdersCount.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, beatsOrdersCount: false };
      }),
  },
  initialState,
);
