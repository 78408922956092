import { takeLatest, call, put } from 'redux-saga/effects';

import * as api from './api';
import * as actions from './actions';

function* getAnalyticsSaga() {
  try {
    const analytics = yield call(api.fetchAnalytics);
    yield put(actions.getAnalytics.success(analytics));
  } catch (ex) {
    yield put(actions.getAnalytics.failure(ex));
  }
}

function* fetchVideoAutoCompleteSaga({ payload }) {
  try {
    const result = yield call(api.fetchVideoAutoComplete, payload);
    yield put(actions.fetchVideoAutoComplete.success(result));
  } catch (ex) {
    yield put(actions.fetchVideoAutoComplete.failure(ex));
  }
}

function* fetchVideoAnalyticsByCountrySaga({ payload }) {
  try {
    const result = yield call(api.fetchVideoAnalyticsByCountry, payload);
    yield put(actions.fetchVideoAnalyticsByCountry.success(result));
  } catch (ex) {
    yield put(actions.fetchVideoAnalyticsByCountry.failure(ex));
  }
}

function* fetchVideoAnalyticsBySourceSaga({ payload }) {
  try {
    const result = yield call(api.fetchVideoAnalyticsBySource, payload);
    yield put(actions.fetchVideoAnalyticsBySource.success(result));
  } catch (ex) {
    yield put(actions.fetchVideoAnalyticsBySource.failure(ex));
  }
}

function* fetchAudioAnalyticsByCountrySaga({ payload }) {
  try {
    const result = yield call(api.fetchAudioAnalyticsByCountry, payload);
    yield put(actions.fetchAudioAnalyticsByCountry.success(result));
  } catch (ex) {
    yield put(actions.fetchAudioAnalyticsByCountry.failure(ex));
  }
}

function* fetchAudioAnalyticsByDspSaga({ payload }) {
  try {
    const result = yield call(api.fetchAudioAnalyticsByDsp, payload);
    yield put(actions.fetchAudioAnalyticsByDsp.success(result));
  } catch (ex) {
    yield put(actions.fetchAudioAnalyticsByDsp.failure(ex));
  }
}

function* fetchVideoAnalyticsTopCountriesSaga({ payload }) {
  try {
    const result = yield call(api.fetchVideoAnalyticsTopCountries, payload);
    yield put(actions.fetchVideoAnalyticsTopCountries.success(result));
  } catch (ex) {
    yield put(actions.fetchVideoAnalyticsTopCountries.failure(ex));
  }
}

function* fetchVideoAnalyticsByMonthSaga({ payload }) {
  try {
    const result = yield call(api.fetchVideoAnalyticsByMonth, payload);
    yield put(actions.fetchVideoAnalyticsByMonth.success(result));
  } catch (ex) {
    yield put(actions.fetchVideoAnalyticsByMonth.failure(ex));
  }
}

function* fetchVideoAnalyticsTopVideosSaga({ payload }) {
  try {
    const result = yield call(api.fetchVideoAnalyticsTopVideos, payload);
    yield put(actions.fetchVideoAnalyticsTopVideos.success(result));
  } catch (ex) {
    yield put(actions.fetchVideoAnalyticsTopVideos.failure(ex));
  }
}

function* fetchAudioAnalyticsTopCountriesSaga({ payload }) {
  try {
    const result = yield call(api.fetchAudioAnalyticsTopCountries, payload);
    yield put(actions.fetchAudioAnalyticsTopCountries.success(result));
  } catch (ex) {
    yield put(actions.fetchAudioAnalyticsTopCountries.failure(ex));
  }
}

function* fetchAudioAnalyticsByMonthSaga({ payload }) {
  try {
    const result = yield call(api.fetchAudioAnalyticsByMonth, payload);
    yield put(actions.fetchAudioAnalyticsByMonth.success(result));
  } catch (ex) {
    yield put(actions.fetchAudioAnalyticsByMonth.failure(ex));
  }
}

function* fetchAudioAnalyticsRevenueByDspSaga({ payload }) {
  try {
    const result = yield call(api.fetchAudioAnalyticsRevenueByDsp, payload);
    yield put(actions.fetchAudioAnalyticsRevenueByDsp.success(result));
  } catch (ex) {
    yield put(actions.fetchAudioAnalyticsRevenueByDsp.failure(ex));
  }
}

function* analyticsSagas() {
  yield takeLatest(actions.getAnalytics.request, getAnalyticsSaga);
  yield takeLatest(
    actions.fetchVideoAutoComplete.request,
    fetchVideoAutoCompleteSaga,
  );
  yield takeLatest(
    actions.fetchVideoAnalyticsByCountry.request,
    fetchVideoAnalyticsByCountrySaga,
  );
  yield takeLatest(
    actions.fetchVideoAnalyticsBySource.request,
    fetchVideoAnalyticsBySourceSaga,
  );

  yield takeLatest(
    actions.fetchAudioAnalyticsByCountry.request,
    fetchAudioAnalyticsByCountrySaga,
  );
  yield takeLatest(
    actions.fetchAudioAnalyticsByDsp.request,
    fetchAudioAnalyticsByDspSaga,
  );

  yield takeLatest(
    actions.fetchVideoAnalyticsTopCountries.request,
    fetchVideoAnalyticsTopCountriesSaga,
  );

  yield takeLatest(
    actions.fetchVideoAnalyticsByMonth.request,
    fetchVideoAnalyticsByMonthSaga,
  );

  yield takeLatest(
    actions.fetchVideoAnalyticsTopVideos.request,
    fetchVideoAnalyticsTopVideosSaga,
  );

  yield takeLatest(
    actions.fetchAudioAnalyticsTopCountries.request,
    fetchAudioAnalyticsTopCountriesSaga,
  );

  yield takeLatest(
    actions.fetchAudioAnalyticsByMonth.request,
    fetchAudioAnalyticsByMonthSaga,
  );

  yield takeLatest(
    actions.fetchAudioAnalyticsRevenueByDsp.request,
    fetchAudioAnalyticsRevenueByDspSaga,
  );
}

export default analyticsSagas;
