import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import * as layoutSelectors from 'src/modules/layout/redux/selectors';
import * as authSelectors from 'src/modules/auth/redux/selectors';
import * as videoSelectors from 'src/modules/video/redux/selectors';
import * as audioSelectors from 'src/modules/audio/redux/selectors';
import * as channelSelectors from 'src/modules/channel/redux/selectors';
import * as accountSelectors from 'src/modules/account/redux/selectors';
import * as analyticsSelectors from 'src/modules/analytics/redux/selectors';
import * as dashboardSelectors from 'src/modules/dashboard/redux/selectors';
import * as payoutSelectors from 'src/modules/payout/redux/selectors';
import * as macvSelectors from 'src/modules/macv/redux/selectors';
import * as finSelectors from 'src/modules/financial/redux/selectors';
import * as marketingSelectors from 'src/modules/marketing/redux/selectors';
import Loader from 'src/common/Loader/index';

const GlobalLoader = ({
  authLoading,
  userLoading,
  videoLoading,
  audioLoading,
  channelLoading,
  registrationProcessing,
  children,
  isSubscriptionLoading,
  isLoading,
  analyticsLoading,
  dashboardLoading,
  payoutLoading,
  macvLoading,
  finLoading,
  fauLoading,
  linkScanLoading,
  linkLoading,
}) => {
  const loading =
    authLoading ||
    userLoading ||
    videoLoading ||
    audioLoading ||
    channelLoading ||
    registrationProcessing ||
    isSubscriptionLoading ||
    analyticsLoading ||
    isLoading ||
    dashboardLoading ||
    payoutLoading ||
    macvLoading ||
    finLoading ||
    fauLoading ||
    linkScanLoading ||
    linkLoading;

  return (
    <>
      {loading && <Loader minHeight="100vh" />}
      {children}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  authLoading: authSelectors.isLoading,
  userLoading: authSelectors.isUserLoading,
  registrationProcessing: authSelectors.isRegistrationProcessing,
  videoLoading: videoSelectors.getLoadingStatus,
  audioLoading: audioSelectors.getLoadingStatus,
  channelLoading: channelSelectors.getLoadingStatus,
  isSubscriptionLoading: accountSelectors.isSubscriptionLoading,
  isLoading: layoutSelectors.isLoading,
  analyticsLoading: analyticsSelectors.isLoading,
  dashboardLoading: dashboardSelectors.isLoading,
  payoutLoading: payoutSelectors.getLoadingStatus,
  macvLoading: macvSelectors.getLoadingStatus,
  finLoading: finSelectors.getLoadingStatus,
  fauLoading: videoSelectors.getFauLoading,
  linkLoading: marketingSelectors.getLoadingStatus,
  linkScanLoading: marketingSelectors.getScanLoading,
});

export default connect(mapStateToProps)(GlobalLoader);
