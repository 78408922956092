export const root = (state) => state.account;

export const isSubscriptionLoading = (state) => root(state).loading;

export const getSelectedSubscription = (state) =>
  root(state).selectedSubscription;

export const getUpdateSubscriptionError = (state) =>
  root(state).updateSubscriptionError;

export const getExpiredAccounts = (state) => root(state).expiredAccounts;
