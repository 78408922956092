import { actionCreator } from 'src/utils';

const MODULE_NAME = 'CHANNEL';
const ACTION = {
  FETCH_CHANNEL: 'FETCH_CHANNEL',
  SEARCH_CHANNEL: 'SEARCH_CHANNEL',
  CHANGE_PAGE: 'CHANGE_PAGE',
  CHANGE_PAGE_SIZE: 'CHANGE_PAGE_SIZE',
  CREATE_CHANNEL: 'CREATE_CHANNEL',
};

const action = actionCreator(MODULE_NAME);
export const fetchChannels = action(ACTION.FETCH_CHANNEL);
export const searchChannels = action(ACTION.SEARCH_CHANNEL);
export const changePage = action(ACTION.CHANGE_PAGE);
export const changePageSize = action(ACTION.CHANGE_PAGE_SIZE);
export const createChannel = action(ACTION.CREATE_CHANNEL);
