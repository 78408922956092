import { actionCreator } from 'src/utils';

const MODULE_NAME = 'FINANCIAL';
const ACTION = {
  FETCH_BALANCE_STATEMENT: 'FETCH_BALANCE_STATEMENT',
  FETCH_PAYMENT_STATEMENT: 'FETCH_PAYMENT_STATEMENT',
  REQUEST_PAYMENT: 'REQUEST_PAYMENT',
  FETCH_VIDEO_EARNINGS: 'FETCH_VIDEO_EARNINGS',
  FETCH_AUDIO_EARNINGS: 'FETCH_AUDIO_EARNINGS',
};

const action = actionCreator(MODULE_NAME);

export const fetchBalanceStatement = action(ACTION.FETCH_BALANCE_STATEMENT);
export const fetchPaymentStatement = action(ACTION.FETCH_PAYMENT_STATEMENT);
export const requestPayment = action(ACTION.REQUEST_PAYMENT);
export const fetchVideoEarnings = action(ACTION.FETCH_VIDEO_EARNINGS);
export const fetchAudioEarnings = action(ACTION.FETCH_AUDIO_EARNINGS);
