import { Loadable } from 'src/common';

import { PATH } from 'src/constants';

const ChannelListPage = Loadable(() => import('./pages/ChannelList'));
const CreateChannel = Loadable(() =>
  import('./components/createChannel/CreateChannel'),
);

export default [
  {
    component: ChannelListPage,
    path: PATH.CHANNEL,
    name: 'Channel List Page',
    exact: true,
  },
  {
    component: CreateChannel,
    path: PATH.CHANNEL_CREATE,
    name: 'Channel List Page',
    exact: true,
  },
];
