import { createReducer } from 'redux-act';
import produce from 'immer';

import * as actions from './actions';

const initialState = {
  loading: {
    createVideo: false,
    polling: false,
    shortClips: false,
  },
  pollData: {
    status: 'pending',
    submissionId: '',
  },
  shortClips: [],
  shortClipsPagination: {
    maxKeys: 10,
    nextToken: '',
  },
};

export default createReducer(
  {
    [actions.createAudioVideo.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = {
          ...state.loading,
          createVideo: true,
          pollData: {
            status: 'pending',
            submissionId: '',
          },
        };
      }),
    [actions.createAudioVideo.success]: (state) => {
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, createVideo: false };
      });
    },
    [actions.createAudioVideo.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, createVideo: false };
      }),

    [actions.pollConversion.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, polling: true };
      }),
    [actions.pollConversion.success]: (state, payload) => {
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, polling: false };
        nextState.pollData = { ...payload };
      });
    },
    [actions.pollConversion.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, polling: false };
        nextState.pollData = { status: 'error' };
      }),

    [actions.fetchShortClips.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, shortClips: true };
      }),
    [actions.fetchShortClips.success]: (state, payload) => {
      const { data, nextToken } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, shortClips: false };
        nextState.shortClips = [...state.shortClips, ...data];
        nextState.shortClipsPagination.nextToken = nextToken;
      });
    },
    [actions.fetchShortClips.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, shortClips: false };
      }),
  },
  initialState,
);
