import { Loadable } from 'src/common';

import { PATH } from 'src/constants';

const PayoutPage = Loadable(() => import('./pages/Payout'));
const CreatePayoutDirectPage = Loadable(() =>
  import('./pages/CreatePayoutDirect'),
);

const UpdatePayoutDirectPage = Loadable(() =>
  import('./pages/UpdatePayoutDirect'),
);

const CreatePayoutPaypalPage = Loadable(() =>
  import('./pages/CreatePayoutPaypal'),
);

const UpdatePayoutPaypalPage = Loadable(() =>
  import('./pages/UpdatePayoutPaypal'),
);

export default [
  {
    component: PayoutPage,
    path: PATH.PAYOUT,
    name: 'Payout',
    exact: true,
  },
  {
    component: CreatePayoutDirectPage,
    path: `${PATH.PAYOUT}/add-payout-direct`,
    name: 'Create Payout Direct',
    exact: true,
  },
  {
    component: UpdatePayoutDirectPage,
    path: `${PATH.PAYOUT}/update-payout-direct`,
    name: 'Update Payout Direct',
    exact: true,
  },
  {
    component: CreatePayoutPaypalPage,
    path: `${PATH.PAYOUT}/add-payout-paypal`,
    name: 'Create Payout Paypal',
    exact: true,
  },
  {
    component: UpdatePayoutPaypalPage,
    path: `${PATH.PAYOUT}/update-payout-paypal`,
    name: 'Update Payout Paypal',
    exact: true,
  },
];
