import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChartVideoTotalViews = (props) => {
  const data = (canvas) => {
    const bar = canvas.getContext('2d');

    const theme = bar.createLinearGradient(0, 300, 0, 0);
    theme.addColorStop(0, '#4680ff');
    theme.addColorStop(1, '#4680ff');

    const datasets = [
      {
        label: props.label || 'Total views',
        data: props.data.data,
        borderColor: theme,
        backgroundColor: theme,
        hoverBorderColor: theme,
        hoverBackgroundColor: theme,
      },
    ];

    return {
      labels: props.data.labels,
      datasets,
    };
  };

  const customOptions = props.options || {};

  return (
    <Bar
      data={data}
      height={100}
      options={{
        // maintainAspectRatio: false,
        barValueSpacing: 20,
        ...customOptions,
      }}
    />
  );
};

export default BarChartVideoTotalViews;
