import { Loadable } from 'src/common';

import { PATH } from 'src/constants';

// const AnalyticsPage = Loadable(() => import('./pages/Analytics'));
const VideoAnalyticsPage = Loadable(() => import('./pages/VideoAnalytics'));
const AudioAnalyticsPage = Loadable(() => import('./pages/AudioAnalytics'));

export default [
  // {
  //   component: AnalyticsPage,
  //   path: PATH.ANALYTICS,
  //   name: 'Analytics',
  //   exact: true,
  // },
  {
    component: VideoAnalyticsPage,
    path: PATH.VIDEO_ANALYTICS,
    name: 'Video Analytics',
    exact: true,
  },
  {
    component: AudioAnalyticsPage,
    path: PATH.AUDIO_ANALYTICS,
    name: 'Audio Analytics',
    exact: true,
  },
];
