import { Loadable } from 'src/common';

import { PATH } from 'src/constants';

const VideosPage = Loadable(() => import('./pages/VideoList'));
const UploadPage = Loadable(() => import('./pages/UploadVideo'));

const AddToPMVPage = Loadable(() => import('./pages/AddToPMV'));

export default [
  {
    component: VideosPage,
    path: PATH.VIDEO,
    name: 'Videos Page',
    exact: true,
  },
  {
    component: UploadPage,
    path: PATH.VIDEO_UPLOAD,
    name: 'Upload video',
    exact: true,
  },
  {
    component: AddToPMVPage,
    path: PATH.ADD_TO_PMV,
    name: 'Add to PMV',
    exact: true,
  },
];
