import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as api from './api';
import * as actions from './actions';

function* fetchDashboardSaga({ payload }) {
  try {
    const { params = {} } = payload;
    const dashboardData = yield call(api.fetchDashboardData, params);
    yield put(actions.fetchDashboardData.success(dashboardData));
  } catch (ex) {
    yield put(actions.fetchDashboardData.failure(ex));
  }
}

function* dashboardSagas() {
  yield takeLatest(actions.fetchDashboardData.request, fetchDashboardSaga);
}

export default dashboardSagas;
