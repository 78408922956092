import { createReducer } from 'redux-act';
import produce from 'immer';

import * as actions from './actions';

const initialState = {
  data: {},
  loading: false,
};

export default createReducer(
  {
    [actions.fetchDashboardData.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.fetchDashboardData.success]: (state, payload) => {
      return produce(state, (nextState) => {
        nextState.data = payload;
        nextState.loading = false;
      });
    },
    [actions.fetchDashboardData.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
  },
  initialState,
);
