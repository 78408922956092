import { toast } from 'react-toastify';
import { put, takeLatest, select, call } from 'redux-saga/effects';
import { defaultSearchParams } from 'src/constants/searchParams';
import { getSearchText, getPageSize } from './selectors';
import { createXml } from 'src/utils/videoXmlCreator';
import moment from 'moment';
import * as api from './api';
import * as actions from './actions';
import { serialize, genVideoContentConfirmationAnswers } from 'src/utils';
import { fetchUserData } from 'src/modules/auth/redux/actions';
import { compact, get, trim } from 'lodash';

function* fetchVideosSaga({ payload = {} }) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamPage = urlParams.get('page');
    const urlParamLimit = urlParams.get('limit');
    const urlParamSearchText = urlParams.get('search');
    const { page, searchText, limit } = payload;
    const searchTextFromUrlOrDefault =
      typeof searchText === 'string'
        ? searchText
        : urlParamSearchText || defaultSearchParams.search;
    const searchParams = searchTextFromUrlOrDefault
      ? {
          $or: [
            { Artist: { $cont: searchTextFromUrlOrDefault } },
            { Title: { $cont: searchTextFromUrlOrDefault } },
            { Isrc: { $cont: searchTextFromUrlOrDefault } },
          ],
        }
      : [];
    const limitFromUrlOrDefault =
      limit || urlParamLimit || defaultSearchParams.limit;
    const pageFromUrlOrDefault =
      page || urlParamPage || defaultSearchParams.page;
    const paramsWithUrlAndDefault = {
      page: pageFromUrlOrDefault,
      s: searchParams,
      limit: limitFromUrlOrDefault,
    };
    const videos = yield call(api.fetchVideos, paramsWithUrlAndDefault);
    yield put(
      actions.fetchVideos.success({
        ...videos,
        search: searchTextFromUrlOrDefault,
        limit: limitFromUrlOrDefault,
      }),
    );
    window.history.pushState(
      {},
      '',
      serialize({
        limit: limitFromUrlOrDefault,
        page: pageFromUrlOrDefault,
        search: searchTextFromUrlOrDefault,
      }),
    );
  } catch (ex) {
    yield put(actions.fetchVideos.failure(ex));
  }
}

function* searchVideoSaga({ payload }) {
  try {
    yield put(actions.fetchVideos.request({ page: 1, searchText: payload }));
  } catch (ex) {
    yield put(actions.searchVideos.failure(ex));
  }
}

function* changePageSizeSaga({ payload }) {
  try {
    const searchText = yield select(getSearchText);
    yield put(
      actions.fetchVideos.request({ page: 1, searchText, limit: payload }),
    );
  } catch (ex) {
    yield put(actions.changePageSize.failure(ex));
  }
}

function* changePageSaga({ payload }) {
  try {
    const searchText = yield select(getSearchText);
    const pageSize = yield select(getPageSize);
    yield put(
      actions.fetchVideos.request({
        page: payload,
        searchText,
        limit: pageSize,
      }),
    );
  } catch (ex) {
    yield put(actions.changePageSize.failure(ex));
  }
}

function* createVideoSaga({ payload }) {
  const { values, cb, createMode = 'normal' } = payload;

  try {
    const {
      auth: { user },
    } = yield select();

    const CCAnswers = yield call(
      genVideoContentConfirmationAnswers,
      values.ContentConfirmation,
    );

    // validate if ccAnswers are empty then return error
    // these answer are required to create video
    if (CCAnswers.length === 0) {
      throw new Error('Invalid Request');
    }

    const selectedEndpoints = compact(
      Object.keys(values.Endpoints).map((item) =>
        values.Endpoints[item] ? item : null,
      ),
    );
    const Endpoints = [...selectedEndpoints].join(',');

    const audioPublishIncludeMarvmentDist = get(
      user,
      'currentSettings.audioPublishIncludeMarvmentDist',
      null,
    );

    const hasToIncludeMarvmentDistributionDesc =
      Number(
        audioPublishIncludeMarvmentDist
          ? audioPublishIncludeMarvmentDist.value
          : '0',
      ) === 1;
    const utcReleaseDate = moment(
      moment(values.ReleaseDate).format('YYYY-MM-DDTHH:mm:00-04:00'),
    ).utc();
    // const utcReleaseDate = moment(values.ReleaseDate).utc();
    const xml = yield call(
      createXml,
      {
        ...values,
        ReleaseDate: utcReleaseDate.format('MM/DD/YY hh:mm A'),
        description: hasToIncludeMarvmentDistributionDesc
          ? trim(
              `Distributed by: MarvMent\nhttps://marvment.com\n${values.description}`,
            )
          : values.description,
      },
      'image.png',
      'video.mp4',
    );
    // NOTE: upload xml is done via api now
    // const formData = new FormData();
    // formData.append('file', new Blob([xml], { type: 'text/xml' }));
    // yield call(api.uploadVideoXml, values.submissionId, formData);

    yield call(
      api.createVideo,
      {
        Endpoints,
        AccountId: user.AccountId,
        Artist: values.Artist.join(', '),
        ChannelName: values.ChannelName,
        Title: values.Title,
        ReleaseDate: utcReleaseDate.format(),
        CreationDate: moment().utc().format(),
        IsUploaded: 1,
        Isrc: values.Isrc,
        ItunesSale: 0,
        PublishingStatus: 'Pending',
        SubmissionId: values.submissionId || null,
        VideoFlag: 1,
        VideoSourceUrl: values.VideoSourceUrl || '',
        Data: xml,
        FacebookArtistUrl: values.FacebookArtistUrl,
        ContentConfirmationAnswers: CCAnswers,
      },
      createMode,
    );
    yield put(fetchUserData.request({}));
    yield put(actions.createVideo.success({}));
    cb();
  } catch (ex) {
    console.log('Video error', ex);
    yield put(actions.createVideo.failure(ex));
    toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* fetchFacebookArtistUrlSaga({ payload = {} }) {
  try {
    const result = yield call(api.fetchFacebookArtistUrl, payload);
    yield put(actions.fetchFacebookArtistUrl.success(result));
  } catch (ex) {
    yield put(actions.fetchFacebookArtistUrl.failure(ex));
  }
}

function* saveFacebookArtistUrlSaga({ payload: { values, successCb } }) {
  try {
    const result = yield call(api.saveFacebookArtistUrl, values);
    yield put(actions.saveFacebookArtistUrl.success(result));
    toast.success('Facebook artist page details saved successfully');
    if (successCb) {
      successCb();
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.saveFacebookArtistUrl.failure(ex));
  }
}

function* addToPMVSaga({ payload: { values, successCb } }) {
  try {
    const selectedEndpoints = compact(
      Object.keys(values.Endpoints).map((item) =>
        values.Endpoints[item] ? item : null,
      ),
    );
    const Endpoints = [...selectedEndpoints].join(',');

    const result = yield call(api.addToPMV, {
      Id: values.Id, // selected video ID
      Endpoints,
      Isrc: values.Isrc,
      SubmissionId: values.submissionId || null,
      VideoSourceUrl: values.VideoSourceUrl || '',
      FacebookArtistUrl: values.FacebookArtistUrl,
    });
    yield put(actions.addToPMV.success(result));
    toast.success('FaceBook PMV Video request registered successfully');
    if (successCb) {
      successCb();
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.addToPMV.failure(ex));
  }
}

function* videoSagas() {
  yield takeLatest(actions.fetchVideos.request, fetchVideosSaga);
  yield takeLatest(actions.searchVideos.request, searchVideoSaga);
  yield takeLatest(actions.changePage.request, changePageSaga);
  yield takeLatest(actions.changePageSize.request, changePageSizeSaga);
  yield takeLatest(actions.createVideo.request, createVideoSaga);
  yield takeLatest(
    actions.fetchFacebookArtistUrl.request,
    fetchFacebookArtistUrlSaga,
  );
  yield takeLatest(
    actions.saveFacebookArtistUrl.request,
    saveFacebookArtistUrlSaga,
  );
  yield takeLatest(actions.addToPMV.request, addToPMVSaga);
}

export default videoSagas;
