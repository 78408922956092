import { actionCreator } from 'src/utils';

const MODULE_NAME = 'LAYOUT';
const ACTION = {
  TOGGLE_MENU: 'TOGGLE_MENU',
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',
  SET_PAGE_TITLE: 'SET_PAGE_TITLE',
};

const action = actionCreator(MODULE_NAME);

export const toggleMenu = action(ACTION.TOGGLE_MENU);
export const startLoading = action(ACTION.START_LOADING);
export const stopLoading = action(ACTION.STOP_LOADING);
export const setPageTitle = action(ACTION.SET_PAGE_TITLE);
