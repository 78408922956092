import { api } from 'src/utils';

export const updateSubscription = (data, apiToken) => {
  return api.put(
    `/subscriptions`,
    data,
    apiToken
      ? {
          headers: {
            Authorization: 'Bearer ' + apiToken,
          },
        }
      : undefined,
  );
};

export const cancelSubscription = () => {
  return api.delete(`/subscriptions`);
};

export const changePassword = (data) => {
  return api.put(`/accounts/change-password`, data);
};

export const fetchExpiredAccounts = () => {
  return api.get(`/accounts/expired-accounts`);
};

export const updateEmail = (data) => {
  return api.put(`/accounts/update-email`, data);
};
