const serialize = (obj) =>
  '?' +
  Object.keys(obj)
    .reduce(function (a, k) {
      const value =
        typeof obj[k] === 'string' ? obj[k] : JSON.stringify(obj[k]);
      if (value) a.push(k + '=' + value);
      return a;
    }, [])
    .join('&');

export default serialize;
