import { createReducer } from 'redux-act';
import produce from 'immer';

import * as actions from './actions';

const initialState = {
  loading: false,
  searchText: '',
  page: 1,
  pageCount: 1,
  limit: 25,
  total: 0,
  data: [],
};

export default createReducer(
  {
    [actions.fetchChannels.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.fetchChannels.success]: (state, payload) => {
      const { data, pageCount, page, total, search, limit } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        nextState.pageCount = pageCount;
        nextState.page = page;
        nextState.total = total;
        nextState.searchText = search;
        nextState.limit = Number(limit);
        nextState.data = data;
      });
    },
    [actions.changePageSize.request]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.limit = payload;
      }),
    [actions.searchChannels.request]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.searchText = payload;
      }),
    [actions.fetchChannels.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
  },
  initialState,
);
