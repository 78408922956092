import { createSelector } from 'reselect';
import { chunks } from 'src/utils';

export const root = (state) => state.channel;

export const getChannels = (state) => root(state).data;

export const getChannelIdsChunks = createSelector(
  [(state) => root(state).data.ids],
  (channelIds) => {
    return chunks(channelIds, 3);
  },
);

export const getChannelById = createSelector(
  [(state) => root(state).data.byId, (_, props) => props.channelId],
  (channelsById, channelId) => {
    return channelsById[channelId];
  },
);

export const getPage = createSelector(
  [(state) => root(state).page],
  (page) => page,
);

export const getPageCount = createSelector(
  [(state) => root(state).pageCount],
  (pageCount) => pageCount,
);

export const getPageSize = createSelector(
  [(state) => root(state).limit],
  (pageSize) => pageSize,
);

export const getTotalCount = createSelector(
  [(state) => root(state).total],
  (total) => total,
);

export const getSearchText = createSelector(
  [(state) => root(state).searchText],
  (search) => search,
);

export const getChannelData = (state) => root(state).data.byId;

export const getLoadingStatus = (state) => root(state).loading;
