import { put, takeLatest, select, call } from 'redux-saga/effects';
import { defaultSearchParams } from 'src/constants/searchParams';
import { serialize } from 'src/utils';
import { getSearchText, getPageSize } from './selectors';
import * as api from './api';
import * as actions from './actions';
import { startLoading, stopLoading } from 'src/modules/layout/redux/actions';
import { toast } from 'react-toastify';
import { fetchUserData } from 'src/modules/auth/redux/actions';
import { transformChannelLinks } from '../utils';

function* fetchChannelsSaga({ payload = {} }) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamPage = urlParams.get('page');
    const urlParamLimit = urlParams.get('limit');
    const urlParamSearchText = urlParams.get('search');
    const { page, searchText, limit } = payload;
    const searchTextFromUrlOrDefault =
      typeof searchText === 'string'
        ? searchText
        : urlParamSearchText || defaultSearchParams.search;
    const searchParams = searchTextFromUrlOrDefault
      ? { ChannelName: { $cont: searchTextFromUrlOrDefault } }
      : {};
    const limitFromUrlOrDefault =
      limit || urlParamLimit || defaultSearchParams.limit;
    const pageFromUrlOrDefault =
      page || urlParamPage || defaultSearchParams.page;
    const paramsWithUrlAndDefault = {
      page: pageFromUrlOrDefault,
      s: searchParams,
      limit: limitFromUrlOrDefault,
      sort: 'id,DESC',
    };
    const channels = yield call(api.fetchChannels, paramsWithUrlAndDefault);
    yield put(
      actions.fetchChannels.success({
        ...channels,
        search: searchTextFromUrlOrDefault,
        limit: limitFromUrlOrDefault,
      }),
    );
    window.history.pushState(
      {},
      '',
      serialize({
        limit: limitFromUrlOrDefault,
        page: pageFromUrlOrDefault,
        search: searchTextFromUrlOrDefault,
      }),
    );
  } catch (ex) {
    yield put(actions.fetchChannels.failure(ex));
  }
}

function* searchChannelSaga({ payload }) {
  try {
    yield put(actions.fetchChannels.request({ page: 1, searchText: payload }));
  } catch (ex) {
    yield put(actions.searchChannels.failure(ex));
  }
}

function* changePageSizeSaga({ payload }) {
  try {
    const searchText = yield select(getSearchText);
    yield put(
      actions.fetchChannels.request({ page: 1, searchText, limit: payload }),
    );
  } catch (ex) {
    yield put(actions.changePageSize.failure(ex));
  }
}

function* changePageSaga({ payload }) {
  try {
    const searchText = yield select(getSearchText);
    const pageSize = yield select(getPageSize);
    yield put(
      actions.fetchChannels.request({
        page: payload,
        searchText,
        limit: pageSize,
      }),
    );
  } catch (ex) {
    yield put(actions.changePageSize.failure(ex));
  }
}

function* createChannelSaga({ payload }) {
  const { values, cb, noFetch = false } = payload;
  yield put(startLoading.success());
  try {
    const {
      auth: { user },
    } = yield select();

    yield call(api.createChannel, {
      AccountID: user.AccountId,
      ArtistName: values.ArtistName,
      ChannelName: `${values.ChannelName}VEVO`,
      Links: transformChannelLinks(values.Links),
      ArtistImagepath: values.ArtistImagepath,
      BannerImagePath: values.BannerImagePath,
      RequestDate: new Date(),
      IsChannelOnYoutube: values.IsChannelOnYoutube,
    });
    yield put(actions.createChannel.success({}));

    if (!noFetch) {
      yield put(fetchUserData.request({}));
    }
    yield put(stopLoading.success());
    toast.success('Channel created');
    cb();
  } catch (ex) {
    yield put(stopLoading.success());
    yield put(actions.createChannel.failure(ex));
    toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* channelSagas() {
  yield takeLatest(actions.fetchChannels.request, fetchChannelsSaga);
  yield takeLatest(actions.searchChannels.request, searchChannelSaga);
  yield takeLatest(actions.changePage.request, changePageSaga);
  yield takeLatest(actions.changePageSize.request, changePageSizeSaga);
  yield takeLatest(actions.createChannel.request, createChannelSaga);
}

export default channelSagas;
