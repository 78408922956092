const root = (state) => state.auth;

export const getUserData = (state) => root(state).user;

export const isLoading = (state) => root(state).loading;
export const isUserLoggedIn = (state) => root(state).loggedIn;
export const isUserLoading = (state) => root(state).loadingUser;
export const isRegistrationProcessing = (state) => root(state).loading;

export const getSubscriptions = (state) => root(state).subscriptions;

export const getTermsAndConditions = (state) => root(state).termsAndConditions;
export const isTermsLoading = (state) => root(state).loadingTermsAndConditions;

export const getIpInfo = (state) => root(state).ipInfo;
export const isIpInfoLoading = (state) => root(state).loadingIpInfo;

export const getSignupError = (state) => root(state).signupError;
export const getRegisteredUser = (state) => root(state).registered;
export const isUserRegistered = (state) => {
  const registeredUser = root(state).registered;
  return !!(registeredUser && registeredUser.user && registeredUser.token);
};

export const getPhoneSecurityError = (state) => root(state).phoneSecurityError;
export const getUpdatePhoneError = (state) => root(state).updatePhoneError;
