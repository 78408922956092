import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as selectors from '../redux/selectors';
import { PATH } from 'src/constants';

const PrivateRoute = ({ isUserLoggedIn, component, path, exact }) => {
  if (!isUserLoggedIn) {
    return <Redirect to={PATH.SIGNIN} />;
  }

  return <Route component={component} exact={exact} path={path} />;
};

const mapStateToProps = createStructuredSelector({
  isUserLoggedIn: selectors.isUserLoggedIn,
});

export default connect(mapStateToProps)(PrivateRoute);
