import { createReducer } from 'redux-act';
import produce from 'immer';

import * as actions from './actions';

const initialState = {
  loading: false,
  searchText: '',
  page: 1,
  pageCount: 1,
  limit: 25,
  total: 0,
  data: [],
  fauLoading: false,
  facebookArtistUrls: [],
};

export default createReducer(
  {
    [actions.fetchVideos.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.fetchVideos.success]: (state, payload) => {
      const { data, pageCount, page, total, search, limit } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        nextState.pageCount = pageCount;
        nextState.page = page;
        nextState.total = total;
        nextState.searchText = search;
        nextState.limit = Number(limit);
        nextState.data = [...data];
      });
    },
    [actions.changePageSize.request]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.limit = payload;
      }),
    [actions.searchVideos.request]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.searchText = payload;
      }),
    [actions.fetchVideos.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.createVideo.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.createVideo.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.createVideo.success]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),

    [actions.saveFacebookArtistUrl.request]: (state) =>
      produce(state, (nextState) => {
        nextState.fauLoading = true;
      }),
    [actions.saveFacebookArtistUrl.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.fauLoading = false;
        const updatedData = [...state.facebookArtistUrls];
        updatedData.push({ ...data });
        nextState.facebookArtistUrls = updatedData;
      });
    },
    [actions.saveFacebookArtistUrl.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.fauLoading = false;
      }),
    [actions.fetchFacebookArtistUrl.request]: (state) =>
      produce(state, (nextState) => {
        nextState.fauLoading = true;
      }),
    [actions.fetchFacebookArtistUrl.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.fauLoading = false;
        nextState.facebookArtistUrls = [...data];
      });
    },
    [actions.fetchFacebookArtistUrl.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.fauLoading = false;
      }),
    [actions.addToPMV.request]: (state) =>
      produce(state, (nextState) => {
        nextState.fauLoading = true;
      }),
    [actions.addToPMV.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.fauLoading = false;
        const currentRecordIndex = state.data.findIndex(
          (item) => item.Id === data.Id,
        );
        if (currentRecordIndex >= 0) {
          const updatedData = [...state.data];
          updatedData[currentRecordIndex] = { ...data };
          nextState.data = updatedData;
        }
      });
    },
    [actions.addToPMV.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.fauLoading = false;
      }),
  },
  initialState,
);
