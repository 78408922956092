import React from 'react';
import { Pie } from 'react-chartjs-2';

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function initBgColors() {
  const colors = [
    '#3F72E3',
    '#C0C0C0',
    '#3CB371',
    '#FFA500',
    '#DDA0DD',
    '#9932CC',
  ];
  for (let index = 0; index < 500; index += 1) {
    colors.push(getRandomColor());
  }
  return colors;
}

export const PieBgColors = initBgColors();

const PieChart = (props) => {
  const data = () => {
    return {
      labels: props.data.labels,
      datasets: [
        {
          data: props.data.data,
          backgroundColor: PieBgColors,
          // backgroundColor: [
          //   '#3F72E3',
          //   '#C0C0C0',
          //   '#3CB371',
          //   '#FFA500',
          //   '#DDA0DD',
          //   '#9932CC',
          // ],
          // hoverBackgroundColor: [
          //   '#3F72E3',
          //   '#C0C0C0',
          //   '#3CB371',
          //   '#FFA500',
          //   '#DDA0DD',
          //   '#9932CC',
          // ],
          hoverOffset: 4,
        },
      ],
    };
  };

  return (
    <Pie
      data={data}
      responsive
      height="auto"
      options={{
        maintainAspectRatio: false,
        legend: { position: props.legendPosition || 'top' },
        tooltips: props.tooltips || {},
      }}
    />
  );
};

export default PieChart;
