import { put, takeLatest, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import noop from 'lodash/noop';

import { storage } from 'src/utils';

import * as api from './api';
import * as actions from './actions';
import * as macvActions from 'src/modules/macv/redux/actions';

import {
  transformChannelLinks,
  createChannelName,
} from 'src/modules/channel/utils';
import { trim } from 'lodash';

function* loginSaga({ payload: { values, successCb } }) {
  try {
    const user = yield call(api.loginSubmit, values);
    yield put(actions.login.success(user));
    yield put(actions.fetchUserData.request(user.token));

    storage.setItem('token', user.token);

    if (successCb) {
      successCb();
    }
  } catch (ex) {
    yield put(actions.login.failure(ex));
    toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* signUpSaga({ payload: { values, successCb } }) {
  try {
    const signUpData = {
      ...values,
      billing_address: {
        ...values.billing_address,
        state: 'tmp',
      },
      user: {
        ...values.user,
        name: values.user.name + ' ' + values.user.lastName,
        lastName: undefined,
        phone: values.user.phone.replace(/[\\(\\)\s\\-]/g, ''),
        email: trim(values.user.email),
      },
      card: {
        ...values.card,
        number: values.card.number.replace(/\s/g, ''),
      },
      register: {
        ...values.register,
        ChannelName: createChannelName(values.register.ArtistName),
        Links: transformChannelLinks(values.register.Links),
        TermID: values.TermId,
        PhoneNumber: values.register.PhoneNumber.replace(/[\\(\\)\s\\-]/g, ''),
        EmailAddress: trim(values.register.EmailAddress),
      },
    };

    if (values.isSkippedChannel) {
      signUpData.register.ChannelName = '';
      signUpData.register.Links = '';
    }

    const user = yield call(api.signUp, signUpData);
    if (user.code === 1) {
      yield put(actions.signUp.success(user));
      if (successCb) {
        successCb();
      }
      toast.success('User has been successfully registered');
    } else {
      yield put(actions.signUp.failure(user.message));
      toast.error('Transaction failed');
    }
  } catch (ex) {
    yield put(actions.signUp.failure(ex));
    toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* fetchUserDataSaga() {
  try {
    const token = storage.getItem('token');
    if (!token) {
      return;
    }
    const userData = yield call(api.getUser);
    let ipInfo;
    try {
      if (window.location.hostname !== 'localhost') {
        ipInfo = yield call(api.getIpInfo);
      } else {
        ipInfo = { country: '' };
      }
    } catch (ex) {
      ipInfo = { country: '' };
    }
    yield put(actions.fetchUserData.success({ ipInfo, ...userData }));
  } catch (ex) {
    yield put(actions.fetchUserData.failure(ex));
    yield put(actions.logout.trigger());
    yield put(macvActions.resetMacv.success());
  } finally {
    yield put(actions.fetchUserData.fulfill());
  }
}

function* fetchSubscriptionsSaga() {
  try {
    const resp = yield call(api.fetchSubscriptions);
    yield put(actions.fetchSubscriptions.success(resp.data));
  } catch (ex) {
    yield put(actions.fetchSubscriptions.failure(ex));
  }
}

function* logoutSaga({ payload }) {
  storage.removeItem('token');
  storage.removeItem('macv');
  storage.removeItem('countdownClosed');

  yield put(actions.logout.trigger());
  yield put(macvActions.resetMacv.success());

  if (payload.successCb) {
    payload.successCb();
  }
}

function* changePasswordSaga({ payload }) {
  try {
    const { token, password, successCb = noop } = payload;

    yield call(api.changePassword, password, token);

    yield put(actions.changePassword.success());

    successCb();
    toast.success('Password has beed successfully changed');
  } catch (ex) {
    yield put(actions.changePassword.failure(ex));
    toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* forgotPasswordSaga({ payload }) {
  try {
    const { email, successCb = noop } = payload;

    yield call(api.forgotPassword, email);

    yield put(actions.forgotPassword.success());

    successCb();
    toast.success('Link has been successfully sent. Please check your email');
  } catch (ex) {
    yield put(actions.forgotPassword.failure(ex));
    toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* fetchTermsAndConditionsSaga() {
  try {
    const resp = yield call(api.fetchTermsAndConditions);

    yield put(
      actions.fetchTermsAndConditions.success(resp.Terms ? { ...resp } : null),
    );
  } catch (ex) {
    yield put(actions.fetchTermsAndConditions.failure(ex));
  }
}

function* fetchIpInfoSaga() {
  try {
    let ipInfo;
    try {
      if (window.location.hostname !== 'localhost') {
        ipInfo = yield call(api.getIpInfo);
      } else {
        ipInfo = { country: '' };
      }
    } catch (ex) {
      ipInfo = { country: '' };
    }
    yield put(actions.fetchIpInfo.success(ipInfo));
  } catch (ex) {
    yield put(actions.fetchIpInfo.failure(ex));
  }
}

function* setUserSettingsSaga({ payload: { values, successCb } }) {
  try {
    const response = yield call(api.setUserSettings, values);
    if (response.success) {
      toast.success('User settings updated successfully');
      yield put(actions.setUserSettings.success(response.data));
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(response.error);
      yield put(actions.setUserSettings.failure(response.error));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.setUserSettings.failure(ex));
  }
}

function* updateTermsAndConditionsSaga({
  payload: { values, apiToken, successCb },
}) {
  try {
    const response = yield call(api.updateTermsAndConditions, apiToken, values);
    if (response.success) {
      toast.success('Terms & Conditions updated successfully');
      storage.setItem('token', response.token);
      yield put(actions.updateTermsAndConditions.success(response.data));
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(response.error);
      yield put(actions.updateTermsAndConditions.failure(response.error));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.updateTermsAndConditions.failure(ex));
  }
}

function* createUserSaga({ payload: { values, successCb } }) {
  try {
    const [PhoneCountryCode, PhoneDialCode] =
      values.register.PhoneDialCode.split('-');
    const userData = {
      FirstName: values.register.FirstName,
      LastName: values.register.LastName,
      EmailAddress: trim(values.register.EmailAddress),
      Password: values.register.Password,
      PhoneCountryCode,
      PhoneNumber: `${PhoneDialCode}-${values.register.PhoneNumber}`,
      TermID: values.TermId,
    };

    const result = yield call(api.createUser, userData);
    if (result.success) {
      toast.success(result.message);
      storage.setItem('registered-token', result.token, 'session');
      storage.setItem('registered-user', result.data, 'session');
      yield put(actions.createUser.success(result));
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(result.message);
      yield put(actions.createUser.failure(result.message));
    }
  } catch (ex) {
    toast.error(
      'User not created due to some technical reason. Please try later',
    );
    yield put(actions.createUser.failure(ex));
    // toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* updateUserSaga({ payload: { token, accountId, values, successCb } }) {
  try {
    const [PhoneCountryCode, PhoneDialCode] =
      values.register.PhoneDialCode.split('-');
    const userData = {
      FirstName: values.register.FirstName,
      LastName: values.register.LastName,
      EmailAddress: trim(values.register.EmailAddress),
      Password: values.register.Password,
      PhoneCountryCode,
      PhoneNumber: `${PhoneDialCode}-${values.register.PhoneNumber}`,
      TermID: values.TermId,
    };
    const result = yield call(api.updateUser, token, accountId, userData);
    if (result.success) {
      toast.success(result.message);
      storage.setItem('registered-token', result.token, 'session');
      storage.setItem('registered-user', result.data, 'session');
      yield put(actions.updateUser.success(result));
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(result.message);
      yield put(actions.updateUser.failure(result.message));
    }
  } catch (ex) {
    toast.error(
      'User not update due to some technical reason. Please try later',
    );
    yield put(actions.updateUser.failure(ex));
    // toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* requestChannelSaga({
  payload: { token, accountId, values, successCb },
}) {
  try {
    const userData = {
      ArtistName: values.register.ArtistName,
      ChannelName: createChannelName(values.register.ArtistName),
      Links: transformChannelLinks(values.register.Links),
      ArtistId: values.ArtistId,
      ChannelId: values.ChannelId,
    };
    const result = yield call(api.requestChannel, token, accountId, userData);
    if (result.success) {
      toast.success(result.message);
      storage.setItem('registered-artist', result.data.artist, 'session');
      storage.setItem('registered-channel', result.data.channel, 'session');
      yield put(actions.requestChannel.success(result));
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(result.message);
      yield put(actions.requestChannel.failure(result.message));
    }
  } catch (ex) {
    toast.error(
      'Channel request not saved due to some technical reason. Please try later',
    );
    yield put(actions.requestChannel.failure(ex));
    // toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* buyPackageSaga({ payload: { token, accountId, values, successCb } }) {
  try {
    const userData = {
      billing_address: {
        ...values.billing_address,
        state: 'tmp',
      },
      user: {
        ...values.user,
        name: values.user.name + ' ' + values.user.lastName,
        lastName: undefined,
        phone: values.user.phone.replace(/[\\(\\)\s\\-]/g, ''),
        email: trim(values.user.email),
      },
      card: {
        ...values.card,
        number: values.card.number.replace(/\s/g, ''),
      },
      credits: {
        ...values.credits,
      },
      subscription: {
        ...values.subscription,
      },
    };

    const result = yield call(api.buyPackage, token, accountId, userData);
    if (result.success) {
      toast.success(result.message);
      yield put(actions.buyPackage.success(result));
      if (successCb) {
        successCb();
      }
    } else {
      toast.error('Transaction failed');
      yield put(actions.buyPackage.failure(result.message));
    }
  } catch (ex) {
    toast.error('Error while processing payment');
    yield put(actions.buyPackage.failure(ex));
    // toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* generatePhoneSecurityCodeSaga({
  payload: { token, accountId, successCb },
}) {
  try {
    storage.removeItem('registered-phone-code', 'session');
    const result = yield call(api.generatePhoneSecurityCode, token, accountId);
    if (result.success) {
      toast.success(result.message);
      storage.setItem('registered-phone-code', { generated: true }, 'session');
      yield put(actions.generatePhoneSecurityCode.success(result));
      if (successCb) {
        successCb();
      }
    } else {
      // toast.error(result.message);
      yield put(actions.generatePhoneSecurityCode.failure(result.message));
    }
  } catch (ex) {
    yield put(actions.generatePhoneSecurityCode.failure(ex));
    // toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* updatePhoneNumberSaga({
  payload: { token, accountId, values, successCb },
}) {
  try {
    const [PhoneCountryCode, PhoneDialCode] =
      values.register.PhoneDialCode.split('-');
    const userData = {
      PhoneCountryCode,
      PhoneNumber: `${PhoneDialCode}-${values.register.PhoneNumber}`,
    };

    const result = yield call(
      api.updatePhoneNumber,
      token,
      accountId,
      userData,
    );
    if (result.success) {
      toast.success(result.message);

      const { auth } = yield select();
      // if phone update via logged in user
      if (auth.loggedIn && !auth.registered.user) {
        storage.setItem('token', result.token);
      } else {
        storage.setItem('registered-token', result.token, 'session');
        storage.setItem('registered-user', result.data, 'session');
      }
      yield put(actions.updatePhoneNumber.success(result));
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(result.message);
      yield put(actions.updatePhoneNumber.failure(result.message));
    }
  } catch (ex) {
    toast.error(
      'Phone number not updated due to some technical reason. Please try later.',
    );
    yield put(actions.updatePhoneNumber.failure(ex));
    // toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* authSagas() {
  yield takeLatest(actions.logout.request, logoutSaga);
  yield takeLatest(actions.login.request, loginSaga);
  yield takeLatest(actions.signUp.request, signUpSaga);
  yield takeLatest(actions.fetchSubscriptions.request, fetchSubscriptionsSaga);
  yield takeLatest(actions.fetchUserData.request, fetchUserDataSaga);
  yield takeLatest(actions.forgotPassword.request, forgotPasswordSaga);
  yield takeLatest(actions.changePassword.request, changePasswordSaga);
  yield takeLatest(
    actions.fetchTermsAndConditions.request,
    fetchTermsAndConditionsSaga,
  );
  yield takeLatest(actions.fetchIpInfo.request, fetchIpInfoSaga);
  yield takeLatest(actions.setUserSettings.request, setUserSettingsSaga);
  yield takeLatest(
    actions.updateTermsAndConditions.request,
    updateTermsAndConditionsSaga,
  );

  yield takeLatest(actions.createUser.request, createUserSaga);
  yield takeLatest(actions.updateUser.request, updateUserSaga);
  yield takeLatest(actions.requestChannel.request, requestChannelSaga);
  yield takeLatest(actions.buyPackage.request, buyPackageSaga);
  yield takeLatest(
    actions.generatePhoneSecurityCode.request,
    generatePhoneSecurityCodeSaga,
  );

  yield takeLatest(actions.updatePhoneNumber.request, updatePhoneNumberSaga);
}

export default authSagas;
