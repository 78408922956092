import { createReducer } from 'redux-act';
import produce from 'immer';

import * as actions from './actions';
import { defaults } from 'lodash';

const initialState = {
  loggedIn: false,
  user: null,
  loadingUser: true,
  loading: false,
  loadingSubscriptions: false,
  subscriptions: [],
  loadingTermsAndConditions: false,
  termsAndConditions: null,
  loadingIpInfo: false,
  ipInfo: null,
  signupError: null,
  registered: {
    user: null,
    token: null,
    artist: null,
    channel: null,
    phoneSecurityCodeGenerated: false,
  },
  phoneSecurityError: null,
  updatePhoneError: null,
};

export default createReducer(
  {
    [actions.forgotPassword.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.forgotPassword.success]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.forgotPassword.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.changePassword.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.changePassword.success]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.changePassword.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.login.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.login.success]: (state, data) =>
      produce(state, (nextState) => {
        nextState.user = data;
        nextState.loggedIn = true;
        nextState.loading = false;
      }),
    [actions.login.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.signUp.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
        nextState.signupError = null;
      }),
    [actions.signUp.success]: (state, data) =>
      produce(state, (nextState) => {
        nextState.user = data;
        nextState.loading = false;
      }),
    [actions.signUp.failure]: (state, error) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.signupError = error;
      }),
    [actions.fetchUserData.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loadingUser = true;
      }),
    [actions.fetchUserData.success]: (state, user) =>
      produce(state, (nextState) => {
        nextState.loadingUser = false;
        nextState.loggedIn = true;
        nextState.user = user;
      }),
    [actions.fetchUserData.fulfill]: (state) =>
      produce(state, (nextState) => {
        nextState.loadingUser = false;
      }),
    [actions.logout.trigger]: (state) =>
      produce(state, (nextState) => {
        nextState.loggedIn = false;
        nextState.user = null;
      }),
    [actions.fetchSubscriptions.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loadingSubscriptions = true;
      }),
    [actions.fetchSubscriptions.success]: (state, data) =>
      produce(state, (nextState) => {
        nextState.loadingSubscriptions = false;
        nextState.subscriptions = data;
      }),
    [actions.fetchSubscriptions.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loadingSubscriptions = false;
      }),
    [actions.fetchTermsAndConditions.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loadingTermsAndConditions = true;
      }),
    [actions.fetchTermsAndConditions.success]: (state, data) =>
      produce(state, (nextState) => {
        nextState.loadingTermsAndConditions = false;
        nextState.termsAndConditions = data;
      }),
    [actions.fetchTermsAndConditions.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loadingTermsAndConditions = false;
      }),
    [actions.fetchIpInfo.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loadingIpInfo = true;
      }),
    [actions.fetchIpInfo.success]: (state, data) =>
      produce(state, (nextState) => {
        nextState.loadingIpInfo = false;
        nextState.ipInfo = data;
      }),
    [actions.fetchIpInfo.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loadingIpInfo = false;
      }),

    [actions.setUserSettings.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.setUserSettings.success]: (state, payload) => {
      return produce(state, (nextState) => {
        nextState.loading = false;
        nextState.user.currentSettings = { ...payload };
      });
    },
    [actions.setUserSettings.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.updateTermsAndConditions.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.updateTermsAndConditions.success]: (state) => {
      return produce(state, (nextState) => {
        nextState.loading = false;
      });
    },
    [actions.updateTermsAndConditions.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),

    [actions.createUser.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
        nextState.signupError = null;
      }),
    [actions.createUser.success]: (state, payload) => {
      const { data, token } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        nextState.registered.user = data;
        nextState.registered.token = token;
      });
    },
    [actions.createUser.failure]: (state, error) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.signupError = error;
      }),
    [actions.hydrateRegisteredUser.request]: (state, payload) => {
      return produce(state, (nextState) => {
        nextState.registered = payload;
      });
    },

    [actions.updateUser.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
        nextState.signupError = null;
      }),
    [actions.updateUser.success]: (state, payload) => {
      const { data, token } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        nextState.registered.user = data;
        nextState.registered.token = token;
      });
    },
    [actions.updateUser.failure]: (state, error) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.signupError = error;
      }),

    [actions.requestChannel.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
        nextState.signupError = null;
      }),
    [actions.requestChannel.success]: (state, payload) => {
      const {
        data: { artist, channel },
      } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        nextState.registered.artist = artist;
        nextState.registered.channel = channel;
      });
    },
    [actions.requestChannel.failure]: (state, error) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.signupError = error;
      }),

    [actions.buyPackage.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
        nextState.signupError = null;
      }),
    [actions.buyPackage.success]: (state) => {
      return produce(state, (nextState) => {
        nextState.loading = false;
      });
    },
    [actions.buyPackage.failure]: (state, error) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.signupError = error;
      }),

    [actions.generatePhoneSecurityCode.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
        nextState.phoneSecurityError = null;
        nextState.registered.phoneSecurityCodeGenerated = false;
      }),
    [actions.generatePhoneSecurityCode.success]: (state) => {
      return produce(state, (nextState) => {
        nextState.loading = false;
        nextState.registered.phoneSecurityCodeGenerated = true;
      });
    },
    [actions.generatePhoneSecurityCode.failure]: (state, error) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.phoneSecurityError = error;
        nextState.registered.phoneSecurityCodeGenerated = false;
      }),

    [actions.updatePhoneNumber.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
        nextState.updatePhoneError = null;
      }),
    [actions.updatePhoneNumber.success]: (state, payload) => {
      const { data, token } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        if (state.loggedIn && !state.registered.user) {
          nextState.user = defaults({ ...data, token }, state.user);
        } else {
          nextState.registered.user = data;
          nextState.registered.token = token;
        }
      });
    },
    [actions.updatePhoneNumber.failure]: (state, error) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.updatePhoneError = error;
      }),
  },
  initialState,
);
