import * as yup from 'yup';
import moment from 'moment';

import {
  getSignedUrl,
  uploadFileWithSignedUrl,
  transformSignedUrl,
} from 'src/utils';

export const ALBUM_TYPES = {
  SINGLE: 'single',
  EP: 'ep',
  ALBUM: 'album',
};

export const ALBUM_TRACK_VALIDATION = {
  [ALBUM_TYPES.SINGLE]: {
    condition: [1, 1],
    error: 'Must be 1 track',
  },
  [ALBUM_TYPES.EP]: {
    condition: [2, Infinity],
    error: 'Must be more than 2 tracks',
  },
  [ALBUM_TYPES.ALBUM]: {
    condition: [2, Infinity],
    error: 'Must be more than 2 tracks',
  },
};

export const uploadAudioInitialValues = {
  Endpoint: { spotify: true, 'tik-tok': false, 'apple-music': false },
  Artist: '',
  Title: '',
  ReleaseDate: '',
  FeaturedArtist: [],
  genre: '',
  Label: '',
  UPCcode: '',
  IsExplicit: false,
  CopyrightYear: '',
  Copyright: '',
  Country: '',
  AlbumType: ALBUM_TYPES.SINGLE,
  isUpcGeneratedBySystem: false,
  AlbumPrice: '',
};

export const uploadValidationSchema = yup.object().shape({
  AlbumType: yup.string().required('Field is required'),
  ReleaseDate: yup
    .string()
    .required('Field is required')
    .test('ReleaseDate', 'Release Date not valid', (value) =>
      moment(value).isValid(),
    ),
  Title: yup.string().required('Field is required'),
  // Artist: yup.string().required('Field is required'),
  genre: yup.string().required('Field is required'),
  Label: yup.string().required('Field is required'),
  CopyrightYear: yup.string().required('Field is required'),
  Copyright: yup.string().required('Field is required'),
  UPCcode: yup.string().required('Field is required'),
});

export const trackUploadQueue = (function () {
  let queue = [];
  let isUploading = false;
  let queueIndex = -1;
  let progressCb = null;
  let successCb = null;
  let errorCb = null;

  const doUpload = async function () {
    queueIndex++;
    if (queueIndex < queue.length) {
      const file = queue[queueIndex];
      try {
        const signedUrl = await getSignedUrl({
          bucket: 'audio',
          contentType: file.type,
          submissionId: undefined,
          fileFormat: file.name.slice(file.name.lastIndexOf('.') + 1),
        });
        uploadFileWithSignedUrl(
          signedUrl.presignedURL,
          file,
          (progressEvent) => {
            if (progressCb) {
              // total percentage according to queue length;
              progressCb(
                file.name,
                Math.round((progressEvent.loaded * 100) / progressEvent.total),
              );
            }
          },
        )
          .then(() => {
            const isQueueFinished = queueIndex === queue.length - 1;
            if (successCb) {
              successCb(
                file,
                {
                  submissionId: signedUrl.submissionId,
                  audioUrl: transformSignedUrl(signedUrl.presignedURL),
                },
                isQueueFinished,
              );
            }
            if (!isQueueFinished) {
              setTimeout(async function () {
                await doUpload();
              }, 100);
            }
          })
          .catch((err) => {
            const isQueueFinished = queueIndex === queue.length - 1;
            if (errorCb) {
              errorCb(file, err, isQueueFinished);
            }
          });
      } catch (err) {
        const isQueueFinished = queueIndex === queue.length - 1;
        errorCb(file, err, isQueueFinished);
      }
    }
  };

  return {
    setHandler(success, progress, error) {
      successCb = success;
      progressCb = progress;
      errorCb = error;
    },
    async process(file) {
      queue.push(file);
      if (!isUploading) {
        isUploading = true;
        await doUpload();
      }
    },
    reset() {
      queue = [];
      isUploading = false;
      queueIndex = -1;
      successCb = null;
      progressCb = null;
      errorCb = null;
    },
  };
})();
