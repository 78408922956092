import * as js2xmlparser from 'js2xmlparser';

/* eslint-disable */

function removeEmptyProps(videoData) {
  for (const i in videoData) {
    const prop = videoData[i];
    for (const key in prop) {
      if (
        prop[key] === '' ||
        (Object.prototype.toString.call(prop[key]) === '[object Array]' &&
          prop[key].length < 1)
      )
        delete videoData[i];
      // if (videoData[i] === ""  || videoData[i] === undefined) {
      //   delete videoData[i];
      // }
    }
  }

  return videoData;
}

export function createXml(data, imageFilename, videoFilename) {
  const title = data.IsExplicit
    ? (data.Title = data.Title + ' (Explicit)')
    : data.Title;

  return new Promise((resolve) => {
    const videoData = {
      '@': {
        'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
        'xmlns:xsd': 'http://www.w3.org/2001/XMLSchema',
        version: '1.0',
      },
      Url: {
        '#': videoFilename,
      },
      VideoTitle: {
        '#': title,
      },
      Action: {
        '#': 'Insert',
      },
      AllowComments: {
        '#': data.AllowComments,
      },
      AllowResponses: {
        '#': data.AllowResponses,
      },
      VideoISRC: {
        '#': data.Isrc,
      },
      AudioISRC: {
        '#': data.AudioISRC,
      },
      StartTime: {
        '#': data.ReleaseDate,
      },
      Policies: {
        Policy: {
          '@': {
            Rule: 'Share',
            Include: 'Exclude',
          },
          '#': '',
        },
      },
      Genre: {
        '#': data.genre,
      },
      Username: {
        '#': data.ChannelName,
      },
      ContentCategory: {
        '#': 'music',
      },
      VideoDescription: {
        '#': data.description,
      },
      VideoKeywords: {
        '#': data.VideoKeywords,
      },
      Label: {
        '#': data.Label ? data.Label : 'MarvmentLLC',
      },
      ParentalAdvisory: {
        '#': data.ParentAdvisory ? data.ParentAdvisory : '',
      },
      Artists: {
        Artist: data.Artist.join(', '),
      },
      Producers: {
        Producer: data.Producer.join(' ,'),
      },
      Directors: {
        Director: data.Directors.join(' ,'),
      },
      FeaturedArtists: {
        FeaturedArtist: data.FeaturedArtist.join(' ,'),
      },
      Composers: {
        Composer: data.Composers.join(' ,'),
      },
      Editors: {
        Editor: data.Editors.join(' ,'),
      },
      AllowRatings: {
        '#': 'True',
      },
      AllowEmbedding: {
        '#': 'True',
      },
      Target: {
        '#': 'upload,claim',
      },
      AdsenseForVideo: {
        '#': 'Allow',
      },
      InVideoAds: {
        '#': 'Allow',
      },
      RepetoireOwner: {
        '#': data.Label ? data.Label : 'MarvmentLLC',
      },
      DistributionRule: {
        '#': 'Allow',
      },
      ThumbnailUrl: {
        '#': imageFilename,
      },
      HasPreRoll: {
        '#': 'True',
      },
      HasPostRoll: {
        '#': 'True',
      },
      HasMidRollSlots: {
        '#': 'False',
      },
    };

    const options = {
      wrapArray: {
        enabled: false,
      },
      declaration: {
        include: false,
      },
    };

    const videoDataCleaned = removeEmptyProps(videoData);
    const xmlData = js2xmlparser.parse('Video', videoDataCleaned, options);
    return resolve(xmlData);
  });
}
