import { Loadable } from 'src/common';

import { PATH } from 'src/constants';

const AudioListPage = Loadable(() => import('./pages/AudioList'));
const UploadAudio = Loadable(() => import('./pages/UploadAudio'));

export default [
  {
    component: AudioListPage,
    path: PATH.AUDIO,
    name: 'Audio List Page',
    exact: true,
  },
  {
    component: UploadAudio,
    path: PATH.AUDIO_CREATE,
    name: 'Audio List Page',
    exact: true,
  },
];
