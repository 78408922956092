import { Loadable } from 'src/common';

import { PATH } from 'src/constants';

const AccountPage = Loadable(() => import('./pages/Account'));
const ChangeSubscriptionPage = Loadable(() =>
  import('./pages/ChangeSubscription'),
);
const CheckoutSubscriptionPage = Loadable(() =>
  import('./pages/CheckoutSubscription'),
);

const RenewSubscriptionPage = Loadable(() =>
  import('./pages/RenewSubscription'),
);

const ChangePasswordPage = Loadable(() => import('./pages/ChangePassword'));
const UpdateEmailPage = Loadable(() => import('./pages/UpdateEmail'));

const CancelSubscriptionPage = Loadable(() =>
  import('./pages/CancelSubscription'),
);

const CreditsPurchasePage = Loadable(() => import('./pages/CreditsPurchase'));

const ExpiredAccountsPage = Loadable(() => import('./pages/ExpiredAccounts'));

export default [
  {
    component: AccountPage,
    path: PATH.ACCOUNT,
    name: 'My account',
    exact: true,
  },
  {
    component: ChangeSubscriptionPage,
    path: `${PATH.ACCOUNT}/buy-subscription`,
    name: 'Change subscription',
  },
  {
    component: CheckoutSubscriptionPage,
    path: `${PATH.ACCOUNT}/checkout-subscription`,
    name: 'Checkout subscription',
  },
  {
    component: RenewSubscriptionPage,
    path: `${PATH.ACCOUNT}/renew-subscription`,
    name: 'Renew',
  },
  {
    component: UpdateEmailPage,
    path: `${PATH.ACCOUNT}/update-email`,
    name: 'Update Email',
  },
  {
    component: ChangePasswordPage,
    path: `${PATH.ACCOUNT}/change-password`,
    name: 'Change Password',
  },
  {
    component: CancelSubscriptionPage,
    path: `${PATH.ACCOUNT}/cancel-subscription`,
    name: 'Cancel Subscription',
  },
  {
    component: CreditsPurchasePage,
    path: `${PATH.ACCOUNT}/credits-purchase`,
    name: 'Credits Purchase',
  },
  {
    component: CheckoutSubscriptionPage,
    path: `${PATH.ACCOUNT}/checkout-credits`,
    name: 'Checkout Credits',
  },
  {
    component: ExpiredAccountsPage,
    path: `${PATH.ACCOUNT}/expired-accounts`,
    name: 'Expired Accounts',
  },
];
