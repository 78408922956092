import { api, serialize } from 'src/utils';

export const fetchVideosQueue = (params) => {
  const queryStringParams = serialize(params);
  return api(`/macv/user/videos/queue${queryStringParams}`);
};

export const fetchSharedVideos = (params) => {
  const queryStringParams = serialize(params);
  return api(`/macv/user/videos/shared${queryStringParams}`);
};

export const shareVideo = (body) => {
  return api.post('/macv/user/video/share', body);
};

export const updateVideoView = (body) => {
  return api.post(`/macv/user/video/view`, body);
};

export const fetchQueueVideo = (params) => {
  const queryStringParams = serialize(params);
  return api(`/macv/queue/video${queryStringParams}`);
};

export const fetchVideoViewed = (params) => {
  const queryStringParams = serialize(params);
  return api(`/macv/user/videos/viewed${queryStringParams}`);
};

export const searchVideos = (params) => {
  const queryStringParams = serialize(params);
  return api(`/macv/user/videos/search${queryStringParams}`);
};

export const getMacUser = () => {
  return api(`/macv/user/profile`);
};
export const setMacUserExplicit = (body) => {
  return api.put(`/macv/user/profile/explicit`, body);
};

export const getMacvStats = (params) => {
  const queryStringParams = serialize(params);
  return api(`/macv/stats${queryStringParams}`);
};

export const fetchViewsByIsrc = (params) => {
  const queryStringParams = serialize(params);
  return api(`/macv/views-by-isrc${queryStringParams}`);
};

export const fetchTopWatchers = (params) => {
  const queryStringParams = serialize(params);
  return api(`/macv/top-watchers${queryStringParams}`);
};

export const fetchBeats = (params) => {
  const queryStringParams = serialize(params);
  return api(`/macv/beats${queryStringParams}`);
};

export const beatsCheckout = (body) => {
  return api.post(`/macv/beats-checkout`, body);
};

export const fetchBeatInvoiceByToken = (token) => {
  return api(`/macv/beats-token-invoice?token=${token}`);
};

export const fetchBeatsOrders = (params) => {
  const queryStringParams = serialize(params);
  return api(`/macv/beats/orders${queryStringParams}`);
};
export const fetchBeatsOrdersCount = () => {
  return api(`/macv/beats/orders-count`);
};
