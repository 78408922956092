import React, { useEffect } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { store } from 'src/store';
import { theme } from 'src/constants/colors';
import { ScrollToTop, Loadable } from 'src/common';
import { PrivateRoute } from 'src/modules/auth';
import { GlobalStyle } from 'src/style/global';
import { AuthGuard, GlobalLoader } from 'src/hoc';

import { PATH } from 'src/constants';


const Layout = Loadable(() => import('./modules/layout/components/Layout'));
const SignIn = Loadable(() => import('./modules/auth/components/Login'));
const Signup = Loadable(() => import('./modules/auth/components/Registration'));
const ForgotPwd = Loadable(() => import('./modules/auth/components/ForgotPassword'));
const ChangePwd = Loadable(() => import('./modules/auth/components/ChangePassword'));
const RenewAccount = Loadable(() => import('./modules/auth/components/RenewAccount'));
const AgreeTermsWithToken = Loadable(() => import('./modules/auth/components/AgreeTermsWithToken'));
const ValidatePhone = Loadable(() => import('./modules/auth/components/Registration/ValidatePhone'));

const App = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamVerified = urlParams.get('verified');

    if (urlParamVerified === 'true') {
      toast.success('Your email was verified');
    } else if (urlParamVerified === 'false') {
      toast.error("You can't verify email by this link");
    }
  }, []);

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalLoader>
          <AuthGuard>
            <BrowserRouter>
              <ScrollToTop />
              <Switch>
                <Route path={PATH.SIGNIN} exact component={SignIn} />
                <Route path={PATH.SIGNUP} exact component={Signup} />
                <Route path={PATH.FORGOT_PASSWORD} exact component={ForgotPwd} />
                <Route path={PATH.CHANGE_PASSWORD} exact component={ChangePwd} />
                <Route path={PATH.RENEW_ACCOUNT} exact component={RenewAccount} />
                <Route path={PATH.AGREE_TERMS_WITH_TOKEN} exact component={AgreeTermsWithToken} />
                <Route path={PATH.VALIDATE_PHONE} exact component={ValidatePhone}/>
                <PrivateRoute path={PATH.ROOT} component={Layout} />
              </Switch>
            </BrowserRouter>
          </AuthGuard>
        </GlobalLoader>
        <GlobalStyle />
      </ThemeProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        pauseOnHover
      />
    </StoreProvider>
  );
};

export default App;
