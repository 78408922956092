export { default as PATH } from './path';

export const ALBUM_TRACK_PRICES = [
  { value: 0.69, label: '0.69', type: 'single' },
  { value: 0.99, label: '0.99', type: 'single' },
  { value: 1.29, label: '1.29', type: 'single' },
  { value: 1.99, label: '1.99', type: 'album' },
  { value: 2.99, label: '2.99', type: 'album' },
  { value: 3.99, label: '3.99', type: 'album' },
  { value: 4.99, label: '4.99', type: 'album' },
  { value: 5.99, label: '5.99', type: 'album' },
  { value: 6.99, label: '6.99', type: 'album' },
  { value: 7.99, label: '7.99', type: 'album' },
  { value: 8.99, label: '8.99', type: 'album' },
  { value: 9.99, label: '9.99', type: 'album' },
  { value: 10.99, label: '10.99', type: 'album' },
  { value: 11.99, label: '11.99', type: 'album' },
  { value: 12.99, label: '12.99', type: 'album' },
  { value: 13.99, label: '13.99', type: 'album' },
  { value: 14.99, label: '14.99', type: 'album' },
];
