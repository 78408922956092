import { createGlobalStyle } from 'styled-components';

import 'animate.css';
import 'typeface-roboto';
import 'src/assets/scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';

export const GlobalStyle = createGlobalStyle`
  body {
    ${'' /* font-family: Roboto; */}
  }

  button {
    &:focus {
      outline: none;
    }
  }

  .pull-right {
    float: right;
  }

  .pull-left {
    float: left;
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }

  .Toastify__toast {
    border-radius: 3px;
  }

  table tr:nth-child(even) {
    background: #e2e5e8;
  }

  .Analytics {
    .table-responsive {
      overflow-y: auto;
      height: 480px;

      thead th {
        position: sticky;
        top: 0;
      }
    }
  }
`;
