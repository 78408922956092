import { api, serialize } from 'src/utils';

export const fetchBalanceStatement = (params) => {
  const queryStringParams = serialize(params);
  return api(`/financial/balances${queryStringParams}`);
};

export const fetchPaymentStatement = (params) => {
  const queryStringParams = serialize(params);
  return api(`/financial/payments${queryStringParams}`);
};

export const requestPayment = (payload) => {
  return api.post('/financial/request-payment', payload);
};

export const fetchVideoEarnings = (params) => {
  const queryStringParams = serialize(params);
  return api(`/financial/video-earnings${queryStringParams}`);
};

export const fetchAudioEarnings = (params) => {
  const queryStringParams = serialize(params);
  return api(`/financial/audio-earnings${queryStringParams}`);
};
