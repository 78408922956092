import { api } from 'src/utils';

export const loginSubmit = ({ email, password }) => {
  return api.post('/auth/login', { email, password });
};

export const verifyEmail = ({ email }) => {
  return api(`/dbaccount/verify/email/${email}`);
};

export const signUp = (data) => {
  return api.post(`/auth/register`, data);
};

export const fetchSubscriptions = () => {
  return api.get('/subscriptions/plans');
};

export const getUser = () => {
  return api.get('/accounts/profile');
};

export const forgotPassword = (email) => {
  return api.get(`/auth/forgot?email=${email}`);
};

export const changePassword = (password, token) => {
  return api.put('/auth/forgot', { password, token });
};

export const getIpInfo = () => {
  return api.get('https://ipinfo.io/json');
};

export const fetchTermsAndConditions = () => {
  return api.get('/auth/terms-and-conditions');
};

export const validateEmailAlreadyExists = (email, accountId) => {
  return api.get(`/auth/email-exists?email=${email}&accountId=${accountId}`);
};

export const validateRenewToken = (token) => {
  return api.get(`/auth/validate-renew-token?token=${token}`);
};

export const setUserSettings = (payload) => {
  return api.put(`/accounts/settings`, payload);
};

export const validateAgreeTermsToken = (token) => {
  return api.get(`/auth/validate-terms-and-conditions-token?token=${token}`);
};

export const updateTermsAndConditions = (apiToken, body) => {
  return api.put('/accounts/update-terms-conditions', body, {
    notIncludeToken: true,
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
  });
};

export const validatePhoneSecurityCode = (
  apiToken,
  accountId,
  securityCode,
) => {
  return api.get(`/auth/verify-phone-code/${accountId}?code=${securityCode}`, {
    notIncludeToken: true,
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
  });
};
export const generatePhoneSecurityCode = (apiToken, accountId) => {
  return api.get(`/auth/gen-verify-phone-code/${accountId}`, {
    notIncludeToken: true,
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
  });
};

export const createUser = (data) => {
  return api.post('/accounts/create', data);
};

export const updateUser = (apiToken, accountId, data) => {
  return api.put(`/accounts/update/${accountId}`, data, {
    notIncludeToken: true,
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
  });
};

export const requestChannel = (apiToken, accountId, body) => {
  return api.post(`/accounts/request-channel/${accountId}`, body, {
    notIncludeToken: true,
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
  });
};

export const buyPackage = (apiToken, accountId, body) => {
  return api.post(`/accounts/buy-package/${accountId}`, body, {
    notIncludeToken: true,
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
  });
};

export const updatePhoneNumber = (apiToken, accountId, data) => {
  return api.put(`/accounts/update-phone/${accountId}`, data, {
    notIncludeToken: true,
    headers: {
      Authorization: 'Bearer ' + apiToken,
    },
  });
};
