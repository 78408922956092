import { api, getBaseUrl, serialize } from 'src/utils';

export const fetchAudios = (params) => {
  const queryStringParams = serialize(params);
  return api(`/audio/albums${queryStringParams}`);
};

export const createAlbum = (body) => {
  return api.post('/audio/albums', body);
};

export const getUpcCode = (body) => {
  return api.get('/audio/upc', body);
};

export const imageUploadUrl = (upcCode, today) =>
  `${getBaseUrl()}/upload/audio/thumb?key=${today}/${upcCode}`;

export const audioUploadUrl = (upcCode, today) =>
  `${getBaseUrl()}/upload/audio?key=${today}/${upcCode}`;

export const uploadAudioXml = (upcCode, today, body) =>
  api.post(
    `${getBaseUrl()}/upload/audio/metadata?key=${today}/${upcCode}`,
    body,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );

export const verifyUpcCodeExists = (upc) => {
  return api.get(`/audio/upc-exists?upc=${upc}`);
};
