import axios from 'axios';
import get from 'lodash/get';

import { getBaseUrl } from './url';
import { storage } from 'src/utils';

const instance = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(({ notIncludeToken, ...config }) => {
  const token = storage.getItem('token');
  const headers = { ...config.headers };

  if (token && !notIncludeToken) {
    headers.Authorization = token;
  }

  return { ...config, headers };
});

instance.interceptors.response.use(
  (res) => res.data || res,
  ({ response }) => {
    const status = get(response, 'status');

    if (status === 401 || status === 403) {
      storage.removeItem('token');
    }

    return Promise.reject({
      error: get(response, 'message', 'Bad request'),
      status,
      response: response.data,
    });
  },
);

export default instance;
