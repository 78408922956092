import { actionCreator } from 'src/utils';

const MODULE_NAME = 'ACCOUNT';
const ACTION = {
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  SELECT_SUBSCRIPTION: 'SELECT_SUBSCRIPTION',
  FETCH_EXPIRED_ACCOUNTS: 'FETCH_EXPIRED_ACCOUNTS',
  UPDATE_EMAIL: 'UPDATE_EMAIL',
};

const action = actionCreator(MODULE_NAME);

export const updateSubscription = action(ACTION.UPDATE_SUBSCRIPTION);
export const changePassword = action(ACTION.CHANGE_PASSWORD);
export const cancelSubscription = action(ACTION.CANCEL_SUBSCRIPTION);
export const selectSubscription = action(ACTION.SELECT_SUBSCRIPTION);
export const fetchExpiredAccounts = action(ACTION.FETCH_EXPIRED_ACCOUNTS);
export const updateEmail = action(ACTION.UPDATE_EMAIL);
