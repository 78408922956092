import { actionCreator } from 'src/utils';

const MODULE_NAME = 'DASHBOARD';
const ACTION = {
  FETCH_DASHBOARD_DATA: 'FETCH_DASHBOARD_DATA',
};

const action = actionCreator(MODULE_NAME);

export const fetchDashboardData = action(ACTION.FETCH_DASHBOARD_DATA);
