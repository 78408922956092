import { actionCreator } from 'src/utils';

const MODULE_NAME = 'ACCOUNT';
const ACTION = {
  GET_ANALYTICS: 'GET_ANALYTICS',
  SELECT_CHANNEL: 'SELECT_CHANNEL',
  SELECT_VIDEO: 'SELECT_VIDEO',
  RESET: 'RESET',
  FETCH_VIDEO_AUTO_COMPLETE: 'FETCH_VIDEO_AUTO_COMPLETE',
  FETCH_VIDEO_ANALYTICS_BY_COUNTRY: 'FETCH_VIDEO_ANALYTICS_BY_COUNTRY',
  FETCH_VIDEO_ANALYTICS_BY_SOURCE: 'FETCH_VIDEO_ANALYTICS_BY_SOURCE',
  FETCH_VIDEO_ANALYTICS_TOP_COUNTRIES: 'FETCH_VIDEO_ANALYTICS_TOP_COUNTRIES',
  FETCH_VIDEO_ANALYTICS_BY_MONTH: 'FETCH_VIDEO_ANALYTICS_BY_MONTH',
  FETCH_VIDEO_ANALYTICS_TOP_VIDEOS: 'FETCH_VIDEO_ANALYTICS_TOP_VIDEOS',
  FETCH_AUDIO_ANALYTICS_BY_DSP: 'FETCH_AUDIO_ANALYTICS_BY_DSP',
  FETCH_AUDIO_ANALYTICS_BY_COUNTRY: 'FETCH_AUDIO_ANALYTICS_BY_COUNTRY',
  FETCH_AUDIO_ANALYTICS_BY_MONTH: 'FETCH_AUDIO_ANALYTICS_BY_MONTH',
  FETCH_AUDIO_ANALYTICS_TOP_COUNTRIES: 'FETCH_AUDIO_ANALYTICS_TOP_COUNTRIES',
  FETCH_AUDIO_ANALYTICS_REVENUE_BY_DSP: 'FETCH_AUDIO_ANALYTICS_REVENUE_BY_DSP',
};

const action = actionCreator(MODULE_NAME);

export const getAnalytics = action(ACTION.ANALYTICS);
export const selectVideo = action(ACTION.SELECT_VIDEO);
export const selectChannel = action(ACTION.SELECT_CHANNEL);
export const reset = action(ACTION.RESET);

export const fetchVideoAutoComplete = action(ACTION.FETCH_VIDEO_AUTO_COMPLETE);
export const fetchVideoAnalyticsByCountry = action(
  ACTION.FETCH_VIDEO_ANALYTICS_BY_COUNTRY,
);
export const fetchVideoAnalyticsBySource = action(
  ACTION.FETCH_VIDEO_ANALYTICS_BY_SOURCE,
);

export const fetchVideoAnalyticsTopCountries = action(
  ACTION.FETCH_VIDEO_ANALYTICS_TOP_COUNTRIES,
);

export const fetchVideoAnalyticsTopVideos = action(
  ACTION.FETCH_VIDEO_ANALYTICS_TOP_VIDEOS,
);

export const fetchVideoAnalyticsByMonth = action(
  ACTION.FETCH_VIDEO_ANALYTICS_BY_MONTH,
);

export const fetchAudioAnalyticsByCountry = action(
  ACTION.FETCH_AUDIO_ANALYTICS_BY_COUNTRY,
);
export const fetchAudioAnalyticsByDsp = action(
  ACTION.FETCH_AUDIO_ANALYTICS_BY_DSP,
);

export const fetchAudioAnalyticsByMonth = action(
  ACTION.FETCH_AUDIO_ANALYTICS_BY_MONTH,
);

export const fetchAudioAnalyticsTopCountries = action(
  ACTION.FETCH_AUDIO_ANALYTICS_TOP_COUNTRIES,
);

export const fetchAudioAnalyticsRevenueByDsp = action(
  ACTION.FETCH_AUDIO_ANALYTICS_REVENUE_BY_DSP,
);
