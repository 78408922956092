import { api, serialize } from 'src/utils';

export const fetchChannels = (params) => {
  const queryStringParams = serialize(params);
  return api(`/channels${queryStringParams}`);
};

export const createChannel = (body) => {
  return api.post(`/channels/create`, body);
};

export const postChannelImage = (body) => {
  const formData = new FormData();
  formData.append('file', body);
  return api.post(`/upload/channel/image`, formData);
};

export const validateChannelNameAlreadyExists = (name) => {
  return api(`/auth/channel-exists?name=${name}`);
};
