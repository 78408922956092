import { actionCreator } from 'src/utils';

const MODULE_NAME = 'LINKFIRE';
const ACTION = {
  CREATE_RELEASE_LINK: 'CREATE_RELEASE_LINK',
  FETCH_LINKS: 'FETCH_LINKS',
  SEARCH_LINK: 'SEARCH_LINK',
  CHANGE_PAGE: 'CHANGE_PAGE',
  CHANGE_PAGE_SIZE: 'CHANGE_PAGE_SIZE',
  GET_LINK_SCAN_STATUS: 'GET_LINK_SCAN_STATUS',
  DELETE_LINK: 'DELETE_LINK',
  UPDATE_LINK: 'UPDATE_LINK',
  GET_LINK_DETAILS: 'GET_LINK_DETAILS',
  GET_USER_INSIGHTS: 'GET_USER_INSIGHTS',
  GET_BEST_LINKS: 'GET_BEST_LINKS',
  GET_TOP_COUNTRIES: 'GET_TOP_COUNTRIES',
  GET_TOP_CHANNELS: 'GET_TOP_CHANNELS',
  GET_TOP_SERVICES: 'GET_TOP_SERVICES',
  FETCH_PUBLISHED_AUDIOS: 'FETCH_PUBLISHED_AUDIOS',
};

const action = actionCreator(MODULE_NAME);

export const createReleaseLink = action(ACTION.CREATE_RELEASE_LINK);
export const fetchLinks = action(ACTION.FETCH_LINKS);
export const searchLink = action(ACTION.SEARCH_Link);
export const changePageSize = action(ACTION.CHANGE_PAGE_SIZE);
export const changePage = action(ACTION.CHANGE_PAGE);
export const getLinkScanStatus = action(ACTION.GET_LINK_SCAN_STATUS);
export const deleteLink = action(ACTION.DELETE_LINK);
export const updateLink = action(ACTION.UPDATE_LINK);
export const getLinkDetails = action(ACTION.GET_LINK_DETAILS);
export const getUserInsights = action(ACTION.GET_USER_INSIGHTS);
export const getBestLinks = action(ACTION.GET_BEST_LINKS);
export const getTopChannels = action(ACTION.GET_TOP_CHANNELS);
export const getTopCountries = action(ACTION.GET_TOP_COUNTRIES);
export const getTopServices = action(ACTION.GET_TOP_SERVICES);
export const fetchPublishedAudios = action(ACTION.FETCH_PUBLISHED_AUDIOS);
