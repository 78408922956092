import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducer';
import appSagas from './saga';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const composer =
  process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;

const PRELOADED_STATE = undefined;

const store = createStore(
  rootReducer,
  PRELOADED_STATE,
  composer(applyMiddleware(...middlewares)),
);

sagaMiddleware.run(appSagas);

export default store;
