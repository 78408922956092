import { createReducer } from 'redux-act';
import produce from 'immer';

import * as actions from './actions';

const initialState = {
  loading: false,
  searchText: '',
  page: 1,
  pageCount: 1,
  limit: 25,
  total: 0,
  data: [],
  hasAnyAudioCount: 0,
  collapsedIds: [],
};

export default createReducer(
  {
    [actions.fetchAudios.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.fetchAudios.success]: (state, payload) => {
      const {
        data,
        pageCount,
        page,
        total,
        search,
        limit,
        hasAnyAudioCount,
      } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        nextState.pageCount = pageCount;
        nextState.page = page;
        nextState.total = total;
        nextState.searchText = search;
        nextState.limit = Number(limit);
        nextState.data = data;
        nextState.hasAnyAudioCount = hasAnyAudioCount > 0 ? 1 : 9;
      });
    },

    [actions.fetchAudios.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.changePageSize.request]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.limit = payload;
      }),
    [actions.searchAudio.request]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.searchText = payload;
      }),
    [actions.createAlbum.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.createAlbum.success]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.createAlbum.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.toggleCollapse.success]: (state, payload) =>
      produce(state, (nextState) => {
        const isExist = state.collapsedIds.includes(payload);
        if (isExist) {
          nextState.collapsedIds = state.collapsedIds.filter(
            (id) => id !== payload,
          );
        } else {
          nextState.collapsedIds = [payload].concat(state.collapsedIds);
        }
      }),
    [actions.fetchUPC.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.fetchUPC.success]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.fetchUPC.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
  },
  initialState,
);
