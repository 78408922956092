import { createReducer } from 'redux-act';
import produce from 'immer';

import * as actions from './actions';

const initialState = {
  selectedChannel: null,
  selectedVideo: null,
  ready: false,
  loading: false,
  channelsAnalytics: [],
  videoAnalytics: [],
  videoCountryViewsAnalytics: {
    loading: false,
    data: [],
  },
  videoSourceViewsAnalytics: { loading: false, data: [] },
  videoAutoCompleteList: { loading: false, data: [] },
  videoAnalyticsDataBySource: { loading: false, data: [] },
  videoAnalyticsDataByCountry: {
    data: [],
    loading: false,
    paging: {
      page: 1,
      pageSize: 20,
      totalCount: 0,
      pageCount: 0,
    },
  },
  videoAnalyticsDataTopCountries: { loading: false, data: [] },
  videoAnalyticsDataByMonth: { loading: false, data: [] },
  videoAnalyticsDataTopVideos: { loading: false, data: [] },
  audioAnalyticsDataByDsp: { loading: false, data: [] },
  audioAnalyticsDataByCountry: {
    data: [],
    loading: false,
    paging: {
      page: 1,
      pageSize: 20,
      totalCount: 0,
      pageCount: 0,
    },
  },
  audioAnalyticsDataTopCountries: { loading: false, data: [] },
  audioAnalyticsDataByMonth: { loading: false, data: [] },
  audioAnalyticsDataRevenueByDsp: { loading: false, data: [] },
};

export default createReducer(
  {
    [actions.getAnalytics.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
        nextState.ready = false;
      }),
    [actions.getAnalytics.success]: (state, analytics) =>
      produce(state, (nextState) => {
        nextState.ready = true;
        nextState.loading = false;
        nextState.channelsAnalytics = analytics.channelsAnalytics || [];
        nextState.videoAnalytics = analytics.videoAnalytics || [];
        nextState.videoCountryViewsAnalytics =
          analytics.videoCountryViewsAnalytics || [];
        nextState.videoSourceViewsAnalytics =
          analytics.videoSourceViewsAnalytics || [];
      }),
    [actions.getAnalytics.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.ready = true;
      }),
    [actions.selectChannel.success]: (state, channelId) =>
      produce(state, (nextState) => {
        nextState.selectedChannel = channelId;
        nextState.selectedVideo = null;
      }),
    [actions.selectVideo.success]: (state, videoIsrc) =>
      produce(state, (nextState) => {
        if (videoIsrc) {
          const video = state.videoAnalytics.find(
            ({ ISRC }) => videoIsrc === ISRC,
          );
          if (video && video.ChannelId) {
            nextState.selectChannel = video.ChannelId;
          }
        }
        nextState.selectedVideo = videoIsrc;
      }),
    [actions.reset.success]: (state) =>
      produce(state, (nextState) => {
        nextState.selectedChannel = null;
        nextState.selectedVideo = null;
        nextState.ready = false;
        nextState.channelsAnalytics = [];
        nextState.videoAnalytics = [];
        nextState.videoCountryViewsAnalytics = [];
        nextState.videoSourceViewsAnalytics = [];
      }),

    [actions.fetchVideoAutoComplete.request]: (state) =>
      produce(state, (nextState) => {
        nextState.videoAutoCompleteList.loading = true;
      }),
    [actions.fetchVideoAutoComplete.success]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.videoAutoCompleteList.loading = false;
        nextState.videoAutoCompleteList.data = payload.data || [];
      }),
    [actions.fetchVideoAutoComplete.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.videoAutoCompleteList.loading = false;
      }),

    [actions.fetchVideoAnalyticsBySource.request]: (state) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataBySource.loading = true;
      }),
    [actions.fetchVideoAnalyticsBySource.success]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataBySource.loading = false;
        nextState.videoAnalyticsDataBySource.data = payload.data || [];
      }),
    [actions.fetchVideoAnalyticsBySource.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataBySource.loading = false;
        nextState.videoAnalyticsDataBySource.data = [];
      }),
    [actions.fetchVideoAnalyticsByCountry.request]: (state) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataByCountry.loading = true;
      }),
    [actions.fetchVideoAnalyticsByCountry.success]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataByCountry.loading = false;
        nextState.videoAnalyticsDataByCountry.data = payload.data || [];
        nextState.videoAnalyticsDataByCountry.paging = {
          ...state.videoAnalyticsDataByCountry.paging,
          ...payload.paging,
        };
      }),
    [actions.fetchVideoAnalyticsByCountry.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataByCountry.loading = false;
        nextState.videoAnalyticsDataByCountry.data = [];
        nextState.videoAnalyticsDataByCountry.paging =
          state.videoAnalyticsDataByCountry.paging;
      }),
    [actions.fetchVideoAnalyticsTopCountries.request]: (state) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataTopCountries.loading = true;
      }),
    [actions.fetchVideoAnalyticsTopCountries.success]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataTopCountries.loading = false;
        nextState.videoAnalyticsDataTopCountries.data = payload.data || [];
      }),
    [actions.fetchVideoAnalyticsTopCountries.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataTopCountries.loading = false;
        nextState.videoAnalyticsDataTopCountries.data = [];
      }),
    [actions.fetchVideoAnalyticsByMonth.request]: (state) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataByMonth.loading = true;
      }),
    [actions.fetchVideoAnalyticsByMonth.success]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataByMonth.loading = false;
        nextState.videoAnalyticsDataByMonth.data = payload.data || [];
      }),
    [actions.fetchVideoAnalyticsByMonth.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataByMonth.loading = false;
        nextState.videoAnalyticsDataByMonth.data = [];
      }),

    [actions.fetchVideoAnalyticsTopVideos.request]: (state) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataTopVideos.loading = true;
      }),
    [actions.fetchVideoAnalyticsTopVideos.success]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataTopVideos.loading = false;
        nextState.videoAnalyticsDataTopVideos.data = payload.data || [];
      }),
    [actions.fetchVideoAnalyticsTopVideos.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.videoAnalyticsDataTopVideos.loading = false;
        nextState.videoAnalyticsDataTopVideos.data = [];
      }),

    [actions.fetchAudioAnalyticsByDsp.request]: (state) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataByDsp.loading = true;
      }),
    [actions.fetchAudioAnalyticsByDsp.success]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataByDsp.loading = false;
        nextState.audioAnalyticsDataByDsp.data = payload.data || [];
      }),
    [actions.fetchAudioAnalyticsByDsp.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataByDsp.loading = false;
        nextState.audioAnalyticsDataByDsp.data = [];
      }),
    [actions.fetchAudioAnalyticsByCountry.request]: (state) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataByCountry.loading = true;
      }),
    [actions.fetchAudioAnalyticsByCountry.success]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataByCountry.loading = false;
        nextState.audioAnalyticsDataByCountry.data = payload.data || [];
        nextState.audioAnalyticsDataByCountry.paging = {
          ...state.audioAnalyticsDataByCountry.paging,
          ...payload.paging,
        };
      }),
    [actions.fetchAudioAnalyticsByCountry.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataByCountry.loading = false;
        nextState.audioAnalyticsDataByCountry.data = [];
        nextState.audioAnalyticsDataByCountry.paging =
          state.audioAnalyticsDataByCountry.paging;
      }),

    [actions.fetchAudioAnalyticsTopCountries.request]: (state) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataTopCountries.loading = true;
      }),
    [actions.fetchAudioAnalyticsTopCountries.success]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataTopCountries.loading = false;
        nextState.audioAnalyticsDataTopCountries.data = payload.data || [];
      }),
    [actions.fetchAudioAnalyticsTopCountries.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataTopCountries.loading = false;
        nextState.audioAnalyticsDataTopCountries.data = [];
      }),

    [actions.fetchAudioAnalyticsByMonth.request]: (state) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataByMonth.loading = true;
      }),
    [actions.fetchAudioAnalyticsByMonth.success]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataByMonth.loading = false;
        nextState.audioAnalyticsDataByMonth.data = payload.data || [];
      }),
    [actions.fetchAudioAnalyticsByMonth.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataByMonth.loading = false;
        nextState.audioAnalyticsDataByMonth.data = [];
      }),

    [actions.fetchAudioAnalyticsRevenueByDsp.request]: (state) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataRevenueByDsp.loading = true;
      }),
    [actions.fetchAudioAnalyticsRevenueByDsp.success]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataRevenueByDsp.loading = false;
        nextState.audioAnalyticsDataRevenueByDsp.data = payload.data || [];
      }),
    [actions.fetchAudioAnalyticsRevenueByDsp.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.audioAnalyticsDataRevenueByDsp.loading = false;
        nextState.audioAnalyticsDataRevenueByDsp.data = [];
      }),
  },
  initialState,
);
