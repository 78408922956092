import { createReducer } from 'redux-act';
import produce from 'immer';

import * as actions from './actions';

const initialState = {
  loading: {
    balanceStatement: false,
    paymentStatement: false,
    requestPayment: false,
    videoEarnings: false,
    audioEarnings: false,
  },
  balanceStatement: [],
  balancePaging: {
    count: 0,
    total: 0,
    page: 1,
    pageCount: 0,
    limit: 25,
  },
  availableVideoBalance: 0,
  availableAudioBalance: 0,
  payments: [],
  paymentPaging: {
    count: 0,
    total: 0,
    page: 1,
    pageCount: 0,
    limit: 25,
  },
  paymentsDue: 0,
  earningsTillDate: 0,
  videoEarnings: [],
  videoEarningsPaging: {
    count: 0,
    total: 0,
    page: 1,
    pageCount: 0,
    limit: 25,
  },
  totalVideoEarnings: 0,
  audioEarnings: [],
  audioEarningsPaging: {
    count: 0,
    total: 0,
    page: 1,
    pageCount: 0,
    limit: 25,
  },
  totalAudioEarnings: 0,
};

export default createReducer(
  {
    [actions.fetchBalanceStatement.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, balanceStatement: true };
      }),
    [actions.fetchBalanceStatement.success]: (state, payload) => {
      const { data, paging, availableBalance, audioBalance } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, balanceStatement: false };
        nextState.balanceStatement = data;
        nextState.balancePaging = {
          ...state.balancePaging,
          ...paging,
        };
        nextState.availableVideoBalance = availableBalance;
        nextState.availableAudioBalance = audioBalance;
      });
    },
    [actions.fetchBalanceStatement.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, balanceStatement: false };
        nextState.balanceStatement = [];
        nextState.balancePaging = {
          count: 0,
          total: 0,
          page: 1,
          pageCount: 0,
          limit: 25,
        };
        nextState.availableVideoBalance = 0;
        nextState.availableAudioBalance = 0;
      }),
    [actions.fetchPaymentStatement.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, paymentStatement: true };
      }),
    [actions.fetchPaymentStatement.success]: (state, payload) => {
      const { data, paging, paymentsDue, earningsTillDate } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, paymentStatement: false };
        nextState.payments = data;
        nextState.paymentPaging = {
          ...state.paymentPaging,
          ...paging,
        };
        nextState.paymentsDue = paymentsDue;
        nextState.earningsTillDate = earningsTillDate;
      });
    },
    [actions.fetchPaymentStatement.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, paymentStatement: false };
        nextState.paymentStatement = [];
        nextState.balancePaging = {
          count: 0,
          total: 0,
          page: 1,
          pageCount: 0,
          limit: 25,
        };
        nextState.paymentsDue = 0;
        nextState.earningsTillDate = 0;
      }),

    [actions.requestPayment.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, requestPayment: true };
      }),
    [actions.requestPayment.success]: (state) => {
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, requestPayment: false };
      });
    },
    [actions.requestPayment.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, requestPayment: false };
      }),

    [actions.fetchVideoEarnings.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, videoEarnings: true };
      }),
    [actions.fetchVideoEarnings.success]: (state, payload) => {
      const { data, paging, totalEarnings } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, videoEarnings: false };
        nextState.videoEarnings = data;
        nextState.videoEarningsPaging = {
          ...state.videoEarningsPaging,
          ...paging,
        };
        nextState.totalVideoEarnings = totalEarnings;
      });
    },
    [actions.fetchVideoEarnings.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, videoEarnings: false };
        nextState.videoEarnings = [];
        nextState.videoEarningsPaging = {
          count: 0,
          total: 0,
          page: 1,
          pageCount: 0,
          limit: 25,
        };
        nextState.totalVideoEarnings = 0;
      }),

    [actions.fetchAudioEarnings.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, audioEarnings: true };
      }),
    [actions.fetchAudioEarnings.success]: (state, payload) => {
      const { data, paging, totalEarnings } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, audioEarnings: false };
        nextState.audioEarnings = data;
        nextState.audioEarningsPaging = {
          ...state.audioEarningsPaging,
          ...paging,
        };
        nextState.totalAudioEarnings = totalEarnings;
      });
    },
    [actions.fetchAudioEarnings.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, audioEarnings: false };
        nextState.audioEarnings = [];
        nextState.audioEarningsPaging = {
          count: 0,
          total: 0,
          page: 1,
          pageCount: 0,
          limit: 25,
        };
        nextState.totalAudioEarnings = 0;
      }),
  },
  initialState,
);
