import { Loadable } from 'src/common';

import { PATH } from 'src/constants';

const CreateLinkPage = Loadable(() => import('./pages/CreateLink'));
const CreateLinkSetupPage = Loadable(() => import('./pages/CreateLinkSetup'));
const LinksListPage = Loadable(() => import('./pages/LinksList'));
const EditLinkPage = Loadable(() => import('./pages/EditLink'));
const CreateLinkScanPage = Loadable(() => import('./pages/CreateLinkScan'));
const InsightsPage = Loadable(() => import('./pages/Insights'));
const LinkInsightsPage = Loadable(() => import('./pages/LinkInsights'));

export default [
  {
    component: CreateLinkPage,
    path: `${PATH.MARKETING_CREATE_LINK}`,
    name: 'Create Link',
    exact: true,
  },
  {
    component: CreateLinkSetupPage,
    path: `${PATH.MARKETING_CREATE_LINK_SETUP}`,
    name: 'Create Link Setup',
    exact: true,
  },
  {
    component: CreateLinkScanPage,
    path: `${PATH.MARKETING_CREATE_LINK_STATUS}`,
    name: 'Create Link Scan Status',
    exact: true,
  },
  {
    component: LinksListPage,
    path: `${PATH.MARKETING_LINKS}`,
    name: 'Links',
    exact: true,
  },
  {
    component: EditLinkPage,
    path: `${PATH.MARKETING_EDIT_LINK}`,
    name: 'Edit Link',
    exact: true,
  },
  {
    component: InsightsPage,
    path: `${PATH.MARKETING_INSIGHTS}`,
    name: 'Insights',
    exact: true,
  },

  {
    component: LinkInsightsPage,
    path: `${PATH.MARKETING_LINK_INSIGHTS}/:id`,
    name: 'Link Insights',
    exact: true,
  },
];
