import { compact } from 'lodash';

export const root = (state) => state.financial;

export const getLoadingStatus = (state, type = '') => {
  const { loading } = root(state);
  if (!type) {
    return loading[type];
  }
  return compact(Object.values(loading)).length > 0;
};

export const getBalanceStatement = (state) => root(state).balanceStatement;
export const getBalancePaging = (state) => root(state).balancePaging;
export const getVideoBalance = (state) => root(state).availableVideoBalance;
export const getAudioBalance = (state) => root(state).availableAudioBalance;

export const getPaymentStatement = (state) => root(state).payments;
export const getPaymentPaging = (state) => root(state).paymentPaging;
export const getPaymentsDue = (state) => root(state).paymentsDue;
export const getEarningsTillDate = (state) => root(state).earningsTillDate;
export const getVideoEarnings = (state) => root(state).videoEarnings;
export const getVideoEarningsPaging = (state) =>
  root(state).videoEarningsPaging;

export const getTotalVideoEarnings = (state) => root(state).totalVideoEarnings;

export const getAudioEarnings = (state) => root(state).audioEarnings;
export const getAudioEarningsPaging = (state) =>
  root(state).audioEarningsPaging;

export const getTotalAudioEarnings = (state) => root(state).totalAudioEarnings;
