/* eslint-disable prefer-destructuring */
import numeral from 'numeral';
import qs from 'query-string';
import defaultApi from './api';
import defaultSerialize from './queryString';

export { default as actionCreator } from './actionCreator';
export { default as storage } from './storage';
export { default as api } from './api';
export { default as chunks } from './chunks';
export { default as serialize } from './queryString';
export { default as stringUtils } from './string';

export * from './url';

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string

  const byteString = window.atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const _ia = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  }

  const dataView = new DataView(arrayBuffer);
  //   var blob = new Blob([dataView], { type: mimeString });
  const blob = new Blob([dataView.buffer], { type: mimeString });
  return blob;
}

export function formatCurrency(value) {
  return numeral(value).format('$0,0.00');
}

export const getSignedUrl = (payload) => {
  return defaultApi.post(`/upload/signed-url${defaultSerialize(payload)}`, {
    rnd: +new Date(),
  });
};

export const uploadFileWithSignedUrl = (signedUrl, file, onProgress) => {
  return defaultApi.put(signedUrl, file, {
    onUploadProgress: (progressEvent) => onProgress(progressEvent),
    notIncludeToken: true,
    headers: {
      Accept: '*/*',
      'Content-Type': file.type,
    },
  });
};

export const transformSignedUrl = (signedUrl) => {
  const urlObj = qs.parseUrl(signedUrl);
  return urlObj.url;
};

export const genVideoContentConfirmationAnswers = (data) => {
  const questions = data.questions || {};
  const answers = Object.keys(questions).map((key) => {
    return {
      QuestionCode: key,
      AnswerCode: questions[key],
    };
  });
  return answers;
};

export const getPhoneWithDialCode = (phone) => {
  let normalizedPhone = phone.replace(/[\s_(+)]+/g, '');
  const phoneParts = normalizedPhone.split('-');
  let last10Digits = '';
  let dialCode = '';
  if (phoneParts.length === 2) {
    dialCode = phoneParts[0];
    last10Digits = phoneParts[1];
  } else {
    normalizedPhone = normalizedPhone.replace(/[-]+/g, '');
    last10Digits = normalizedPhone.slice(-10);
    dialCode = normalizedPhone.substr(0, normalizedPhone.length - 10);
  }

  return {
    PhoneNumber: last10Digits,
    PhoneDialCode: dialCode,
  };
};
