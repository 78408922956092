import { put, takeLatest, call, select } from 'redux-saga/effects';
// import { defaultSearchParams } from 'src/constants/searchParams';
// import { serialize } from 'src/utils';
import { toast } from 'react-toastify';
import * as actions from './actions';
import * as api from './api';
import moment from 'moment';
import { trim } from 'lodash';

function* fetchVideosQueueSaga({ payload }) {
  try {
    const {
      macv: { notRefreshQueue },
    } = yield select();
    const videosQueue = yield call(api.fetchVideosQueue, {
      notRefreshQueue: notRefreshQueue ? 1 : 0,
      ...payload,
    });
    yield put(
      actions.fetchVideosQueue.success({
        ...videosQueue,
      }),
    );
  } catch (ex) {
    yield put(actions.fetchVideosQueue.failure(ex));
  }
}

function* fetchSharedVideosSaga({ payload }) {
  try {
    const sharedVideos = yield call(api.fetchSharedVideos, payload || {});
    yield put(
      actions.fetchSharedVideos.success({
        ...sharedVideos,
      }),
    );
  } catch (ex) {
    yield put(actions.fetchSharedVideos.failure(ex));
  }
}

function* shareVideoSaga({ payload: { videoId, username, successCb } }) {
  try {
    const {
      auth: { user },
    } = yield select();
    const response = yield call(api.shareVideo, {
      SharedBy: user.AccountId,
      VideoId: videoId,
      Username: username,
    });
    if (response.success) {
      yield put(actions.shareVideo.success({}));
      toast.success('Your video shared successfully with Marvment Community');
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(response.message);
      yield put(actions.shareVideo.failure(response.message));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.shareVideo.failure(ex));
  }
}

function* updateVideoViewSaga({ payload: { queueVideoId, view, successCb } }) {
  try {
    const {
      auth: { user },
    } = yield select();
    const response = yield call(api.updateVideoView, {
      ViewedBy: user.AccountId,
      SharedVideoQueueId: queueVideoId,
      ViewAs: view,
    });
    if (response.success) {
      yield put(actions.updateVideoView.success({}));
      if (successCb) {
        successCb();
      }
    } else {
      yield put(actions.updateVideoView.failure(response.message));
    }
  } catch (ex) {
    yield put(actions.updateVideoView.failure(ex));
  }
}

function* fetchQueueVideoSaga({ payload }) {
  try {
    const queueVideo = yield call(api.fetchQueueVideo, payload);
    yield put(
      actions.fetchQueueVideo.success({
        ...queueVideo,
      }),
    );
  } catch (ex) {
    yield put(actions.fetchQueueVideo.failure(ex));
  }
}

function* fetchTodayViewedSaga({ payload: { successCb } }) {
  try {
    const viewedVideo = yield call(api.fetchVideoViewed, {
      mode: 'afterShared',
      date: moment().utc().format('YYYY-MM-DD'),
    });
    yield put(
      actions.fetchTodayViewed.success({
        ...viewedVideo,
      }),
    );
    if (successCb) {
      successCb();
    }
  } catch (ex) {
    console.log('Error in today viewed saga', ex);
    yield put(actions.fetchTodayViewed.failure(ex));
  }
}

function* searchVideosSaga({ payload: { search } }) {
  try {
    const viewedVideo = yield call(api.searchVideos, {
      search,
    });
    yield put(
      actions.searchVideos.success({
        ...viewedVideo,
      }),
    );
  } catch (ex) {
    console.log('Error in search videos saga', ex);
    yield put(actions.searchVideos.failure(ex));
  }
}

function* getMacUserSaga({ payload: { successCb } }) {
  try {
    const macUser = yield call(api.getMacUser);
    yield put(
      actions.getMacUser.success({
        ...macUser,
      }),
    );
    if (successCb) {
      successCb();
    }
  } catch (ex) {
    yield put(actions.getMacUser.failure(ex));
  }
}

function* setMacUserExplicitSaga({
  payload: { isExplicitAllowed, successCb },
}) {
  try {
    // const {
    //   auth: { user },
    // } = yield select();
    const response = yield call(api.setMacUserExplicit, {
      IsExplicitAllowed: isExplicitAllowed,
    });
    yield put(actions.setMacUserExplicit.success(response));
    if (successCb) {
      successCb();
    }
  } catch (ex) {
    yield put(actions.setMacUserExplicit.failure(ex));
  }
}

function* getMacvStatsSaga({ payload: { successCb } }) {
  try {
    const stats = yield call(api.getMacvStats, {});
    yield put(
      actions.getMacvStats.success({
        ...stats,
      }),
    );

    if (successCb) {
      successCb();
    }
  } catch (ex) {
    yield put(actions.getMacvStats.failure(ex));
  }
}

function* fetchViewsByIsrcSaga({ payload: { params, successCb } }) {
  try {
    const views = yield call(api.fetchViewsByIsrc, { ...params });
    yield put(
      actions.fetchViewsByIsrc.success({
        ...views,
      }),
    );
    if (successCb) {
      successCb();
    }
  } catch (ex) {
    yield put(actions.fetchViewsByIsrc.failure(ex));
  }
}

function* fetchTopWatchersSaga({ payload: { params, successCb } }) {
  try {
    const views = yield call(api.fetchTopWatchers, { ...params });
    yield put(
      actions.fetchTopWatchers.success({
        ...views,
      }),
    );
    if (successCb) {
      successCb();
    }
  } catch (ex) {
    yield put(actions.fetchTopWatchers.failure(ex));
  }
}

function* fetchBeatsSaga({
  payload: { successCb, loadMore = false, ...data },
}) {
  try {
    const result = yield call(api.fetchBeats, { ...data });
    yield put(
      actions.fetchBeats.success({
        ...result,
        loadMore,
      }),
    );
    if (successCb) {
      successCb();
    }
  } catch (ex) {
    yield put(actions.fetchBeats.failure(ex));
  }
}

function* beatsCheckoutSaga({
  payload: { values, cartItems, cartTotal, successCb },
}) {
  try {
    const {
      auth: { user },
    } = yield select();
    const response = yield call(api.beatsCheckout, {
      PurchasedBy: user.AccountId,
      Card: {
        ...values.card,
        number: values.card.number.replace(/\s/g, ''),
        credit_card_type: undefined,
      },
      User: {
        email: trim(values.user.email),
        name: values.user.name + ' ' + values.user.lastName,
      },
      Address: { ...values.billing_address },
      CartItems: cartItems.map((item) => ({
        Id: item.Id,
        Title: item.Title,
        Price: item.selectedPrice,
        LicenseType: item.selectedTier,
        LeaseLabel: item.LeaseLabel,
        LeaseIncludes: item.LeaseIncludes,
        BeatImage: `${item.SubmissionId}/beat-image.png`,
      })),
      CartTotal: cartTotal,
    });
    if (response.code === 1) {
      yield put(actions.beatsCheckout.success({}));
      if (successCb) {
        successCb(response.downloadToken);
      }
      toast.success('Beats checkout done successfully');
    } else {
      yield put(actions.beatsCheckout.failure(response.message));
      toast.error('Transaction failed');
    }
  } catch (ex) {
    yield put(actions.beatsCheckout.failure(ex));
    toast.error('Transaction failed');
  }
}

function* fetchBeatInvoiceByTokenSaga({ payload: { successCb, token } }) {
  try {
    const result = yield call(api.fetchBeatInvoiceByToken, token);
    yield put(
      actions.fetchBeatInvoiceByToken.success({
        ...result,
      }),
    );
    if (successCb) {
      successCb();
    }
  } catch (ex) {
    yield put(actions.fetchBeatInvoiceByToken.failure(ex));
  }
}

function* fetchBeatsOrdersSaga({
  payload: { successCb, loadMore = false, ...data },
}) {
  try {
    const result = yield call(api.fetchBeatsOrders, { ...data });
    yield put(
      actions.fetchBeatsOrders.success({
        ...result,
        loadMore,
      }),
    );
    if (successCb) {
      successCb();
    }
  } catch (ex) {
    yield put(actions.fetchBeatsOrders.failure(ex));
  }
}

function* fetchBeatsOrdersCountSaga({ payload: { successCb } }) {
  try {
    const result = yield call(api.fetchBeatsOrdersCount);
    yield put(
      actions.fetchBeatsOrdersCount.success({
        ...result,
      }),
    );
    if (successCb) {
      successCb();
    }
  } catch (ex) {
    yield put(actions.fetchBeatsOrdersCount.failure(ex));
  }
}

function* demoSagas() {
  yield takeLatest(actions.fetchVideosQueue.request, fetchVideosQueueSaga);
  yield takeLatest(actions.fetchSharedVideos.request, fetchSharedVideosSaga);
  yield takeLatest(actions.shareVideo.request, shareVideoSaga);
  yield takeLatest(actions.updateVideoView.request, updateVideoViewSaga);
  yield takeLatest(actions.fetchQueueVideo.request, fetchQueueVideoSaga);
  yield takeLatest(actions.fetchTodayViewed.request, fetchTodayViewedSaga);
  yield takeLatest(actions.searchVideos.request, searchVideosSaga);
  yield takeLatest(actions.getMacUser.request, getMacUserSaga);
  yield takeLatest(actions.setMacUserExplicit.request, setMacUserExplicitSaga);
  yield takeLatest(actions.getMacvStats.request, getMacvStatsSaga);
  yield takeLatest(actions.fetchViewsByIsrc.request, fetchViewsByIsrcSaga);
  yield takeLatest(actions.fetchTopWatchers.request, fetchTopWatchersSaga);
  yield takeLatest(actions.fetchBeats.request, fetchBeatsSaga);
  yield takeLatest(actions.beatsCheckout.request, beatsCheckoutSaga);
  yield takeLatest(
    actions.fetchBeatInvoiceByToken.request,
    fetchBeatInvoiceByTokenSaga,
  );
  yield takeLatest(actions.fetchBeatsOrders.request, fetchBeatsOrdersSaga);
  yield takeLatest(
    actions.fetchBeatsOrdersCount.request,
    fetchBeatsOrdersCountSaga,
  );
}

export default demoSagas;
