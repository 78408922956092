import sortBy from 'lodash/sortBy';
import isNaN from 'lodash/isNaN';
import orderBy from 'lodash/orderBy';
import moment from 'moment';

export const root = (state) => state.analytics;

export const getSelectedChannel = (state) => root(state).selectedChannel;
export const getSelectedVideo = (state) => root(state).selectedVideo;
export const isLoading = (state) => {
  return root(state).loading;
  // ||
  // root(state).videoSourceViewsAnalytics.loading ||
  // root(state).videoAnalyticsDataBySource.loading ||
  // root(state).videoAnalyticsDataByCountry.loading ||
  // root(state).videoAnalyticsDataTopCountries.loading ||
  // root(state).videoAnalyticsDataByMonth.loading ||
  // root(state).videoAnalyticsDataTopVideos.loading ||
  // root(state).audioAnalyticsDataByDsp.loading ||
  // root(state).audioAnalyticsDataByCountry.loading ||
  // root(state).audioAnalyticsDataTopCountries.loading ||
  // root(state).audioAnalyticsDataByMonth.loading ||
  // root(state).audioAnalyticsDataRevenueByDsp.loading
};
export const isReady = (state) => root(state).ready;
export const channelsAnalytics = (state) => root(state).channelsAnalytics;
export const videoAnalytics = (state) => root(state).videoAnalytics;

export const videoCountryViews = (state) => {
  const {
    videoCountryViewsAnalytics,
    videoAnalytics: videos,
    selectedVideo,
    selectedChannel,
  } = root(state);

  if (selectedVideo) {
    const _video = videos.find(({ ISRC }) => selectedVideo === ISRC);
    if (_video) {
      return videoCountryViewsAnalytics.filter(
        ({ Artist }) => Artist === _video.Artist,
      );
    }
  }

  if (selectedChannel) {
    return videoCountryViewsAnalytics.filter(
      ({ ChannelId }) => ChannelId === selectedChannel,
    );
  }

  return videoCountryViewsAnalytics;
};

export const getChannels = (state) => {
  return root(state).channelsAnalytics.map((item) => ({
    value: item.ChannelId,
    label: item.ChannelName,
  }));
};

export const getVideos = (state) => {
  const { selectedChannel } = root(state);

  if (selectedChannel) {
    return root(state)
      .videoAnalytics.filter(({ ChannelId }) => ChannelId === selectedChannel)
      .map((item) => ({
        value: item.ISRC,
        label: item.YoutubeVideoTitle,
      }));
  }

  return root(state).videoAnalytics.map((item) => ({
    value: item.ISRC,
    label: item.YoutubeVideoTitle,
  }));
};

export const getWidgetAnalytics = (state) => {
  const {
    channelsAnalytics: channelsAnalyticsData,
    selectedChannel,
    selectedVideo,
  } = root(state);

  const data = channelsAnalyticsData.reduce(
    (acc, item) => {
      if (selectedChannel && selectedChannel === item.ChannelId) {
        acc.items += 1;
        acc.totalViews += item.Views;
        acc.totalWatch += item.WatchTimeMinutes;

        const percentage = Number(item.AvgViewed.split('%')[0]);
        acc.totalPercentageViews += isNaN(percentage) ? 0 : percentage;
      } else if (!selectedChannel && !selectedVideo) {
        acc.items += 1;
        acc.totalViews += item.Views;
        acc.totalWatch += item.WatchTimeMinutes;

        const percentage = Number(item.AvgViewed.split('%')[0]);
        acc.totalPercentageViews += isNaN(percentage) ? 0 : percentage;
      }
      return acc;
    },
    {
      totalPercentageViews: 0,
      totalViews: 0,
      totalWatch: 0,
      items: 0,
    },
  );

  const totalPercentageViews = (
    data.totalPercentageViews / (data.items || 1)
  ).toFixed(1);

  return {
    totalPercentageViews:
      totalPercentageViews === '0.0' ? 0 : totalPercentageViews,
    totalViews: data.totalViews,
    totalWatch: data.totalWatch,
  };
};

export const getGeoAnalytics = (state) => {
  const {
    videoCountryViewsAnalytics,
    videoAnalytics: videos,
    selectedChannel,
    selectedVideo,
  } = root(state);

  const analytics = videoCountryViewsAnalytics.reduce(
    (acc, { CountryCode, ViewCount, ChannelId, Artist }) => {
      if (selectedChannel && selectedChannel === ChannelId) {
        if (acc[CountryCode]) {
          acc[CountryCode] += ViewCount;
        } else {
          acc[CountryCode] = ViewCount;
        }
      } else if (selectedVideo) {
        const _video = videos.find(({ ISRC }) => selectedVideo === ISRC);
        if (_video && _video.Artist === Artist) {
          if (acc[CountryCode]) {
            acc[CountryCode] += ViewCount;
          } else {
            acc[CountryCode] = ViewCount;
          }
        }
      } else if (!selectedChannel && !selectedVideo) {
        if (acc[CountryCode]) {
          acc[CountryCode] += ViewCount;
        } else {
          acc[CountryCode] = ViewCount;
        }
      }
      return acc;
    },
    {},
  );

  return Object.keys(analytics).map((country) => ({
    country,
    value: analytics[country],
  }));
};

export const getChannelAnalyticsByViews = (state) => {
  const { selectedChannel, selectedVideo, channelsAnalytics: channels } = root(
    state,
  );

  const analytics = channels.reduce(
    (acc, { ChannelName, ChannelId, Views }) => {
      if (selectedChannel && selectedChannel === ChannelId) {
        acc.labels.push(ChannelName);
        acc.data.push(Views);
      } else if (!selectedChannel && !selectedVideo) {
        acc.labels.push(ChannelName);
        acc.data.push(Views);
      }

      return acc;
    },
    {
      labels: [],
      data: [],
    },
  );

  return {
    labels: analytics.labels.splice(0, 5),
    data: analytics.data.splice(0, 5),
  };
};

export const getChannelAnalyticsByWatchTime = (state) => {
  const { selectedChannel, selectedVideo, channelsAnalytics: channels } = root(
    state,
  );

  const analytics = channels.reduce(
    (acc, { WatchTimeMinutes, ChannelId, ChannelName }) => {
      if (selectedChannel && selectedChannel === ChannelId) {
        acc.labels.push(ChannelName);
        acc.data.push(WatchTimeMinutes);
      } else if (!selectedChannel && !selectedVideo) {
        acc.labels.push(ChannelName);
        acc.data.push(WatchTimeMinutes);
      }

      return acc;
    },
    {
      labels: [],
      data: [],
    },
  );

  return {
    labels: analytics.labels.splice(0, 5),
    data: analytics.data.splice(0, 5),
  };
};

export const getChannelAnalyticsByLifetimeViews = (state) => {
  const { selectedChannel, selectedVideo, channelsAnalytics: channels } = root(
    state,
  );

  const analytics = channels.reduce(
    (acc, { LifetimeViews, ChannelId, ChannelName }) => {
      if (selectedChannel && selectedChannel === ChannelId) {
        acc.labels.push(ChannelName);
        acc.data.push(LifetimeViews);
      } else if (!selectedChannel && !selectedVideo) {
        acc.labels.push(ChannelName);
        acc.data.push(LifetimeViews);
      }

      return acc;
    },
    {
      labels: [],
      data: [],
    },
  );

  return {
    labels: analytics.labels.splice(0, 5),
    data: analytics.data.splice(0, 5),
  };
};

export const getVideoAnalyticsBySourceDistribution = (state) => {
  const {
    videoSourceViewsAnalytics,
    videoAnalytics: videos,
    selectedChannel,
    selectedVideo,
  } = root(state);

  const analytics = videoSourceViewsAnalytics.reduce(
    (acc, { Source, ViewCount, ChannelId, Artist }) => {
      if (
        (selectedChannel && ChannelId === selectedChannel) ||
        (!selectedChannel && !selectedVideo)
      ) {
        acc[Source] = acc[Source] ? acc[Source] + ViewCount : ViewCount;
      } else if (selectedVideo) {
        const _video = videos.find(({ ISRC }) => ISRC === selectedVideo);
        if (_video && _video.Artist === Artist) {
          acc[Source] = acc[Source] ? acc[Source] + ViewCount : ViewCount;
        }
      }

      return acc;
    },
    {},
  );

  return Object.keys(analytics).map((key) => ({
    source: key,
    views: analytics[key],
  }));
};

export const getVideoAnalyticsByWeeklyDynamic = (state) => {
  const { selectedChannel, selectedVideo, videoAnalytics: videos } = root(
    state,
  );

  const filteredVideo = videos.filter(({ ChannelId, Artist }) => {
    if (selectedVideo) {
      const _video = videos.find(({ ISRC }) => ISRC === selectedVideo);
      if (_video && _video.Artist === Artist) {
        return true;
      }
    }

    if (selectedChannel && ChannelId === selectedChannel) {
      return true;
    }

    if (!selectedChannel && !selectedVideo) {
      return true;
    }

    return false;
  });

  const sortedVideo = sortBy(filteredVideo, ({ Views }) => -Views).splice(0, 5);

  const analytics = sortedVideo.reduce(
    (acc, { First7DayViews, Last7DayViews, YoutubeVideoTitle }) => {
      acc.labels.push(`${YoutubeVideoTitle.substring(0, 20)}...`);
      acc.data1.push(Last7DayViews);
      acc.data2.push(First7DayViews);

      return acc;
    },
    {
      labels: [],
      data1: [],
      data2: [],
    },
  );

  return analytics;
};

export const getVideoAnalyticsByTotalViews = (state) => {
  const { selectedChannel, selectedVideo, videoAnalytics: videos } = root(
    state,
  );

  const filteredVideo = videos.filter(({ ChannelId, Artist }) => {
    if (selectedVideo) {
      const _video = videos.find(({ ISRC }) => ISRC === selectedVideo);
      if (_video && _video.Artist === Artist) {
        return true;
      }
    }

    if (selectedChannel && ChannelId === selectedChannel) {
      return true;
    }

    if (!selectedChannel && !selectedVideo) {
      return true;
    }

    return false;
  });

  const sortedVideo = sortBy(filteredVideo, ({ Views }) => -Views).splice(0, 5);

  const analytics = sortedVideo.reduce(
    (acc, { Views, YoutubeVideoTitle }) => {
      acc.labels.push(`${YoutubeVideoTitle.substring(0, 25)}...`);
      acc.data.push(Views);

      return acc;
    },
    {
      labels: [],
      data: [],
    },
  );

  return analytics;
};

export const isEmpty = (state) => {
  const {
    ready,
    videoCountryViewsAnalytics,
    videoSourceViewsAnalytics,
    channelsAnalytics: channels,
    videoAnalytics: video,
  } = root(state);

  return (
    ready &&
    !video.length &&
    !channels.length &&
    !videoSourceViewsAnalytics.length &&
    !videoCountryViewsAnalytics.length
  );
};

export const getVideoAutoCompleteList = (state) =>
  root(state).videoAutoCompleteList;

export const getVideoAnalyticsDataBySource = (state) => {
  const entity = root(state).videoAnalyticsDataBySource;
  const data = entity.data.reduce(
    (acc, { TotalStreams, Source }) => {
      acc.labels.push(`${Source.substring(0, 25)}...:(${TotalStreams})`);
      acc.data.push(Number(TotalStreams));
      return acc;
    },
    {
      labels: [],
      data: [],
    },
  );
  return { loading: entity.loading, data };
};

export const getVideoAnalyticsDataByCountry = (state) =>
  root(state).videoAnalyticsDataByCountry;

export const getVideoAnalyticsTopCountries = (state) => {
  const entity = root(state).videoAnalyticsDataTopCountries;
  const data = entity.data.reduce(
    (acc, { TotalStreams, Country }) => {
      acc.labels.push(`${Country.substring(0, 25)}:(${TotalStreams})`);
      acc.data.push(Number(TotalStreams));
      return acc;
    },
    {
      labels: [],
      data: [],
    },
  );
  return { loading: entity.loading, data };
};

export const getVideoAnalyticsTopVideos = (state) => {
  const entity = root(state).videoAnalyticsDataTopVideos;
  const data = entity.data.reduce(
    (acc, { TotalRevenue, Title }) => {
      acc.labels.push(`${Title.substring(0, 25)}:(${TotalRevenue})`);
      acc.data.push(Number(TotalRevenue));
      return acc;
    },
    {
      labels: [],
      data: [],
    },
  );
  return { loading: entity.loading, data };
};

export const getVideoAnalyticsByMonth = (state) => {
  const entity = root(state).videoAnalyticsDataByMonth;
  const data = entity.data.reduce(
    (acc, { TotalRevenue, StatementDate }) => {
      acc.labels.push(`${moment(StatementDate).format('MMM YYYY')}`);
      acc.data.push(Number(TotalRevenue));
      return acc;
    },
    {
      labels: [],
      data: [],
      maxValue: 0,
    },
  );
  return { loading: entity.loading, data };
};

export const getVideoMaxRevenueByMonth = (state) => {
  const sortedDesc = orderBy(
    root(state).videoAnalyticsDataByMonth.data,
    ['TotalRevenue'],
    ['desc'],
  );

  return sortedDesc.length ? sortedDesc[0].TotalRevenue : 0;
};

export const getAudioAnalyticsDataByDsp = (state) => {
  const entity = root(state).audioAnalyticsDataByDsp;
  const data = entity.data.reduce(
    (acc, { TotalStreams, Source }) => {
      acc.labels.push(`${Source.substring(0, 25)}...:(${TotalStreams})`);
      acc.data.push(Number(TotalStreams));
      return acc;
    },
    {
      labels: [],
      data: [],
    },
  );
  return { loading: entity.loading, data };
};

export const getAudioAnalyticsDataByCountry = (state) =>
  root(state).audioAnalyticsDataByCountry;

export const getAudioAnalyticsTopCountries = (state) => {
  const entity = root(state).audioAnalyticsDataTopCountries;
  const data = entity.data.reduce(
    (acc, { TotalStreams, Country }) => {
      acc.labels.push(`${Country.substring(0, 25)}:(${TotalStreams})`);
      acc.data.push(Number(TotalStreams));
      return acc;
    },
    {
      labels: [],
      data: [],
    },
  );
  return { loading: entity.loading, data };
};

export const getAudioAnalyticsByMonth = (state) => {
  const entity = root(state).audioAnalyticsDataByMonth;
  const data = entity.data.reduce(
    (acc, { TotalRevenue, StatementDate }) => {
      acc.labels.push(`${moment(StatementDate).format('MMM YYYY')}`);
      acc.data.push(Number(TotalRevenue));
      return acc;
    },
    {
      labels: [],
      data: [],
      maxValue: 0,
    },
  );
  return { loading: entity.loading, data };
};

export const getAudioMaxRevenueByMonth = (state) => {
  const sortedDesc = orderBy(
    root(state).audioAnalyticsDataByMonth.data,
    ['TotalRevenue'],
    ['desc'],
  );

  return sortedDesc.length ? sortedDesc[0].TotalRevenue : 0;
};

export const getAudioAnalyticsDataRevenueByDsp = (state) => {
  const entity = root(state).audioAnalyticsDataRevenueByDsp;
  const data = entity.data.reduce(
    (acc, { TotalRevenue, Source }) => {
      acc.labels.push(`${Source.substring(0, 25)}...:(${TotalRevenue})`);
      acc.data.push(Number(TotalRevenue));
      return acc;
    },
    {
      labels: [],
      data: [],
    },
  );
  return { loading: entity.loading, data };
};
