import { put, takeLatest, call, select } from 'redux-saga/effects';
import { defaultSearchParams } from 'src/constants/searchParams';
import { serialize } from 'src/utils';
import { toast } from 'react-toastify';
import * as actions from './actions';
import * as api from './api';

function* fetchPayoutSaga({ payload = {} }) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamPage = urlParams.get('page');
    const urlParamLimit = urlParams.get('limit');
    const urlParamSearch = urlParams.get('search');
    const { page, searchText, limit } = payload;
    const searchFromUrlOrDefault =
      typeof searchText === 'string'
        ? searchText
        : urlParamSearch || defaultSearchParams.search;
    const limitFromUrlOrDefault =
      limit || urlParamLimit || defaultSearchParams.limit;
    const pageFromUrlOrDefault =
      page || urlParamPage || defaultSearchParams.page;
    const paramsWithUrlAndDefault = {
      page: page || urlParamPage || defaultSearchParams.page,
      search: searchFromUrlOrDefault,
      limit: limit || urlParamLimit || defaultSearchParams.limit,
    };
    const audios = yield call(api.fetchPayout, paramsWithUrlAndDefault);
    yield put(
      actions.fetchPayout.success({
        ...audios,
        search: searchFromUrlOrDefault,
        limit: limitFromUrlOrDefault,
      }),
    );
    window.history.pushState(
      {},
      '',
      serialize({
        limit: limitFromUrlOrDefault,
        page: pageFromUrlOrDefault,
        search: searchFromUrlOrDefault,
      }),
    );
  } catch (ex) {
    yield put(actions.fetchPayout.failure(ex));
  }
}

function* addPayoutDirectSaga({ payload: { values, successCb } }) {
  try {
    const {
      auth: { user },
    } = yield select();
    const response = yield call(api.addPayoutDirect, {
      AccountId: user.AccountId,
      BankName: values.bankName,
      AccountFName: values.accountFName,
      AccountLName: values.accountLName,
      AccountType: values.accountType,
      RoutingNumber: values.routingNumber,
      AccountNumber: values.accountNumber,
    });
    if (response.success) {
      yield put(actions.addPayoutDirect.success({}));
      toast.success('Direct deposit payout method saved successfully');
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(response.message);
      yield put(actions.addPayoutDirect.failure(response.message));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.addPayoutDirect.failure(ex));
  }
}

function* updatePayoutDirectSaga({ payload: { values, payoutId, successCb } }) {
  try {
    const {
      auth: { user },
    } = yield select();
    const response = yield call(api.updatePayoutDirect, payoutId, {
      AccountId: user.AccountId,
      BankName: values.bankName,
      AccountFName: values.accountFName,
      AccountLName: values.accountLName,
      AccountType: values.accountType,
      RoutingNumber: values.routingNumber,
      AccountNumber: values.accountNumber,
    });
    if (response.success) {
      yield put(actions.updatePayoutDirect.success({}));
      toast.success('Direct deposit payout method saved successfully');
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(response.message);
      yield put(actions.updatePayoutDirect.failure(response.message));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.updatePayoutDirect.failure(ex));
  }
}

function* deletePayoutDirectSaga({ payload: { payoutId, successCb } }) {
  try {
    const response = yield call(api.deletePayoutDirect, payoutId);
    if (response.success) {
      yield put(actions.deletePayoutDirect.success({}));
      toast.success('Direct deposit payout method cancelled successfully');
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(response.message);
      yield put(actions.deletePayoutDirect.failure(response.message));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.deletePayoutDirect.failure(ex));
  }
}

function* addPayoutPaypalSaga({ payload: { values, successCb } }) {
  try {
    const {
      auth: { user },
    } = yield select();
    const response = yield call(api.addPayoutPaypal, {
      AccountId: user.AccountId,
      PayPalEmail: values.paypalEmail,
      FirstName: values.firstName,
      LastName: values.lastName,
    });
    if (response.success) {
      yield put(actions.addPayoutPaypal.success({}));
      toast.success('PayPal payout method saved successfully');
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(response.message);
      yield put(actions.addPayoutPaypal.failure(response.message));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.addPayoutPaypal.failure(ex));
  }
}

function* updatePayoutPaypalSaga({ payload: { values, payoutId, successCb } }) {
  try {
    const {
      auth: { user },
    } = yield select();
    const response = yield call(api.updatePayoutPaypal, payoutId, {
      AccountId: user.AccountId,
      PayPalEmail: values.paypalEmail,
      FirstName: values.firstName,
      LastName: values.lastName,
    });
    if (response.success) {
      yield put(actions.updatePayoutPaypal.success({}));
      toast.success('PayPal payout method saved successfully');
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(response.message);
      yield put(actions.updatePayoutPaypal.failure(response.message));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.updatePayoutPaypal.failure(ex));
  }
}

function* deletePayoutPaypalSaga({ payload: { payoutId, successCb } }) {
  try {
    const response = yield call(api.deletePayoutPaypal, payoutId);
    if (response.success) {
      yield put(actions.deletePayoutPaypal.success({}));
      toast.success('PayPal payout method cancelled successfully');
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(response.message);
      yield put(actions.deletePayoutPaypal.failure(response.message));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.deletePayoutPaypal.failure(ex));
  }
}

function* fetchPayoutSettingsSaga({}) {
  try {
    const settings = yield call(api.fetchPayoutSettings, {});
    yield put(
      actions.fetchPayoutSettings.success({
        ...settings,
      }),
    );
  } catch (ex) {
    yield put(actions.fetchPayoutSettings.failure(ex));
  }
}

function* updatePayoutSettingsSaga({ payload: { values, successCb } }) {
  try {
    const {
      auth: { user },
    } = yield select();
    const response = yield call(api.updatePayoutSettings, {
      AccountId: user.AccountId,
      PayoutMethod: values.payoutMethod,
      Id: values.Id || undefined,
    });
    if (response.success) {
      yield put(actions.updatePayoutSettings.success({}));
      toast.success('Default payout method updated successfully');
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(response.message);
      yield put(actions.updatePayoutSettings.failure(response.message));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.updatePayoutSettings.failure(ex));
  }
}

function* demoSagas() {
  yield takeLatest(actions.fetchPayout.request, fetchPayoutSaga);
  yield takeLatest(actions.addPayoutDirect.request, addPayoutDirectSaga);
  yield takeLatest(actions.updatePayoutDirect.request, updatePayoutDirectSaga);
  yield takeLatest(actions.deletePayoutDirect.request, deletePayoutDirectSaga);
  yield takeLatest(actions.addPayoutPaypal.request, addPayoutPaypalSaga);
  yield takeLatest(actions.updatePayoutPaypal.request, updatePayoutPaypalSaga);
  yield takeLatest(actions.deletePayoutPaypal.request, deletePayoutPaypalSaga);
  yield takeLatest(
    actions.fetchPayoutSettings.request,
    fetchPayoutSettingsSaga,
  );
  yield takeLatest(
    actions.updatePayoutSettings.request,
    updatePayoutSettingsSaga,
  );
}

export default demoSagas;
