import { actionCreator } from 'src/utils';

const MODULE_NAME = 'AUDIO_VIDEO';
const ACTION = {
  CREATE_AUDIO_VIDEO: 'CREATE_AUDIO_VIDEO',
  POLL_CONVERSION: 'POLL_CONVERSION',
  FETCH_SHORT_CLIPS: 'FETCH_SHORT_CLIPS',
};

const action = actionCreator(MODULE_NAME);

export const createAudioVideo = action(ACTION.CREATE_AUDIO_VIDEO);
export const pollConversion = action(ACTION.POLL_CONVERSION);
export const fetchShortClips = action(ACTION.FETCH_SHORT_CLIPS);
