import { actionCreator } from 'src/utils';

const MODULE_NAME = 'AUDIO';
const ACTION = {
  FETCH_AUDIOS: 'FETCH_AUDIOS',
  SEARCH_AUDIO: 'SEARCH_AUDIO',
  CHANGE_PAGE: 'CHANGE_PAGE',
  CHANGE_PAGE_SIZE: 'CHANGE_PAGE_SIZE',
  CREATE_ALBUM: 'CREATE_ALBUM',
  TOGGLE_COLLAPSE: 'TOGGLE_COLLAPSE',
  FETCH_UPC: 'FETCH_UPC',
};

const action = actionCreator(MODULE_NAME);

export const fetchAudios = action(ACTION.FETCH_AUDIOS);
export const searchAudio = action(ACTION.SEARCH_AUDIO);
export const changePageSize = action(ACTION.CHANGE_PAGE_SIZE);
export const changePage = action(ACTION.CHANGE_PAGE);
export const createAlbum = action(ACTION.CREATE_ALBUM);
export const toggleCollapse = action(ACTION.TOGGLE_COLLAPSE);
export const fetchUPC = action(ACTION.FETCH_UPC);
