import { api, serialize } from 'src/utils';

export const fetchVideos = (params) => {
  const queryStringParams = serialize(params);
  return api(`/video${queryStringParams}`);
};

export const createVideo = (body, createMode) => {
  return api.post(
    `/video/create${createMode === 'publish' ? '-publish' : ''}`,
    body,
  );
};

export const uploadVideoXml = (submissionId, body) => {
  return api.post(`/upload/video/xml?submissionId=${submissionId}`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const fetchFacebookArtistUrl = (params) => {
  const queryStringParams = serialize(params);
  return api(`/video/facebook-artist-url${queryStringParams}`);
};

export const saveFacebookArtistUrl = (body) => {
  return api.post(`/video/update-facebook-artist-url`, body);
};

export const addToPMV = (body) => {
  return api.post(`/video/add-to-pmv`, body);
};
