import { createReducer } from 'redux-act';
import produce from 'immer';

import * as actions from './actions';

const initialState = {
  isLoading: false,
  isMenuCollapsed: false,
  isHeaderFixed: false,
  pageTitle: '',
};

export default createReducer(
  {
    [actions.startLoading.success]: (state) =>
      produce(state, (nextState) => {
        nextState.isLoading = true;
      }),
    [actions.stopLoading.success]: (state) =>
      produce(state, (nextState) => {
        nextState.isLoading = false;
      }),
    [actions.toggleMenu.success]: (state) =>
      produce(state, (nextState) => {
        nextState.isMenuCollapsed = !state.isMenuCollapsed;
      }),
    [actions.setPageTitle.success]: (state, payload) => {
      const { pageTitle } = payload;
      return produce(state, (nextState) => {
        nextState.pageTitle = pageTitle;
      });
    },
  },
  initialState,
);
