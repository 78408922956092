import { compact, groupBy, reduce } from 'lodash';

export const root = (state) => state.macv;

export const getLoadingStatus = (state, type = '') => {
  const { loading } = root(state);
  if (!type) {
    return loading[type];
  }
  return compact(Object.values(loading)).length > 0;
};

export const getVideosQueue = (state) => root(state).videosQueue;

export const getWatchingVideo = (state) => root(state).watchingVideo;

export const getTodayViewed = (state) => root(state).todayViewed;

export const getSearchedVideos = (state) => root(state).searchedVideos;

export const getSharedVideos = (state) => root(state).sharedVideos;

export const getMacUser = (state) => root(state).macUser;
export const getSharedCreditFlag = (state) => root(state).hasGotShareCredits;

export const getMacvStats = (state) => root(state).stats;

export const getViewsByIsrc = (state) => root(state).viewsByIsrc;

export const getTopWatchers = (state) => root(state).topWatchers;

export const getBeats = (state) => {
  return groupBy(root(state).beats, function (beat) {
    return beat.Genre;
  });
};

export const getBeatsPaging = (state) => root(state).beatsPaging;

export const getBeatsCartItems = (state) => root(state).beatsCartItems;

export const getBeatsCartTotal = (state) =>
  reduce(
    root(state).beatsCartItems.map((item) => item.selectedPrice),
    function (sum, price) {
      return sum + price;
    },
  );

export const getFlatBeats = (state) => root(state).beats;

export const getTokenInvoice = (state) => root(state).tokenInvoice;

export const getBeatsOrders = (state) => root(state).beatsOrders;

export const getBeatsOrdersPaging = (state) => root(state).beatsOrdersPaging;

export const getYourBeatsOrders = (state) => root(state).totalBeatsOrders;
