import { compact } from 'lodash';

export const root = (state) => state.payout;

export const getPayoutMethods = (state) => root(state).data;

export const getLoadingStatus = (state, type = '') => {
  const loading = root(state).loading;
  if (!type) {
    return loading[type];
  } else {
    return compact(Object.values(loading)).length > 0;
  }
};

export const getPayoutSettings = (state) => root(state).settings;
