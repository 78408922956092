import { put, takeLatest, select, call } from 'redux-saga/effects';
import { defaultSearchParams } from 'src/constants/searchParams';
import { serialize } from 'src/utils';
import { toast } from 'react-toastify';
// import { createDdexXml } from 'src/utils/spotifyddex';
import * as actions from './actions';
import { fetchUserData } from 'src/modules/auth/redux/actions';
import { getSearchText, getPageSize } from './selectors';
import * as api from './api';
import { ALBUM_TYPES } from '../components/uploadAudio/utils';
import moment from 'moment';
import { SPOTIFY_DATE } from 'src/constants/dateFormats';
import { compact } from 'lodash-es';

function* fetchAudiosSaga({ payload = {} }) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamPage = urlParams.get('page');
    const urlParamLimit = urlParams.get('limit');
    const urlParamSearch = urlParams.get('search');
    const { page, searchText, limit } = payload;
    const searchFromUrlOrDefault =
      typeof searchText === 'string'
        ? searchText
        : urlParamSearch || defaultSearchParams.search;
    const limitFromUrlOrDefault =
      limit || urlParamLimit || defaultSearchParams.limit;
    const pageFromUrlOrDefault =
      page || urlParamPage || defaultSearchParams.page;
    const paramsWithUrlAndDefault = {
      page: page || urlParamPage || defaultSearchParams.page,
      search: searchFromUrlOrDefault,
      limit: limit || urlParamLimit || defaultSearchParams.limit,
    };
    const audios = yield call(api.fetchAudios, paramsWithUrlAndDefault);
    yield put(
      actions.fetchAudios.success({
        ...audios,
        search: searchFromUrlOrDefault,
        limit: limitFromUrlOrDefault,
      }),
    );
    window.history.pushState(
      {},
      '',
      serialize({
        limit: limitFromUrlOrDefault,
        page: pageFromUrlOrDefault,
        search: searchFromUrlOrDefault,
      }),
    );
  } catch (ex) {
    yield put(actions.fetchAudios.failure(ex));
  }
}

function* searchAudioSaga({ payload }) {
  try {
    yield put(actions.fetchAudios.request({ page: 1, searchText: payload }));
  } catch (ex) {
    yield put(actions.searchAudio.failure(ex));
  }
}

function* changePageSage({ payload }) {
  try {
    const searchText = yield select(getSearchText);
    yield put(
      actions.fetchAudios.request({ page: 1, limit: payload, searchText }),
    );
  } catch (ex) {
    yield put(actions.changePage.failure(ex));
  }
}

function* changePageSaga({ payload }) {
  try {
    const searchText = yield select(getSearchText);
    const pageSize = yield select(getPageSize);
    yield put(
      actions.fetchAudios.request({
        page: payload,
        searchText,
        limit: pageSize,
      }),
    );
  } catch (ex) {
    yield put(actions.changePageSize.failure(ex));
  }
}
// TODO: how to store all input artists of a track
const getTrackArtists = (track) => {
  return track.Artists.filter(
    (item) =>
      item.indexOf(':main artist') > 0 || item.indexOf(':featured artist') > 0,
  )
    .map((item) => item.split(':').shift())
    .join(', ');
};

function* createAlbumSaga({ payload: { values, cb } }) {
  try {
    const {
      Endpoint,
      tracks,
      UPCcode,
      ReleaseDate,
      Title,
      SubmissionId,
      CreatedAt,
      cover,
      CopyrightYear,
      Copyright,
      ...album
    } = values;

    const utcDate = moment().utc();
    // make user selected date into EST time and then convert it to UTC
    const utcReleaseDate = moment(
      moment(ReleaseDate).format('YYYY-MM-DDTHH:mm:00-04:00'),
    )
      .utc()
      .format();
    const albumTitle =
      album.AlbumType === ALBUM_TYPES.SINGLE ? tracks[0].Title : Title;

    const selectedEndpoints = compact(
      Object.keys(Endpoint).map((item) => (Endpoint[item] ? item : null)),
    );
    const Endpoints = [...selectedEndpoints].join(',');

    // auto prefix copyright with year if year not specified
    const clubCopyrightAndYear = `${CopyrightYear} ${Copyright}`;

    yield call(api.createAlbum, {
      album: {
        Artist:
          album.AlbumType === ALBUM_TYPES.SINGLE
            ? getTrackArtists(tracks[0])
            : 'Various Artists',
        Endpoint: Endpoints,
        UpcCode: UPCcode,
        ReleaseDate: utcReleaseDate,
        Status: 'pending',
        Title: albumTitle,
        SubmissionId,
        CreateDate: CreatedAt,
      },
      tracks: tracks.map((t) => {
        const format = t.audioUrl.slice(t.audioUrl.lastIndexOf('.') + 1);
        return {
          CreateDate: CreatedAt,
          S3Key: `${utcDate.format(SPOTIFY_DATE)}/${utcDate.format(
            'YYYYMMDD_HHmmss',
          )}/${values.UPCcode}`,
          Format: format,
          SubmissionId: t.SubmissionId,
          Artist: getTrackArtists(t),
          Endpoint: Endpoints,
          UpcCode: UPCcode,
          ReleaseDate: utcReleaseDate,
          Status: 'pending',
          Title: t.Title,
          TrackFile: t.TrackFile,
          TrackPrice: Endpoint['apple-music'] ? t.TrackPrice : '',
          ClipDuration: Endpoint['tik-tok'] ? t.ClipDuration : '',
          ClipStartTime: Endpoint['tik-tok'] ? t.ClipStartTime : '',
          TrackLyrics: t.TrackLyrics,
        };
      }),
      cover,
      xmlData: {
        ...values,
        Title: albumTitle,
        ReleaseDate: utcReleaseDate,
        Copyright: clubCopyrightAndYear,
      },
    });
    if (cb) {
      cb();
    }
    yield put(actions.createAlbum.success({}));
    yield put(fetchUserData.request({}));
    toast.success('Audio record successfully created');
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.createAlbum.failure(ex));
  }
}

function* fetchUPCSaga({ payload: { cb } }) {
  try {
    const upc = yield call(api.getUpcCode);
    yield put(actions.fetchUPC.success({}));
    cb(upc);
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.fetchUPC.failure(ex));
  }
}

function* demoSagas() {
  yield takeLatest(actions.fetchAudios.request, fetchAudiosSaga);
  yield takeLatest(actions.searchAudio.request, searchAudioSaga);
  yield takeLatest(actions.changePageSize.request, changePageSage);
  yield takeLatest(actions.changePage.request, changePageSaga);
  yield takeLatest(actions.createAlbum.request, createAlbumSaga);
  yield takeLatest(actions.fetchUPC.request, fetchUPCSaga);
}

export default demoSagas;
