import { createSelector } from 'reselect';

export const root = (state) => state.audio;

export const getAudioData = (state) => root(state).data;

export const getPage = createSelector(
  [(state) => root(state).page],
  (page) => page,
);

export const getPageCount = createSelector(
  [(state) => root(state).pageCount],
  (pageCount) => pageCount,
);
export const getPageSize = createSelector(
  [(state) => root(state).limit],
  (pageSize) => pageSize,
);

export const getTotalCount = createSelector(
  [(state) => root(state).total],
  (total) => total,
);

export const getSearchText = createSelector(
  [(state) => root(state).searchText],
  (search) => search,
);

export const getLoadingStatus = (state) => root(state).loading;

export const getCollapsedIds = (state) => root(state).collapsedIds;

export const hasAnyAudioCount = (state) => root(state).hasAnyAudioCount;
