import { createReducer } from 'redux-act';
import produce from 'immer';

import * as actions from './actions';

const initialState = {
  scanLoading: false,
  scanResult: null,
  scanError: null,
  loading: false,
  searchText: '',
  page: 1,
  pageCount: 1,
  limit: 25,
  total: 0,
  data: [],
  scanStatusLoading: false,
  editLink: null,
  userInsights: [],
  userInsightsParams: {
    start_date: '',
    end_date: '',
    date_filter: '',
    filter_name: '',
  },
  bestLinks: [],
  topChannels: [],
  topChannelsPaging: {
    page: 1,
    pageCount: 1,
    limit: 3,
    total: 0,
  },
  topCountries: [],
  topCountriesPaging: {
    page: 1,
    pageCount: 1,
    limit: 3,
    total: 0,
  },
  topServices: [],
  topServicesPaging: {
    page: 1,
    pageCount: 1,
    limit: 3,
    total: 0,
  },
  publishedAudios: [],
};

export default createReducer(
  {
    [actions.createReleaseLink.request]: (state) =>
      produce(state, (nextState) => {
        nextState.scanLoading = true;
        nextState.scanError = null;
        nextState.scanResult = null;
      }),
    [actions.createReleaseLink.success]: (state, payload) => {
      return produce(state, (nextState) => {
        nextState.scanLoading = false;
        nextState.scanResult = payload;
      });
    },
    [actions.createReleaseLink.failure]: (state, error) =>
      produce(state, (nextState) => {
        nextState.scanLoading = false;
        nextState.scanResult = null;
        nextState.scanError = error;
      }),
    [actions.fetchLinks.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.fetchLinks.success]: (state, payload) => {
      const { data, pageCount, page, total, search, limit } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        nextState.pageCount = pageCount;
        nextState.page = page;
        nextState.total = total;
        nextState.searchText = search;
        nextState.limit = Number(limit);
        nextState.data = data;
      });
    },

    [actions.fetchLinks.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
      }),
    [actions.changePageSize.request]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.limit = payload;
      }),
    [actions.searchLink.request]: (state, payload) =>
      produce(state, (nextState) => {
        nextState.searchText = payload;
      }),

    [actions.getLinkScanStatus.request]: (state) =>
      produce(state, (nextState) => {
        nextState.scanStatusLoading = true;
        nextState.scanError = null;
        nextState.scanResult = null;
      }),
    [actions.getLinkScanStatus.success]: (state, payload) => {
      return produce(state, (nextState) => {
        nextState.scanStatusLoading = false;
        nextState.scanResult = payload;
      });
    },
    [actions.getLinkScanStatus.failure]: (state, error) =>
      produce(state, (nextState) => {
        nextState.scanStatusLoading = false;
        nextState.scanResult = null;
        nextState.scanError = error;
      }),

    [actions.deleteLink.request]: (state) =>
      produce(state, (nextState) => {
        nextState.scanLoading = true;
        nextState.scanError = null;
      }),
    [actions.deleteLink.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.scanLoading = false;
        const records = state.data.filter((item) => item.Id !== data.Id);
        nextState.data = [...records];
      });
    },
    [actions.deleteLink.failure]: (state, error) =>
      produce(state, (nextState) => {
        nextState.scanLoading = false;
        nextState.scanError = error;
      }),

    [actions.updateLink.request]: (state) =>
      produce(state, (nextState) => {
        nextState.scanLoading = true;
        nextState.scanError = null;
      }),
    [actions.updateLink.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.scanLoading = false;
        const recordIndex = state.data.findIndex((item) => item.Id === data.Id);
        if (recordIndex >= 0) {
          nextState.data[recordIndex] = data;
        }
      });
    },
    [actions.updateLink.failure]: (state, error) =>
      produce(state, (nextState) => {
        nextState.scanLoading = false;
        nextState.scanError = error;
      }),

    [actions.getLinkDetails.request]: (state) =>
      produce(state, (nextState) => {
        nextState.scanLoading = true;
        nextState.scanError = null;
        nextState.editLink = null;
      }),
    [actions.getLinkDetails.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.scanLoading = false;
        nextState.editLink = data;
      });
    },
    [actions.getLinkDetails.failure]: (state, error) =>
      produce(state, (nextState) => {
        nextState.scanLoading = false;
        nextState.scanError = error;
      }),

    [actions.getUserInsights.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.getUserInsights.success]: (state, payload) => {
      const { data, params } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        nextState.userInsightsParams = {
          ...state.userInsightsParams,
          ...params,
        };
        nextState.userInsights = data;
      });
    },

    [actions.getUserInsights.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.userInsights = [];
      }),

    [actions.getBestLinks.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.getBestLinks.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        nextState.bestLinks = data;
      });
    },

    [actions.getBestLinks.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.bestLinks = [];
      }),

    [actions.getTopChannels.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.getTopChannels.success]: (state, payload) => {
      const { data, paging } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        if (paging.page === 1) {
          nextState.topChannels = data;
        } else {
          nextState.topChannels = [...state.topChannels, ...data];
        }

        nextState.topChannelsPaging = {
          ...state.topChannelsPaging,
          ...paging,
        };
      });
    },

    [actions.getTopChannels.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.topChannels = [];
        nextState.topChannelsPaging = {
          ...initialState.topChannelsPaging,
        };
      }),

    [actions.getTopCountries.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.getTopCountries.success]: (state, payload) => {
      const { data, paging } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        if (paging.page === 1) {
          nextState.topCountries = data;
        } else {
          nextState.topCountries = [...state.topCountries, ...data];
        }

        nextState.topCountriesPaging = {
          ...state.topCountriesPaging,
          ...paging,
        };
      });
    },

    [actions.getTopCountries.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.topCountries = [];
        nextState.topCountriesPaging = {
          ...initialState.topCountriesPaging,
        };
      }),

    [actions.getTopServices.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.getTopServices.success]: (state, payload) => {
      const { data, paging } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        if (paging.page === 1) {
          nextState.topServices = data;
        } else {
          nextState.topServices = [...state.topServices, ...data];
        }

        nextState.topServicesPaging = {
          ...state.topServicesPaging,
          ...paging,
        };
      });
    },

    [actions.getTopServices.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.topServices = [];
        nextState.topServicesPaging = {
          ...initialState.topServicesPaging,
        };
      }),

    [actions.fetchPublishedAudios.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = true;
      }),
    [actions.fetchPublishedAudios.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = false;
        nextState.publishedAudios = data;
      });
    },

    [actions.fetchPublishedAudios.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = false;
        nextState.publishedAudios = [];
      }),
  },
  initialState,
);
