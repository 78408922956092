import { all, fork } from 'redux-saga/effects';
import concat from 'lodash/concat';

import { audioSagas } from 'src/modules/audio';
import { videoSagas } from 'src/modules/video';
import { authSagas } from 'src/modules/auth';
import { accountSagas } from 'src/modules/account';
import { channelSagas } from 'src/modules/channel';
import { analyticsSagas } from 'src/modules/analytics';
import { dashboardSagas } from 'src/modules/dashboard';
import { payoutSagas } from 'src/modules/payout';
import { macvSagas } from 'src/modules/macv';
import { finSagas } from 'src/modules/financial';
// import { beatsSagas } from 'src/modules/beats';
import { audioVideoSagas } from 'src/modules/audioVideo';
import { marketingSagas } from 'src/modules/marketing';

const allSagas = concat(
  audioSagas,
  videoSagas,
  authSagas,
  accountSagas,
  channelSagas,
  analyticsSagas,
  dashboardSagas,
  payoutSagas,
  macvSagas,
  finSagas,
  // beatsSagas,
  audioVideoSagas,
  marketingSagas,
);

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
