import { api, serialize } from 'src/utils';

export const createReleaseLink = (body) => {
  return api.post(`/links/create-release`, body);
};

export const fetchLinks = (params) => {
  const queryStringParams = serialize(params);
  return api(`/links${queryStringParams}`);
};

export const getLinkScanStatus = (linkId, params) => {
  const queryStringParams = serialize(params);
  return api(`/links/scan-status/${linkId}${queryStringParams}`);
};

export const deleteLink = (linkId) => {
  return api.delete(`/links/delete/${linkId}`);
};

export const updateLink = (body) => {
  return api.put(`/links/update`, body);
};

export const getLinkDetails = (linkId) => {
  return api(`/links/${linkId}`);
};

export const getUserInsights = (params) => {
  const queryStringParams = serialize(params);
  return api(`/links/insights${queryStringParams}`);
};

export const getBestLinks = (params) => {
  const queryStringParams = serialize(params);
  return api(`/links/best-links${queryStringParams}`);
};

export const getTopChannels = (params) => {
  const queryStringParams = serialize(params);
  return api(`/links/insights/top-channels${queryStringParams}`);
};

export const getTopServices = (params) => {
  const queryStringParams = serialize(params);
  return api(`/links/insights/top-services${queryStringParams}`);
};

export const getTopCountries = (params) => {
  const queryStringParams = serialize(params);
  return api(`/links/insights/top-countries${queryStringParams}`);
};

export const fetchPublishedAudios = (params) => {
  const queryStringParams = serialize({
    ...params,
    filter: 'PUBLISHED,DELIVERED',
  });
  return api(`/audio/search${queryStringParams}`);
};
