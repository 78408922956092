import React from 'react';
import { Line } from 'react-chartjs-2';

const options = {
  plugins: {
    filler: {
      propagate: false,
    },
    legend: false,
  },
  interaction: {
    intersect: false,
  },
  maintainAspectRatio: false,
};
const LineAreaChart = (props) => {
  const generateDatasets = () => {
    const data = props.data || [];
    return {
      labels: data.labels,
      datasets: [
        {
          //   label: 'Dataset',
          data: data.data,
          borderColor: '#5CA491',
          backgroundColor: '#DAEBE7',
          fill: 'start',
        },
      ],
    };
  };
  return (
    <Line
      data={generateDatasets}
      responsive
      height={props.height || 300}
      options={{ ...options, ...(props.options || {}) }}
    />
  );
};

export default LineAreaChart;
