import { Loadable } from 'src/common';

import { PATH } from 'src/constants';

const DashboardPage = Loadable(() => import('./pages/Dashboard'));
const WatchVideoPage = Loadable(() => import('./pages/WatchVideo'));
const ShareVideoPage = Loadable(() => import('./pages/ShareVideo'));
const MySharedVideoPage = Loadable(() => import('./pages/MySharedVideo'));
const BeatsHubPage = Loadable(() => import('./pages/BeatsHub'));
const WelcomePage = Loadable(() => import('./pages/Welcome'));
const BeatsSelectPage = Loadable(() => import('./pages/BeatsSelect'));
const BeatsCheckoutPage = Loadable(() => import('./pages/BeatsCheckout'));
const BeatsDownloadPage = Loadable(() => import('./pages/BeatsDownload'));
const BeatsOrdersPage = Loadable(() => import('./pages/BeatsOrders'));

export default [
  {
    component: DashboardPage,
    path: `${PATH.MACV}/dashboard`,
    name: 'MACV',
    exact: true,
  },
  {
    component: WatchVideoPage,
    path: `${PATH.MACV}/watch`,
    name: 'MACV watch',
  },
  {
    component: ShareVideoPage,
    path: `${PATH.MACV}/share-video`,
    name: 'MACV Share Video',
  },
  {
    component: MySharedVideoPage,
    path: `${PATH.MACV}/my-shared/videos`,
    name: 'MACV my shared',
  },
  {
    component: BeatsHubPage,
    path: `${PATH.MACV}/beats-hub`,
    name: 'MACV Beats Hub',
    exact: true,
  },
  {
    component: WelcomePage,
    path: `${PATH.MACV}/overview`,
    name: 'MACV Overview',
    exact: true,
  },
  {
    component: BeatsSelectPage,
    path: `${PATH.MACV}/beats-select`,
    name: 'MACV Beat Select',
    exact: true,
  },
  {
    component: BeatsCheckoutPage,
    path: `${PATH.MACV}/beats-checkout`,
    name: 'MACV Beat checkout',
    exact: true,
  },

  {
    component: BeatsDownloadPage,
    path: `${PATH.MACV}/beats-download`,
    name: 'MACV Beat download',
    exact: true,
  },
  {
    component: BeatsOrdersPage,
    path: `${PATH.MACV}/beats/orders`,
    name: 'MACV Beat Orders',
    exact: true,
  },
];
