import { createReducer } from 'redux-act';
import produce from 'immer';

import * as actions from './actions';

const initialState = {
  loading: {
    fetchPayout: false,
    addPayout: false,
    updatePayout: false,
    deletePayout: false,
    settings: false,
  },
  searchText: '',
  page: 1,
  pageCount: 1,
  limit: 25,
  total: 0,
  data: [],
  settings: null,
};

export default createReducer(
  {
    [actions.fetchPayout.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, fetchPayout: true };
      }),
    [actions.fetchPayout.success]: (state, payload) => {
      const { data, pageCount, page, total, search, limit } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, fetchPayout: false };
        nextState.pageCount = pageCount;
        nextState.page = page;
        nextState.total = total;
        nextState.searchText = search;
        nextState.limit = Number(limit);
        nextState.data = data;
      });
    },
    [actions.fetchPayout.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, fetchPayout: false };
        nextState.data = [];
      }),
    [actions.addPayoutDirect.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, addPayout: true };
      }),
    [actions.addPayoutDirect.success]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, addPayout: false };
      }),
    [actions.addPayoutDirect.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, addPayout: false };
      }),
    [actions.updatePayoutDirect.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, updatePayout: true };
      }),
    [actions.updatePayoutDirect.success]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, updatePayout: false };
      }),
    [actions.updatePayoutDirect.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, updatePayout: false };
      }),
    [actions.deletePayoutDirect.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, deletePayout: true };
      }),
    [actions.deletePayoutDirect.success]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, deletePayout: false };
      }),
    [actions.deletePayoutDirect.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, deletePayout: false };
      }),

    [actions.addPayoutPaypal.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, addPayout: true };
      }),
    [actions.addPayoutPaypal.success]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, addPayout: false };
      }),
    [actions.addPayoutPaypal.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, addPayout: false };
      }),
    [actions.updatePayoutPaypal.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, updatePayout: true };
      }),
    [actions.updatePayoutPaypal.success]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, updatePayout: false };
      }),
    [actions.updatePayoutPaypal.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, updatePayout: false };
      }),
    [actions.deletePayoutPaypal.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, deletePayout: true };
      }),
    [actions.deletePayoutPaypal.success]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, deletePayout: false };
      }),
    [actions.deletePayoutPaypal.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, deletePayout: false };
      }),

    [actions.fetchPayoutSettings.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, settings: true };
      }),
    [actions.fetchPayoutSettings.success]: (state, payload) => {
      const { data } = payload;
      return produce(state, (nextState) => {
        nextState.loading = { ...state.loading, settings: false };
        nextState.settings = data;
      });
    },
    [actions.fetchPayoutSettings.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, settings: false };
        nextState.settings = null;
      }),
    [actions.updatePayoutSettings.request]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, settings: true };
      }),
    [actions.updatePayoutSettings.success]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, settings: false };
      }),
    [actions.updatePayoutSettings.failure]: (state) =>
      produce(state, (nextState) => {
        nextState.loading = { ...state.loading, settings: false };
      }),
  },
  initialState,
);
