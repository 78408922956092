import { put, takeLatest, call, select } from 'redux-saga/effects';
// import { defaultSearchParams } from 'src/constants/searchParams';
// import { serialize } from 'src/utils';
import { toast } from 'react-toastify';
import * as actions from './actions';
import * as api from './api';

function* fetchBalanceStatementSaga({ payload }) {
  try {
    const result = yield call(api.fetchBalanceStatement, { ...payload });
    yield put(
      actions.fetchBalanceStatement.success({
        ...result,
      }),
    );
  } catch (ex) {
    yield put(actions.fetchBalanceStatement.failure(ex));
  }
}

function* fetchPaymentStatementSaga({ payload }) {
  try {
    const result = yield call(api.fetchPaymentStatement, { ...payload });
    yield put(
      actions.fetchPaymentStatement.success({
        ...result,
      }),
    );
  } catch (ex) {
    yield put(actions.fetchPaymentStatement.failure(ex));
  }
}

function* requestPaymentSaga({ payload: { values, successCb } }) {
  try {
    const {
      auth: { user },
    } = yield select();
    const response = yield call(api.requestPayment, {
      AccountId: user.AccountId,
      ...values,
    });
    if (response.success) {
      yield put(actions.requestPayment.success({}));
      toast.success('Payment request submitted successfully');
      if (successCb) {
        successCb();
      }
    } else {
      toast.error(response.message);
      yield put(actions.requestPayment.failure(response.message));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.requestPayment.failure(ex));
  }
}

function* fetchVideoEarningsSaga({ payload }) {
  try {
    const result = yield call(api.fetchVideoEarnings, { ...payload });
    yield put(
      actions.fetchVideoEarnings.success({
        ...result,
      }),
    );
  } catch (ex) {
    yield put(actions.fetchVideoEarnings.failure(ex));
  }
}

function* fetchAudioEarningsSaga({ payload }) {
  try {
    const { successCB, ...data } = payload;
    const result = yield call(api.fetchAudioEarnings, { ...data });
    yield put(
      actions.fetchAudioEarnings.success({
        ...result,
      }),
    );
    if (successCB) {
      successCB();
    }
  } catch (ex) {
    yield put(actions.fetchAudioEarnings.failure(ex));
  }
}

function* demoSagas() {
  yield takeLatest(
    actions.fetchBalanceStatement.request,
    fetchBalanceStatementSaga,
  );
  yield takeLatest(
    actions.fetchPaymentStatement.request,
    fetchPaymentStatementSaga,
  );
  yield takeLatest(actions.requestPayment.request, requestPaymentSaga);
  yield takeLatest(actions.fetchVideoEarnings.request, fetchVideoEarningsSaga);
  yield takeLatest(actions.fetchAudioEarnings.request, fetchAudioEarningsSaga);
}

export default demoSagas;
