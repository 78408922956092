import { put, takeLatest, call, select } from 'redux-saga/effects';
import { defaultSearchParams } from 'src/constants/searchParams';
import { serialize } from 'src/utils';
import * as api from './api';
import * as actions from './actions';
import { toast } from 'react-toastify';
import { getSearchText, getPageSize } from './selectors';
import moment from 'moment';

function* createReleaseLinkSaga({ payload: { values, successCb } }) {
  try {
    const result = yield call(api.createReleaseLink, {
      ReferenceType: values.ReleaseType,
      UrlOrCode: values.UrlOrCode,
      LinkTitle: values.Title,
      Artists: values.Artist,
      Tags: values.Tags.join(','),
      Domain: values.Link,
      ShortCode: values.ShortCode,
      ImageUrl: values.Image,
      MediaType: values.LinkType,
    });
    if (result.success) {
      yield put(
        actions.createReleaseLink.success({
          ...result,
        }),
      );
      toast.success('Link created successfully');
      if (successCb) {
        successCb(result.data);
      }
    } else {
      toast.error(`Error while creating link ${result.error}`);
      yield put(actions.createReleaseLink.failure(result.error));
    }
  } catch (ex) {
    toast.error(`Error while creating link`);
    yield put(actions.createReleaseLink.failure(ex));
  }
}

function* fetchLinksSaga({ payload = {} }) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamPage = urlParams.get('page');
    const urlParamLimit = urlParams.get('limit');
    const urlParamSearch = urlParams.get('search');
    const { page, searchText, limit } = payload;
    const searchFromUrlOrDefault =
      typeof searchText === 'string'
        ? searchText
        : urlParamSearch || defaultSearchParams.search;
    const limitFromUrlOrDefault =
      limit || urlParamLimit || defaultSearchParams.limit;
    const pageFromUrlOrDefault =
      page || urlParamPage || defaultSearchParams.page;
    const paramsWithUrlAndDefault = {
      page: page || urlParamPage || defaultSearchParams.page,
      search: searchFromUrlOrDefault,
      limit: limit || urlParamLimit || defaultSearchParams.limit,
    };
    const links = yield call(api.fetchLinks, paramsWithUrlAndDefault);
    yield put(
      actions.fetchLinks.success({
        ...links,
        search: searchFromUrlOrDefault,
        limit: limitFromUrlOrDefault,
      }),
    );
    window.history.pushState(
      {},
      '',
      serialize({
        limit: limitFromUrlOrDefault,
        page: pageFromUrlOrDefault,
        search: searchFromUrlOrDefault,
      }),
    );
  } catch (ex) {
    yield put(actions.fetchLinks.failure(ex));
  }
}

function* searchLinkSaga({ payload }) {
  try {
    yield put(actions.fetchLinks.request({ page: 1, searchText: payload }));
  } catch (ex) {
    yield put(actions.searchLink.failure(ex));
  }
}

function* changePageSage({ payload }) {
  try {
    const searchText = yield select(getSearchText);
    yield put(
      actions.fetchLinks.request({ page: 1, limit: payload, searchText }),
    );
  } catch (ex) {
    yield put(actions.changePage.failure(ex));
  }
}

function* changePageSaga({ payload }) {
  try {
    const searchText = yield select(getSearchText);
    const pageSize = yield select(getPageSize);
    yield put(
      actions.fetchLinks.request({
        page: payload,
        searchText,
        limit: pageSize,
      }),
    );
  } catch (ex) {
    yield put(actions.changePageSize.failure(ex));
  }
}

function* getLinkScanStatusSaga({ payload: { LinkId, Mode, successCb } }) {
  try {
    const result = yield call(api.getLinkScanStatus, LinkId, { mode: Mode });
    yield put(actions.getLinkScanStatus.success(result));
    if (successCb) {
      successCb(result.data ? result : null);
    }
  } catch (ex) {
    yield put(actions.getLinkScanStatus.failure(ex));
    if (successCb) {
      successCb(null);
    }
    // toast.error((ex.response && ex.response.error) || ex.response.message);
  }
}

function* deleteLinkSaga({ payload: { LinkId, successCb } }) {
  try {
    const result = yield call(api.deleteLink, LinkId);
    yield put(actions.deleteLink.success(result));
    if (result.success) {
      toast.success('Link deleted successfully');
      if (successCb) {
        successCb(result);
      }
    } else {
      toast.error(`Error while deleting link ${result.error}`);
      yield put(actions.deleteLink.failure(result.error));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.deleteLink.failure(ex));
  }
}

function* updateLinkSaga({ payload: { values, successCb } }) {
  try {
    const result = yield call(api.updateLink, {
      Id: values.LinkId,
      ReferenceType: values.ReleaseType,
      UrlOrCode: values.UrlOrCode,
      LinkTitle: values.Title,
      Description: values.Description || '',
      Artists: values.Artist,
      Tags: values.Tags.join(','),
      ImageUrl: values.Image || '',
      MediaType: values.LinkType,
      details: values.details,
      MediaServices: values.MediaServices,
      ReleaseDate: values.ReleaseDate
        ? moment(moment(values.ReleaseDate).format('YYYY-MM-DDTHH:mm:00-04:00'))
            .utc()
            .format()
        : undefined,
    });
    if (result.success) {
      yield put(
        actions.updateLink.success({
          ...result,
        }),
      );
      toast.success('Link updated successfully');
      if (successCb) {
        successCb(result);
      }
    } else {
      toast.error(`Error while updating link ${result.error}`);
      yield put(actions.updateLink.failure(result.error));
    }
  } catch (ex) {
    toast.error(`Error while updating link`);
    yield put(actions.updateLink.failure(ex));
  }
}

function* getLinkDetailsSaga({ payload: { LinkId, successCb } }) {
  try {
    const result = yield call(api.getLinkDetails, LinkId);
    if (result.success) {
      yield put(actions.getLinkDetails.success(result));
      if (successCb) {
        successCb(result.data);
      }
    } else {
      toast.error(result.error || 'Invalid');
      yield put(actions.getLinkDetails.failure(result.error || 'Invalid'));
    }
  } catch (ex) {
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.getLinkDetails.failure(ex));
  }
}

function* getUserInsightsSaga({ payload: { values, successCb } }) {
  try {
    const result = yield call(api.getUserInsights, values);
    yield put(
      actions.getUserInsights.success({ data: result.data, params: values }),
    );
    if (successCb) {
      successCb(result.data);
    }
  } catch (ex) {
    console.log('error getUserInsightsSaga', ex);
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.getUserInsights.failure(ex));
  }
}

function* getBestLinksSaga({ payload: { values, successCb } }) {
  try {
    const result = yield call(api.getBestLinks, values);
    yield put(actions.getBestLinks.success({ data: result.data }));
    if (successCb) {
      successCb(result.data);
    }
  } catch (ex) {
    console.log('error getBestLinksSaga', ex);
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.getBestLinks.failure(ex));
  }
}

function* getTopChannelsSaga({ payload: { values, successCb } }) {
  try {
    const result = yield call(api.getTopChannels, values);
    yield put(actions.getTopChannels.success(result));
    if (successCb) {
      successCb(result);
    }
  } catch (ex) {
    console.log('error getTopChannels', ex);
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.getTopChannels.failure(ex));
  }
}

function* getTopCountriesSaga({ payload: { values, successCb } }) {
  try {
    const result = yield call(api.getTopCountries, values);
    yield put(actions.getTopCountries.success(result));
    if (successCb) {
      successCb(result);
    }
  } catch (ex) {
    console.log('error getTopCountries', ex);
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.getTopCountries.failure(ex));
  }
}

function* getTopServicesSaga({ payload: { values, successCb } }) {
  try {
    const result = yield call(api.getTopServices, values);
    yield put(actions.getTopServices.success(result));
    if (successCb) {
      successCb(result);
    }
  } catch (ex) {
    console.log('error getTopServices', ex);
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.getTopServices.failure(ex));
  }
}

function* fetchPublishedAudiosSaga({ payload: { values, successCb } }) {
  try {
    const result = yield call(api.fetchPublishedAudios, values);
    yield put(actions.fetchPublishedAudios.success(result));
    if (successCb) {
      successCb(result);
    }
  } catch (ex) {
    console.log('error fetchPublishedAudios', ex);
    toast.error((ex.response && ex.response.error) || ex.response.message);
    yield put(actions.fetchPublishedAudios.failure(ex));
  }
}

function* rootSagas() {
  yield takeLatest(actions.createReleaseLink.request, createReleaseLinkSaga);
  yield takeLatest(actions.fetchLinks.request, fetchLinksSaga);
  yield takeLatest(actions.searchLink.request, searchLinkSaga);
  yield takeLatest(actions.changePageSize.request, changePageSage);
  yield takeLatest(actions.changePage.request, changePageSaga);
  yield takeLatest(actions.getLinkScanStatus.request, getLinkScanStatusSaga);
  yield takeLatest(actions.deleteLink.request, deleteLinkSaga);
  yield takeLatest(actions.updateLink.request, updateLinkSaga);
  yield takeLatest(actions.getLinkDetails.request, getLinkDetailsSaga);
  yield takeLatest(actions.getUserInsights.request, getUserInsightsSaga);
  yield takeLatest(actions.getBestLinks.request, getBestLinksSaga);
  yield takeLatest(actions.getTopChannels.request, getTopChannelsSaga);
  yield takeLatest(actions.getTopCountries.request, getTopCountriesSaga);
  yield takeLatest(actions.getTopServices.request, getTopServicesSaga);
  yield takeLatest(
    actions.fetchPublishedAudios.request,
    fetchPublishedAudiosSaga,
  );
}

export default rootSagas;
