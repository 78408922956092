import { api, serialize } from 'src/utils';

export const createAudioVideo = (payload) => {
  return api.post(`/upload/audio-video${serialize(payload)}`, {});
};

export const pollConversion = (payload) => {
  return api.post(`/AudioVideo/audio-video-poll${serialize(payload)}`, {});
};

export const fetchShortClips = (params) => {
  const queryStringParams = serialize(params);
  return api(`/AudioVideo/short-clips${queryStringParams}`);
};
