import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import * as actions from 'src/modules/auth/redux/actions';
import * as selectors from 'src/modules/auth/redux/selectors';

const AuthGuard = ({ isLoading, children, fetchUserData }) => {
  React.useEffect(() => {
    fetchUserData();
  }, []);

  if (isLoading) {
    return null;
  }

  return children;
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectors.isUserLoading,
});

const mapDispatchToProps = {
  fetchUserData: actions.fetchUserData.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuard);
