import { actionCreator } from 'src/utils';

const MODULE_NAME = 'PAYOUT';
const ACTION = {
  FETCH_PAYOUT: 'FETCH_PAYOUT',
  ADD_PAYOUT_DIRECT: 'ADD_PAYOUT_DIRECT',
  UPDATE_PAYOUT_DIRECT: 'UPDATE_PAYOUT_DIRECT',
  DELETE_PAYOUT_DIRECT: 'DELETE_PAYOUT_DIRECT',
  ADD_PAYOUT_PAYPAL: 'ADD_PAYOUT_PAYPAL',
  UPDATE_PAYOUT_PAYPAL: 'UPDATE_PAYOUT_PAYPAL',
  DELETE_PAYOUT_PAYPAL: 'DELETE_PAYOUT_PAYPAL',
  FETCH_PAYOUT_SETTINGS: 'FETCH_PAYOUT_SETTINGS',
  UPDATE_PAYOUT_SETTINGS: 'UPDATE_PAYOUT_SETTINGS',
};

const action = actionCreator(MODULE_NAME);

export const fetchPayout = action(ACTION.FETCH_PAYOUT);
export const addPayoutDirect = action(ACTION.ADD_PAYOUT_DIRECT);
export const updatePayoutDirect = action(ACTION.UPDATE_PAYOUT_DIRECT);
export const deletePayoutDirect = action(ACTION.DELETE_PAYOUT_DIRECT);
export const addPayoutPaypal = action(ACTION.ADD_PAYOUT_PAYPAL);
export const updatePayoutPaypal = action(ACTION.UPDATE_PAYOUT_PAYPAL);
export const deletePayoutPaypal = action(ACTION.DELETE_PAYOUT_PAYPAL);
export const fetchPayoutSettings = action(ACTION.FETCH_PAYOUT_SETTINGS);
export const updatePayoutSettings = action(ACTION.UPDATE_PAYOUT_SETTINGS);
