import * as React from 'react';

const DEFAULT_ERROR_MESSAGE = 'Oops! an error occurred!';

export default class ErrorBoundary extends React.Component {
  constructor() {
    super();
    this.state = {
      error: '',
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line react/no-set-state
    this.setState({
      error,
      errorInfo,
    });
  }

  renderError() {
    const { errorInfo } = this.state;
    const { text } = this.props;

    return (
      <div>
        <h3>{text || DEFAULT_ERROR_MESSAGE}</h3>
        {!!errorInfo && <p>{JSON.stringify(errorInfo)}</p>}
      </div>
    );
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return this.renderError();
    }

    return children;
  }
}
